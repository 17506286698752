import Service from "../Service";

const resource = "requirementpermit/";

export default {
    byUser(obj, requestID) {
        return Service.post(
            resource + "byuser", obj, {
                params: { requestID: requestID },
            }
        );
    },
    users(requestID) {
        return Service.post(
            resource + "user", {}, {
                params: { requestID: requestID },
            }
        );
    },
    pagination(dtr, requestID) {
        return Service.post(
            resource + "Pagination",
            dtr, {
                params: { requestID: requestID },
            }
        );
    },
    save(dtr, requestID) {
        return Service.post(
            resource + "save",
            dtr, {
                params: { requestID: requestID },
            }
        );
    },
};