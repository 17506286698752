<template>
  <v-card>
    <v-card-text class="ma-0 pa-0">
      <v-tabs fixed-tabs v-model="activeTab">
        <v-tab key="calendar">
          <v-icon x-small>mdi-calendar</v-icon>
        </v-tab>
        <v-tab key="timer" :disabled="dateSelected">
          <v-icon x-small>mdi-clock</v-icon>
        </v-tab>
        <v-tab-item key="calendar">
          <v-date-picker v-model="date" v-bind="datePickerProps" @input="showTimePicker" />
        </v-tab-item>
        <v-tab-item key="timer">
          <v-time-picker ref="timer" v-model="time" v-bind="timePickerProps" format="24hr" />
        </v-tab-item>
      </v-tabs>
    </v-card-text>
    <v-card-actions class=" pt-1">
      <v-container>
        <v-row>
          <v-col cols="6" class="s-col-form">
            <v-btn small color="grey" block text @click.native="clearHandler">Limpiar</v-btn>
          </v-col>
          <v-col cols="6" class="s-col-form">
            <v-btn small color="primary darken-1" block text @click="okHandler">Guardar</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
const DEFAULT_DATE = "";
const DEFAULT_TIME = "00:00";
const DEFAULT_DIALOG_WIDTH = 300;

export default {
  name: "v-datetime-picker",
  model: {
    prop: "datetime",
    event: "input",
  },
  props: {
    datetime: {
      type: [Date, String],
      default: null,
    },
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    label: {
      type: String,
      default: "",
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH,
    },
    textFieldProps: {
      type: Object,
    },
    datePickerProps: {
      type: Object,
    },
    timePickerProps: {
      type: Object,
    },
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    dateTimeFormat() {
      return this.dateFormat + " " + this.timeFormat;
    },
    defaultDateTimeFormat() {
      return this.$const.FormatDateDB + " " + this.$const.FormatTimeDB;
    },
    selectedDatetime() {
      if (this.date && this.time) {
        let datetimeString = this.date + " " + this.time;
        if (this.time.length === 5) {
          datetimeString += ":00";
        }
        return this.moment(datetimeString).format(this.$const.FormatDateTimeDB);
      } else {
        return null;
      }
    },
    dateSelected() {
      return !this.date;
    },
  },
  methods: {
    init() {
      if (!this.datetime) {
        return;
      }
      let initDateTime;
      // if (this.datetime instanceof Date) {
      //   initDateTime = this.datetime;
      // } else if (typeof this.datetime === "string" || this.datetime instanceof String) {
      initDateTime = this.moment(this.datetime);
      //}
      // console.log("format(initDateTime, this.$const.FormatDateDB)", format(initDateTime, this.$const.FormatDateDB));
      // console.log("format(initDateTime, this.$const.FormatDateDB)", format(initDateTime, this.$const.FormatTimeDB));
      this.date = initDateTime.format(this.$const.FormatDateDB);

      this.time = initDateTime.format(this.$const.FormatTimeDB);
    },
    okHandler() {
      this.resetPicker();
      this.$emit("input", this.selectedDatetime);
    },
    clearHandler() {
      this.resetPicker();
      this.date = DEFAULT_DATE;
      this.time = DEFAULT_TIME;
      this.$emit("input", null);
    },
    resetPicker() {
      this.display = false;
      this.activeTab = 0;
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true;
      }
    },
    showTimePicker() {
      this.activeTab = 1;
    },
  },
  watch: {
    datetime() {
      this.init();
    },
  },
};
</script>
