<template>
  <div>
    <s-select
      :return-object="returnObject"
      :item-value="itemvalue"
      :item-text="itemtext"
      :id="id"
      :disabled="disabled"
      :readonly="readonly"
      :items="items"
      label="Tipo de Planilla"
      dense :clearable=clearable
      outlined
      :value="value"
      @input="input($event)"
      hide-details="false"
    ></s-select>
  </div>
</template>

<script>
export default {
  name: "SelectG",
  components: {},
  props: {
    value: null,
    itemtext: {
      type: String,
      default: "TypePayrollText",
    },
    itemvalue: {
      type: String,
      default: "TypePayroll",
    },
   clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "TypePayroll",
    },
  
 
  
  },

  mounted() {},
  created() {
    this.load();
  },
  watch: {

    
  },

  data: () => ({
    search: "",
    //ComboBox
    item: null,
    items: [],
    val: "",
    //Autocomplete
    items: [],
    isLoading: false,
    model: null,
    search: null,
  }),
  methods: {
  
    input(value) {
      this.$emit("input", value);
    },
    load() {
     this.items=this.$fun.getSecurity().PayrollsAllow;
    },
  },
};
</script>

<style></style>
