<template>
  <div style="padding-top:-2px;margin-top:-2px;">
    <v-label><b>Seleccione Archivo</b></v-label>
    <section>
      <v-file-input
        @click:clear="clear()"
        :multiple="multiple"
        small-chips
        v-model="files"
        prepend-icon
        show-size
        color="primary"
        prepend-inner-icon="mdi-paperclip"
        dense
        hide-details
        outlined
      >
      </v-file-input>
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    config: {
      type: Object,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: null,
      files: null,
    };
  },
  watch: {
    files() {
      this.$emit("changeData", this.files);
      console.log(this.files);
    },
  },
  methods: {
    send() {
      this.$fun.alert("Seguro de subir Archivo", "question").then((val) => {
        if (val.value) {
          if (this.config != null)
            if (this.files.length > 0) {
              const formData = new FormData();
              formData.append("file", this.files);
              this.$http
                .post(
                  this.config.url,
                  formData
                  // ,  { headers: {'Content-Type': 'multipart/form-data' } }
                )
                .then(() => {
                  this.$fun.alert("Archivo cargado correctamente", "success");
                });
            } else this.$fun.alert("Seleccione Archivo a Cargar", "info");
        }
      });
    },
    clear() {
      this.$emit("clear");
    },
    changeData(data) {
      console.log("registro" + data);
    },
  },
};
</script>
