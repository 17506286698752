import Service from "../Service";

const resource = "/approvaltransaction/";

export default {
    approved(objTransaction, requestID) {
        console.log(objTransaction);
        return Service.post(resource + "approved", objTransaction, {
            params: { requestID: requestID },
        });
    },



};