let children = []

children.push({
    path: "/comercioexterior/precinto",
    name: "ExtSeal",

    component: () =>
        import ("../views/ExternalTrade/Seal.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Comercio Exterior", disabled: true },
            { text: "Registro de Precinto", href: "/comercioexterior/Comercio Exterior" },
        ],
    },
});


// EMBARQUE 
children.push({
    path: "/comercioexterior/embarque",
    name: "EXT_SHIPMENT",
    component: () =>
        import ("../views/ExternalTrade/Shipment/ShipmentG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Comercio Exterior", disabled: true },
            { text: "Embarque", href: "/comercioexterior/Embarque" }
        ],
    },
});

// LIQUIDACION 
children.push({
    path: "/comercioexterior/liquidaciones",
    name: "EXT_LIQUIDATION",
    component: () =>
        import ("../views/ExternalTrade/Liquidation/Liquidation.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Comercio Exterior", disabled: true },
            { text: "Liquidaciones", href: "/comercioexterior/Liquidaciones" }
        ],
    },
});

// CONFIGURACIÓN CLIENTES GASTOS 
children.push({
    path: "/comercioexterior/Gastos",
    name: "EXT_EXPENSES",
    component: () =>
        import ("../views/ExternalTrade/ExpenseConfiguration/Expenses.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Comercio Exterior", disabled: true },
            { text: "Gastos", href: "/comercioexterior/Gastos" }
        ],
    },
});

children.push({
    path: "/comercioexterior/lineastransporte",
    name: "EXT_TYPE_TRANSPORT_LINE",

    component: () =>
        import ("../views/ExternalTrade/TypeTransportLine/TransportG.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Comercio Exterior", disabled: true },
            { text: "Registro de Lineas de Transporte", href: "/comercioexterior/lineastransporte" },
        ],
    },
});

children.push({
    path: "/comercioexterior/contactocliente",
    name: "EXT_CUSTOMER_CONTACT",

    component: () =>
        import ("../views/ExternalTrade/CustomerContact/CustomerContact.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Comercio Exterior", disabled: true },
            { text: "Contacto Cliente", href: "/comercioexterior/contactocliente" },
        ],
    },
});

children.push({
    path: "/comercioexterior/notasprovicionales",
    name: "EXT_PROVISIONAL_NOTES",

    component: () =>
        import ("../views/ExternalTrade/ProvisionalNotes/ProvisionalNotesNew.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Comercio Exterior", disabled: true },
            { text: "Contacto Cliente", href: "/comercioexterior/notasprovicionales" },
        ],
    },
});

children.push({
    path: "/comercioexterior/salesorder",
    name: "EXT_SALES_ORDER",

    component: () =>
        import ("../views/ExternalTrade/ReferralGuide/SaleOrder.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Comercio Exterior", disabled: true },
            { text: "Orden Venta", href: "/comercioexterior/salesorder" },
        ],
    },
});

children.push({
    path: "/comercioexterior/misguias",
    name: "EXT_REFERRAL_GUIDE",

    component: () =>
        import ("../views/Logistics/ReferralGuide/LgsReferralGuide.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Comercio Exterior", disabled: true },
            { text: "Mis Guías", href: "/comercioexterior/misguias" },
        ],
    },
});

export { children }