<template
  ><div style="padding-top:-2px;margin-top:-2px;">
    <s-select-generic 
      ref="sdepartment"
      autocomplete  
      class="text"
      itemtext="GenDescription"
      itemvalue="GenHelper" 
      :disabled="disabled"
      id="GenDescription"
      :value="value" 
      :return-object="returnObject"
      @input="input($event)" 
      :config="config" 
      :label=label
      :full="full" 
      :autofocus=autofocus
      :text="text" 
      :clearable=clearable 
      :noDefault=noDefault
    >
    </s-select-generic>
  </div>
</template>

<script>
import _sGenericService from "./../../services/General/GenericService";
export default {
  components: {},
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
     clearable: {
      type: Boolean,
      default: false,
    },
    value:null,
    text: { type: String, default: "" },
    label: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    noDefault: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(value) {
      this.item = value;
    },
  },
  methods: {
    focus(){
      this.$refs.sdepartment.focus();
    },
    input(val) {
      this.$emit("input", val);
    },
  
    },
    data: () => ({
        item: 0,
        val:'',
        items: [],
        config: {},
    }),
  
    created() {
          this.config = {
        title: "Departamento",
        url: _sGenericService.getDepartment(),
        params: { requestID: this.$fun.getUserID() },
      };
      
        

    }

  }

</script>
