let children = []

children.push({
    path: "/produccionfrescos/Razones_de_paradas",
    name: "PRF_TURN_REASON_STOP",
    component: () =>
        import ("../views/FreshProduction/Turn/TurnStopReason.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Productividad", disabled: true },
            { text: "Motivos de Paradas", href: "/produccionfrescos/Razones_de_paradas" },
        ],
    },
});


children.push({
    path: "/produccionfrescos/RegistroIncidenciaContainer",
    name: "PRF_INCCONT",

    component: () =>
        import ("../views/FreshProduction/IncidenceContainer/IncidenceContainerVG.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Registro de Registro de Incidencias de Container", href: "/produccionfrescos/RegistroIncidenciaContainer" },
        ],
    },
});
children.push({
    path: "/produccionfrescos/RegistroIncidenciaLiquidacion",
    name: "PRF_INCLIQUI",

    component: () =>
        import ("../views/FreshProduction/InidenceLiquidation/IncidenceLiquidationVG.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Registro de Incidencias de Liquidaciones OV", href: "/produccionfrescos/RegistroIncidenciaLiquidacion" },
        ],
    },
});

children.push({
    path: "/produccionfrescos/RegistroActividades",
    name: "PRF_ACTIVITYAREA",

    component: () =>
        import ("../views/FreshProduction/ActivityArea/ActivityAreaVG.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Registro de Actividades", href: "/produccionfrescos/RegistroActividades" },
        ],
    },
});

children.push({
    path: "/produccionfrescos/RegistroTrurnos",
    name: "PRF_TURN",

    component: () =>
        import ("../views/FreshProduction/Turn/CreateTurn.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Registro de Turnos", href: "/produccionfrescos/RegistroTrurnos" },
        ],
    },
});

children.push({
    path: "/produccionfrescos/SupplyCalibrator",
    name: "PRF_LOTPRODUCTIONFILTER",

    component: () =>
        import ("../views/FreshProduction/LotProductionCalibrated/SupplyCalibrator.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Abastecer Calibradora", href: "/produccionfrescos/SupplyCalibrator" },
        ],
    },
});

children.push({
    path: "/produccionfrescos/supplycalibratorlist",
    name: "PRF_PALLET_SUPPLY",

    component: () =>
        import ("../views/FreshProduction/LotProductionCalibrated/SupplyCalibratorList.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Parihuelas abastecidas", href: "/produccionfrescos/supplycalibratorlist" },
        ],
    },
});


children.push({
    path: "/produccionfrescos/MuestreoLoteProduccion",
    name: "PRF_LOTDISSAMPLE",

    component: () =>
        import ("../views/FreshProduction/LotProduction/LPDiscartSample/RegisterSampleLotProductionVG.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Filtrado De Lotes", href: "/produccionfrescos/MuestreoLoteProduccion" },
        ],
    },
});
children.push({
    path: "/LotesProduccion/RegistroDescarte",
    name: "PRF_LOTPRODUCTIONDISCART",

    component: () =>
        import ("../views/FreshProduction/LotProduction/LPDiscartWeight/RegisterDiscarLotProductionVG.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Filtrado De Lotes", href: "/LotesProduccion/RegistroDescarte" },
        ],
    },
});
children.push({
    path: "/produccionfrescos/RegistroLotesProduccion",
    name: "PRF_LOTPRODUCTIONREGISTER",

    component: () =>
        import ("../views/FreshProduction/LotProduction/LotProductionRegisterVG.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Registro De Lotes", href: "/produccionfrescos/RegistroLotesProduccion" },
        ],
    },
});

children.push({
    path: "/produccionfrescos/incidenciascanastillas",
    name: "PRF_INCIDENCELAYETTE",

    component: () =>
        import ("../views/FreshProduction/PrfLayetteIncidence/PrfLayetteIncidenceVG.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Incidencias de Canastillas", href: "/produccionfrescos/incidenciascanastillas" },
        ],
    },
});

children.push({
    path: "/produccionfrescos/Flujo de Camiones",
    name: "PRF_TRUNK",

    component: () =>
        import ("../views/FreshProduction/PrfLayetteIncidence/PrfLayetteIncidenceVG.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Flujo de camiones", href: "/produccionfrescos/Flujo de Camiones" },
        ],
    },
});

children.push({
    path: "/produccionfrescos/mapacanastillas",
    name: "PRF_BASKETMAP",
    component: () =>
        import ("../views/FreshProduction/BasketMap/BasketMapG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Mapa de Canastillas", href: "/produccionfrescos/mapacanastillas" },
        ],
    },
});

// registro de recepcion de materia prima y evaluacion de grados brix 
children.push({
    path: "/produccionfrescos/recepcionMateriaPrima",
    name: "PRF_RAWMATERIALRECEPTION",
    component: () =>
        import ("../views/FreshProduction/RawMaterialReception/MaterialReceptionG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Recepción materia prima", href: "/produccionfrescos/recepcionMateriaPrima" },
        ],
    },
});

children.push({
    path: "/produccionfrescos/pesadoporlote",
    name: "PRF_WEIGHEDBYBATCH",
    component: () =>
        import ("../views/FreshProduction/RawMaterialReception/WeighedByBatchG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Pesado por lote", href: "/produccionfrescos/pesadoporlote" },
        ],
    },
});

children.push({
    path: "/produccionfrescos/createlayette",
    name: "PRF_CREATELAYETTE",
    component: () =>
        import ("../views/FreshProduction/CreateLayette/RegisterLayette.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Creación de Canastilla", href: "/produccionfrescos/createlayette" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/Canastillas/report",
    name: "PRF_REPORT_LAYETTE",
    component: () =>
        import ("../views/FreshProduction/CreateLayette/ReportLayette.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Reporte", href: "/produccionfrescos/Canastillas/reportes" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/createlayettenew",
    name: "PRF_CREATELAYETTE_New",
    component: () =>
        import ("../views/FreshProduction/CreateLayette/CreateLayetteIndex.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Creación de Canastilla", href: "/produccionfrescos/createlayettenew" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/createpallet",
    name: "PRF_CREATEPALLET",
    component: () =>
        import ("../views/FreshProduction/CreatePallet/RegisterPallet.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Creación de Pallets", href: "/produccionfrescos/createpallet" }
        ],
    },
})

children.push({
    path: "/produccionfrescos/Albaran",
    name: "PRF_CREATE_ALBARAN",
    component: () =>
        import ("../views/FreshProduction/CreateAlbaran/Albaran.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Creación de Albaran", href: "/produccionfrescos/Albaran" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/AlbaranSAP",
    name: "PRF_CREATE_ALBARAN_SAP",
    component: () =>
        import ("../views/FreshProduction/CreateAlbaran/AlbaranSAP.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Creación de Albaran SAP", href: "/produccionfrescos/AlbaranSAP" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/cronogramaembarque",
    name: "PRF_BOARDING_SCHEDULE",
    component: () =>
        import ("../views/FreshProduction/BoardingSchedule/BoardingScheduleG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Cronograma Embarque", href: "/produccionfrescos/cronogramaembarque" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/ReceptionFresh",
    name: "PRF_RECEPTION_FRESH",
    component: () =>
        import ("../views/FreshProduction/RawMaterialReception/ReceptionFresh.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Recepcion Frescos", href: "/produccionfrescos/ReceptionFresh" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/printqrfresh",
    name: "PRF_RECEPTIONFRESH_PRINTQR",
    component: () =>
        import ("../views/FreshProduction/RawMaterialReception/PrintQRHeavy.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Imprimir QR", href: "/produccionfrescos/printqrfresh" }
        ],
    },
});



children.push({
    path: "/produccionfrescos/SupplyPallet",
    name: "PRF_LOTPRODUCTION_CALIBRATED",
    component: () =>
        import ("../views/FreshProduction/LotProductionCalibrated/SupplyPallet.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Armar Parihuela Calibrado", href: "/produccionfrescos/SupplyPallet" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/conteoetiquetas",
    name: "PRF_TAG_COUNT",
    component: () =>
        import ("../views/FreshProduction/TagCount/TagCountReadingNew.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Conteo de Etiquetas", href: "/produccionfrescos/conteoetiquetas" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/cajasporempacadora",
    name: "PRF_PACKING_BOXES",
    component: () =>
        import ("../views/FreshProduction/TagCount/PackingBoxes.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Cajas Por Empacadora", href: "/produccionfrescos/cajasporempacadora" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/Packing",
    name: "PRF_PACKING_USA",
    component: () =>
        import ("../views/FreshProduction/Packing/PackingNew.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Empaque", href: "/produccionfrescos/Packing" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/Packing",
    name: "PRF_PACKING_EUROPA",
    component: () =>
        import ("../views/FreshProduction/Packing/PackingNew.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Empaque Europa", href: "/produccionfrescos/Packing" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/camaraenfriamiento",
    name: "PRF_COOLING_CHAMBER",
    component: () =>
        import ("../views/FreshProduction/CoolingChamber/CoolingChamber.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Cámara de Enfriamiento", href: "/produccionfrescos/camaraenfriamiento" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/camara",
    name: "PRF_CHAMBER",
    component: () =>
        import ("../views/FreshProduction/CoolingChamber/Chamber.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Cámaras", href: "/produccionfrescos/camara" }
        ],
    },
});
children.push({
    path: "/produccionfrescos/Danos_Cultivo",
    name: "PRF_SAMPLE_DAMAGE_CULTIVE",
    component: () =>
        import ("../views/FreshProduction/RawMaterialReception/SampleDamage/SampleDamageCultive.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Configuración", disabled: true },
            { text: "Daños x Cultivo", href: "/produccionfrescos/Danos_Cultivo" }
        ],
    },
});



children.push({
    path: "/produccionfrescos/lotedescarte",
    name: "PRF_LOT_DISCORD",
    component: () =>
        import ("../views/FreshProduction/LotDiscard/LotDiscardG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Lote Descarte", href: "/produccionfrescos/lotedescarte" }
        ],
    },
});


// ABASTECIMIENTO 

children.push({
    path: "/produccionfrescos/Abastecimiento",
    name: "PRF_SUPPLYING",
    component: () =>
        import ("../views/FreshProduction/Supplying/SupplyingIndex.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Abastecimiento", href: "/produccionfrescos/Abastecimiento" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/Lotes_abastecidos",
    name: "PRF_LOTSSUPPLYING",
    component: () =>
        import ("../views/FreshProduction/Supplying/LotsSupplyingG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Lotes abastecidos", href: "/produccionfrescos/Lotes_abastecidos" }
        ],
    },
});


children.push({
    path: "/produccionfrescos/descartedanocultivo",
    name: "PRF_DISCART_DAMAGE_CULTIVE",
    component: () =>
        import ("../views/FreshProduction/DiscartDamageCultive/DiscartDamageCultive.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Descarte Daño Cultivo", href: "/produccionfrescos/descartedanocultivo" }
        ],
    },
});
//Impresion Etiquetas
children.push({
    path: "/produccionfrescos/ImpresionEtiquetas",
    name: "PRF_PRINT_LABEL",
    component: () =>
        import ("../views/FreshProduction/PrintLabel/PrintLabel.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Impresion de Etiquetas", href: "/produccionfrescos/ImpresionEtiquetas" }
        ],
    },
});


children.push({
    path: "/produccionfrescos/turnopresentacion",
    name: "PRF_TURN_PRESENTATION",
    component: () =>
        import ("../views/FreshProduction/TurnPresentation/TurnPresentationG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Turno - Presentación", href: "/produccionfrescos/turnopresentacion" }
        ],
    },
});


children.push({
    path: "/produccionfrescos/PackingBalanceNew",
    name: "PRF_PACKING_BALANCE",
    component: () =>
        import ("../views/FreshProduction/Packing/PackingBalanceNew.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Saldos Empaque", href: "/produccionfrescos/PackingBalanceNew" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/PrintQR",
    name: "PRF_PRINT_QR",
    component: () =>
        import ("../views/FreshProduction/LotProductionCalibrated/PrintQR.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Imprimir QR", href: "/produccionfrescos/PrintQR" }
        ],
    },
});

// vista solicitud de pedido

children.push({
    path: "/produccionfrescos/SolicitudPedido",
    name: "PRF_CALIBRATED_ORDER_REQUEST",
    component: () =>
        import ("../views/FreshProduction/LotProductionCalibrated/OrderRequest.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Solicitud de pedido", href: "/produccionfrescos/SolicitudPedido" }
        ],
    },
});


children.push({
    path: "/produccionfrescos/etiquetassupervisor",
    name: "PRF_TAG_SUPERVISOR",
    component: () =>
        import ("../views/FreshProduction/TagCount/TagSupervisor.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Etiquetas Supervisor", href: "/produccionfrescos/etiquetassupervisor" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/temperaturepallet",
    name: "PRF_TEMPERATURE_PALLET",
    component: () =>
        import ("../views/FreshProduction/TemperaturePallet/TemperaturePallet.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Toma temperatura", href: "/produccionfrescos/temperaturepallet" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/expectativasrazonables",
    name: "PRF_REQUERIMENTS",
    component: () =>
        import ("../views/FreshProduction/ReasonableExpectation/ReasonableExpectation.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Expectativas Razonables", href: "/produccionfrescos/expectativasrazonables" }
        ],
    },
});
children.push({
    path: "/produccionfrescos/closelotcalibrated",
    name: "PRF_CLOSE_CALIBRATED",
    component: () =>
        import ("../views/FreshProduction/LotProductionCalibrated/CloseLotCalibrated.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Cerrar Lote Calibrado", href: "/produccionfrescos/closelotcalibrated" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/printqrpallet",
    name: "PACKING_QR_PRINT",
    component: () =>
        import ("../views/FreshProduction/Packing/PackingQR.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Imprimir QR", href: "/produccionfrescos/printqrpallet" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/traceabilityindex",
    name: "PRF_PACKING_SCANQR",
    component: () =>
        import ("../views/FreshProduction/PrfTraceability/PrfTraceabilityIndex.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "YTrazabilidad", href: "/produccionfrescos/traceabilityindex" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/tank",
    name: "PRF_LAYETTE_TANK",
    component: () =>
        import ("../views/FreshProduction/CreateLayette/Tank.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Tanques", href: "/produccionfrescos/tank" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/reportheavy",
    name: "PRF_REPORT_HEAVY",
    component: () =>
        import ("../views/FreshProduction/RawMaterialReception/ReportHeavy.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Reporte Pesadas", href: "/produccionfrescos/reportheavy" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/assigncaliber",
    name: "PRF_ASSIGN_CALIBER",
    component: () =>
        import ("../views/FreshProduction/LotProductionCalibrated/AssignCaliberAssistant.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Asignacion Calibres", href: "/produccionfrescos/assigncaliber" }
        ],
    },
});


// REPROCESO 
children.push({
    path: "/produccionfrescos/reproceso",
    name: "PRF_REWORK",
    component: () =>
        import ("../views/FreshProduction/LotProductionCalibrated/Rework.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Reproceso", href: "/produccionfrescos/reproceso" }
        ],
    },
});


children.push({
    path: "/produccionfrescos/controlstop",
    name: "PRF_CONTROL_STOP",
    component: () =>
        import ("../views/FreshProduction/ControlStop/ControlStop.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Control Paradas", href: "/produccionfrescos/controlstop" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/sendfrozen",
    name: "PRF_SEND_FROZEN",
    component: () =>
        import ("../views/FreshProduction/CreateLayette/SendFrozen.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Enviar Congelado", href: "/produccionfrescos/sendfrozen" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/ConfigColor",
    name: "PRF_COLOR",
    component: () =>
        import ("../views/FreshProduction/RawMaterialReception/ConfigColor.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Colores", href: "/produccionfrescos/ConfigColor" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/packingshipment",
    name: "PRF_Shipment",
    component: () =>
        import ("../views/FreshProduction/Packing/PackingShipment.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Pre Packing", href: "/produccionfrescos/packingshipment" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/prepacking",
    name: "PRF_PREPACKING",
    component: () =>
        import ("../views/FreshProduction/Packing/PrePackingShipment.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Creación Pre Packing", href: "/produccionfrescos/prepacking" }
        ],
    },
});




children.push({
    path: "/produccionfrescos/bell",
    name: "PRF_BELL",
    component: () =>
        import ("../views/FreshProduction/Bell/Bell.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Embarque", href: "/produccionfrescos/bell" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/PrfWeighedParamConfig",
    name: "PRF_WEIGHED_PARAM",
    component: () =>
        import ("../views/FreshProduction/PrfWeighedParamConfig/PrfWeighedParamConfig.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Param. x Cultivo", href: "/produccionfrescos/PrfWeighedParamConfig" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/configturnos",
    name: "PRF_TURN_CONFIG",
    component: () =>
        import ("../views/FreshProduction/PrfTurnConfig/PrfTurnConfig.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Config", href: "/produccionfrescos/configturnos" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/analysisguide",
    name: "PRF_ANALYSISGUIDE",
    component: () =>
        import ("../views/FreshProduction/RawMaterialReception/PrfAnalysisGuide.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Cambios Guias de Cosecha", href: "/produccionfrescos/analysisguide" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/resumeparihuela",
    name: "PRF_RESUME_PARIHUELA",
    component: () =>
        import ("../views/FreshProduction/LotProductionCalibrated/PrfResumeParihuela.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Resumen", href: "/produccionfrescos/resumeparihuela" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/reportcalibratedall",
    name: "PRF_REPORTCALIBRATED_ALL",
    component: () =>
        import ("../views/FreshProduction/LotProductionCalibrated/PrfReportCalibratedAll.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Reportes", href: "/produccionfrescos/reportcalibratedall" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/optionsadvanced",
    name: "PRF_OPTIONSAVANCED",
    component: () =>
        import ("../views/FreshProduction/RawMaterialReception/PrfOptionsAdvanced.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Opciones Avanzadas", href: "/produccionfrescos/optionsadvanced" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/receptionfreshresume",
    name: "PRF_RESUME_RECEPTIONFRESH",
    component: () =>
        import ("../views/FreshProduction/RawMaterialReception/PrfReceptionResume.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Resumen KG", href: "/produccionfrescos/receptionfreshresume" }
        ],
    },
});


children.push({
    path: "/produccionfrescos/reportcoolingchamber",
    name: "PRF_COOLING_CHAMBER_REPORT",
    component: () =>
        import ("../views/FreshProduction/CoolingChamber/ReportCoolingChamber.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Reportes", href: "/produccionfrescos/reportcoolingchamber" }
        ],
    },
});


children.push({
    path: "/produccionfrescos/receptionreports",
    name: "PRF_RECEPTION_REPORT",
    component: () =>
        import ("../views/FreshProduction/ReceptionReport/ReceptionReport.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Reportes Recepción", href: "/produccionfrescos/receptionreports" }
        ],
    },
});


children.push({
    path: "/produccionfrescos/packingreport",
    name: "PRF_PACKING_REPORT",
    component: () =>
        import ("../views/FreshProduction/PackingReport/PackingReport.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Reportes Empaque", href: "/produccionfrescos/packingreport" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/layettepositions",
    name: "PRF_POSITION_LAYETTE",
    component: () =>
        import ("../views/FreshProduction/CreateLayette/CreateLayettePositions.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Reportes Empaque", href: "/produccionfrescos/layettepositions" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/trucksflow",
    name: "PRF_TRUCK_FLOW",
    component: () =>
        import ("../views/FreshProduction/TruckFlow/TruckFlow.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Flujo camiones", href: "/produccionfrescos/trucksflow" }
        ],
    },
});
children.push({
    path: "/produccionfrescos/lpprendientesporabastecer",
    name: "PRF_EARNING_LP",
    component: () =>
        import ("../views/FreshProduction/LotProductionCalibrated/PrfEarningCertification.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "LP Pendientes x Certificaciones", href: "/produccionfrescos/lpprendientesporabastecer" }
        ],
    },
});




children.push({
    path: "/produccionfrescos/productivitytunnel",
    name: "PRF_PRODUCTIVITY_TUNNEL",
    component: () =>
        import ("../views/FreshProduction/ProductivityTunnel/ProductivityTunnel.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Productividad Tunel", href: "/produccionfrescos/productivitytunnel" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/lotprogramming",
    name: "PRF_LP_PRGRAMMMING",
    component: () =>
        import ("../views/FreshProduction/RawMaterialReception/PrfLotProgramming.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Programaciones", href: "/produccionfrescos/lotprogramming" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/lotcalibratedview",
    name: "PRF_LP_CALIBRATED_VIEW",
    component: () =>
        import ("../views/FreshProduction/LotProductionCalibrated/PrfLotCalibratedView.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Programaciones", href: "/produccionfrescos/lotcalibratedview" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/parihuelamixed",
    name: "PRF_PARIHUELA_MIXED",
    component: () =>
        import ("../views/FreshProduction/LotProductionCalibrated/SupplyPalletMixed.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Parihuela Mixta", href: "/produccionfrescos/parihuelamixed" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/entrytunnelchamber",
    name: "PRF_ENTRY_TUNNELCHAMBER",
    component: () =>
        import ("../views/FreshProduction/CoolingChamber/Entry/EntryIndex.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Ingreso Tunnel/Camara", href: "/produccionfrescos/entrytunnelchamber" }
        ],
    },
});

// MANTENIMIENTO
children.push({
    path: "/produccionfrescos/mantenimientoticket",
    name: "PRF_MAINTENANCE_TICKET",
    component: () =>
        import ("../views/FreshProduction/Maintenance/MaintenanceTicket.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Mantenimiento Ticket", href: "/produccionfrescos/mantenimientoticket" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/mantenimientocargo",
    name: "PRF_MAINTENANCE_POSITION",
    component: () =>
        import ("../views/FreshProduction/Maintenance/MaintenancePosition.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Mantenimiento Cargo", href: "/produccionfrescos/mantenimientocargo" }
        ],
    },
});
children.push({
    path: "/produccionfrescos/mantenimientomalla",
    name: "PRF_MAINTENANCE_NETTING",
    component: () =>
        import ("../views/FreshProduction/Maintenance/MaintenanceNetting.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Mantenimiento Malla", href: "/produccionfrescos/mantenimientomalla" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/tiempocalibre",
    name: "PRF_TIME_CALIBER",
    component: () =>
        import ("../views/FreshProduction/TimeCaliber/TimeCaliber.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Tiempo de Calibre", href: "/produccionfrescos/tiempocalibre" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/packinglist",
    name: "PRF_PACKING_LIST",
    component: () =>
        import ("../views/FreshProduction/CoolingChamber/PackingList.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Packing List", href: "/produccionfrescos/packinglist" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/tarimas",
    name: "PRF_SCAFFOLD",
    component: () =>
        import ("../views/FreshProduction/ConfigFresh/Scaffold.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Tarimas", href: "/produccionfrescos/tarimas" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/tagbaler",
    name: "PRF_TAG_BALER",
    component: () =>
        import ("../views/FreshProduction/TagBaler/TagBaler.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Packing List", href: "/produccionfrescos/tagbaler" }
        ],
    },
});


children.push({
    path: "/produccionfrescos/transferkilos",
    name: "PRF_TRANSFER_KILO",
    component: () =>
        import ("../views/FreshProduction/TransferKilo/TransferKilo.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Packing List", href: "/produccionfrescos/transferkilos" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/SupplyPalletm",
    name: "PRF_LOTPRODUCTION_CALIBRATED_MIRROR",
    component: () =>
        import ("../views/FreshProduction/LotProductionCalibrated/SupplyPalletMirror.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Armar Parihuela Calibrado", href: "/produccionfrescos/SupplyPalletm" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/reportgeneral",
    name: "PRF_REPORTS_GENERAL",
    component: () =>
        import ("../views/FreshProduction/ReportGeneral/Report.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Reportes", href: "/produccionfrescos/reportgeneral" }
        ],
    },
});

children.push({
    path: "/produccionfrescos/tablacanastillas",
    name: "PRF_BASKETTABLE",
    component: () =>
        import ("../views/FreshProduction/BasketMap/BasketMapGTable.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Tabla de Canastillas", href: "/produccionfrescos/tablacanastillas" },
        ],
    },
});
children.push({
    path: "/produccionfrescos/listpalletf",
    name: "PRF_PALLET_CHANGE_F",
    component: () =>
        import ("../views/FreshProduction/LotProductionCalibrated/SupplyPalletf.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Lista de Pallet", href: "/produccionfrescos/listpalletf" },
        ],
    },
});
children.push({
    path: "/produccionfrescos/searchlotsused",
    name: "PRF_SEARCH_LOTS_USED",
    component: () =>
        import ("../views/FreshProduction/Packing/searchlotsused.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Lista de Pallet", href: "/produccionfrescos/searchlotsused" },
        ],
    },
});

children.push({
    path: "/produccionfrescos/ticketdemo",
    name: "PRF_READ_DEMO",
    component: () =>
        import ("../views/FreshProduction/TicketDemo/TicketDemo.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Lectura Demo", href: "/produccionfrescos/ticketdemo" },
        ],
    },
});

children.push({
    path: "/produccionfrescos/mantenimientozone",
    name: "MAINTENANCE_ZON_LOCATION",
    component: () =>
        import ("../views/FreshProduction/Maintenance/MaintenanceZonLocation.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "Zonas de Pago por Peso guia", href: "/produccionfrescos/mantenimientozone" }
        ],
    },
});

export { children }