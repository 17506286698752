<template>
  <div style="padding-top: -2px; margin-top: -2px">
    <template>
      <v-label>
        <b>{{ label }}</b>
      </v-label>

      <s-select-generic
        autocomplete
        class="text"
        itemvalue="VehID"
        itemtext="VehPlate"
        style="margin-top: 0px"
        :config="config"
        :value="value"
        @returnObject="returnObject"
        :return-object="returnObject"
        :add="add"
        :slot-item="toProgramming"
        @add="addEvent()"
        @input="input($event)"
        :clearable="clearable"
        :full="full"
        :text="text"
      >
        <template v-if="toProgramming" v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-html="item.VehPlate" />
            <v-list-item-subtitle v-html="item.TypeVehicleText + ' : ' + item.TypeBrandText + ' ' + item.TypeModelText" />
          </v-list-item-content>
        </template>
      </s-select-generic>
    </template>

    <v-dialog max-width="900" v-model="dialog" persistent>
      <vehicle-edit @save="save()" @close="dialog = false" v-model="vehID" />
    </v-dialog>
  </div>
</template>

<script>
import _sVehicle from "@/services/Management/VehicleService";
import vehicleEdit from "@/views/Management/Vehicle/VehicleEdit";

export default {
  components: { vehicleEdit },
  props: {
    toProgramming: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Vehículo",
    },
    value: null,
    add: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    text: { type: String, default: "" },
    returnObject: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vehID: 0,
      dialog: false,
      item: 0,
      config: {
        url: _sVehicle.listURL(),
        params: {
          typeOperation: this.toProgramming ? 2 : 1, //1 Todos, 2 Solo Para Control de programacion
          requestID: this.$fun.getUserID(),
        },
      },
    };
  },
  watch: {},
  methods: {
    addEvent() {
      this.dialog = true;
    },
    input(item) {
      this.$emit("input", item);
    },
    returnObject(item) {
      this.$emit("returnObject", item);
    },
  },
};
</script>
