let children = []

children.push({
    path: "/mesaayuda/mantenimiento/temasayuda",
    name: "HDK_HELP_SUBJECT",
    component: () =>
        import ("../views/HelpDesk/HelpSubject/HelpSubject.vue"),
    meta: {
        authenticated: true,
        breadcrum: [
            { text: "Inicio", href: "/#/" },
            { text: "Mesa Ayuda", disabled: true },
            { text: "Temas Ayuda", href: "/mesaayuda/mantenimiento/temasayuda" },
        ],
    },
});

children.push({
    path: "/mesaayuda/mantenimiento/agentes",
    name: "HDK_AGENT",
    component: () =>
        import ("../views/HelpDesk/Agent/Agent.vue"),
    meta: {
        authenticated: true,
        breadcrum: [
            { text: "Inicio", href: "/#/" },
            { text: "Mesa Ayuda", disabled: true },
            { text: "Agentes", href: "/mesaayuda/mantenimiento/agentes" },
        ],
    },
});

children.push({
    path: "/mesaayuda/tickets",
    name: "HDK_TICKET",
    component: () =>
        import ("../views/HelpDesk/Ticket/Ticket.vue"),
    meta: {
        authenticated: true,
        breadcrum: [
            { text: "Inicio", href: "/#/" },
            { text: "Mesa Ayuda", disabled: true },
            { text: "Ticket", href: "/mesaayuda/tickets" },
        ],
    },
});

export { children }