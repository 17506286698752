<template>

	<v-card outlined >
		<!-- <template #title>
			<h6>Sales Overview</h6>			
			<p>than last year <span class="text-success">+20%</span></p>	
		</template>
		<template #extra>
			<a-badge color="primary" class="badge-dot-primary" text="Traffic" />
			<a-badge color="primary" class="badge-dot-secondary" text="Sales" />
		</template> -->
		<chart-line :height="210" :data="lineChartData"></chart-line>
	</v-card>

</template>

<script>

	// Bar chart for "Active Users" card.
	import ChartLine from '../Charts/ChartLine' ;

	export default ({
        props:{ 
            objLineX: {type: Array, default: []},
            objLineY: {type: Array, default: []},
			text: {type:String, default: "KG: "},
        },
		components: {
			ChartLine,
            
		},

        
		data() {

			return {
                
				// Data for line chart.
				lineChartData: {
					labels: this.objLineX,//["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"],
					datasets: [
						
					{
						label: this.text,
						tension: 0.2,
						borderWidth: 0,
						pointRadius: 5,
						borderColor: "#1890FF",
						borderWidth: 3,
						data: this.objLineY, //[50, 40, 300, 220, 500, 250, 400, 230, 500],
						maxBarThickness: 6,
                        pointStyle: 'circle',
						

					},
					
					],
				},
			}
		},
	})

</script>