import Service from "../Service";
import Vue from "vue";
const resource = "technicalVisit/";

export default {
    save(ded, requestID) {
        return Service.post(resource + "save", ded, {
            params: { requestID: requestID },
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: {
                requestID: requestID,
            },
        });
    },
    paginationSna(parameters, requestID) {
        return Service.post(resource + "paginationSNA", parameters, {
            params: {
                requestID: requestID,
            },
        });
    },
    listtasarticletypevisita(dedId, search, requestid) {
        return Service.post(resource + "listtasarticletypevisita", {}, {
            params: { DedId: dedId, Search: search, RequestID: requestid }
        });
    },
    GetUrllisttasarticletypevisita() {
        return Vue.prototype.$http.defaults.baseURL + "/technicalVisit/listtasarticletypevisita";
    },

    listLote(fundo, search, requestid) {
        return Service.post(resource + "listLote", {}, {
            params: { Fundo: fundo, Search: search, RequestID: requestid }
        });
    },
    GetUrllistLote() {
        return Vue.prototype.$http.defaults.baseURL + "/technicalVisit/listLote";
    },
    listMaterial(TvID) {
        return Service.post(resource + "listMateriales", {}, {
            params: { TvID: TvID }
        })
    },
    listdatoslote(FarmLot) {
        return Service.post(resource + "listdatoslote", {}, {
            params: { FarmLot: FarmLot }
        })
    },
    listdatossupervisor(NtpID) {
        return Service.post(resource + "listdatossupervisor", {}, {
            params: { NtpID: NtpID }
        })
    },
    BySupervisorByDate(parameters, requestID) {
        return Service.post(resource + "BySupervisorByDate", parameters, {
            params: {
                requestID: requestID,
            },
        });
    },
    excel(dtr, requestID) {
        console.log(dtr);
        return Service.post(resource + "excel", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },
};