<template>
    <div>
        <v-card
            class="  m-0 pb-0 pt-0 mb-0"
            max-width="500"  elevation="0"
        >
            <template slot="progress">
            <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
            ></v-progress-linear>
            </template>

            <!-- <v-card-title>Calculator</v-card-title> -->

            <v-card-text class="pb-0 pt-0" style="text-align: right;">
                <div class="subheading" style="min-height:24px;">
                    {{operation}}
                </div>
                <div class="headline" style="min-height:28px;">
                    {{result}}
                </div>
            </v-card-text>

            <v-divider class="pr-0 pl-0 mr-0 ml-0"></v-divider>

            <v-card-text class="pr-0 pl-0 mr-0 ml-0">

                <v-row>
                    <v-col class="">
                        <v-btn x-small @click="clickAC()">
                            Backspace
                        </v-btn>

                        <v-btn x-small @click="clickAC()">
                            AC
                        </v-btn>

                        <v-btn x-small @click="clickC()">
                            C
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="pt-0 pb-0">
                        <v-btn x-small @click="click('7')">
                            7
                        </v-btn>

                        <v-btn x-small @click="click('8')">
                            8
                        </v-btn>

                        <v-btn x-small @click="click('9')">
                            9
                        </v-btn>

                        <v-btn x-small @click="click('*')">
                            x
                        </v-btn>

                        <v-btn x-small @click="click('÷')">
                            ÷
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="pb-0">
                        <v-btn x-small @click="click('4')">
                            4
                        </v-btn>

                        <v-btn x-small @click="click('5')">
                            5
                        </v-btn>

                        <v-btn x-small @click="click('6')">
                            6
                        </v-btn>

                        <v-btn x-small @click="click('+')">
                            +
                        </v-btn>

                        <v-btn x-small @click="click('-')">
                            -
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col class="pb-0">
                        <v-btn x-small @click="click('1')">
                            1
                        </v-btn>

                        <v-btn x-small @click="click('2')">
                            2
                        </v-btn>

                        <v-btn x-small @click="click('3')">
                            3
                        </v-btn>

                        <v-btn x-small @click="click('%')">
                            %
                        </v-btn>

                        <v-btn x-small @click="click('√')">
                            √
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-btn x-small @click="click('0')">
                            0
                        </v-btn>

                        <v-btn x-small @click="clickSignChange()">
                            +-
                        </v-btn>

                        <v-btn x-small @click="click('.')">
                            .
                        </v-btn>

                        <v-btn x-small @click="clickReverse()">
                            1/x
                        </v-btn>

                        <v-btn x-small @click="clickResult()">
                            =
                        </v-btn>
                    </v-col>
                </v-row>

            </v-card-text>

            <v-card-actions class="mt-0 pt-0">
                <v-row>
                    <v-col class="pl-0">
  <v-btn block
                    depressed x-small
                    color="error"
                    rounded  outlined
                >
                    Cerrar
                </v-btn>
                    </v-col>
                    <v-col class="pr-0 pl-0">
                          <v-btn  x-small block
                    depressed
                    color="primary"
                    rounded
                    @click="resultEvent()"
                >
                    Copiar
                </v-btn>
                    </v-col>
                </v-row>
              
              
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    export default {
        data: () => ({
            operation: '',
            result: ''
        }),
props:{  data:0},
        methods: {
            click(param) {
                if(this.result == '')
                {
                    this.result = param;
                }
                else {
                    this.result += param;
                }
            },

            clickAC()
            {
                this.operation = '';
                this.result = '';
            },

            clickC()
            {
                if(this.result.length > 1)
                {
                    this.result = this.result.substring(0, this.result.length - 1);
                }
                else{
                    this.result = '0';
                }
            },

            clickSignChange()
            {
                if(this.result.includes('-'))
                {
                    this.result = this.result.replace('-', '');
                }
                else{
                    this.result = '-' + this.result;
                }
            },

            clickReverse()
            {
                if(this.result != '0')
                {
                    let cadena = this.result;
                    let reemplazo = '';
                    for (let i = cadena.length - 1; i >= 0; i--)
                    {
                        if(!isNaN(cadena.charAt(i)))
                        {
                            reemplazo += cadena.charAt(i);
                        }else{
                            break;
                        }
                    }
                    reemplazo = reemplazo.split("").reverse().join("");
                    let newValue = '1/'+reemplazo;
                    this.result = this.result.replace(reemplazo, newValue);
                }
            },

            clickResult()
            {
                let operation = this.result;
                this.operation = operation;
                
                if(
                    operation.includes('*') || 
                    operation.includes('÷') || 
                    operation.includes('+') || 
                    operation.includes('%') ||
                    operation.includes('/') ||
                    operation.includes('√') ||
                    operation.includes('-')
                )
                {
                    operation = operation.replace('÷', '/');
                    operation = operation.replace('%', '/100');

                    //reemplazar raiz cuadrada
                    if(operation.includes('√'))
                    {
                        let posicion = operation.indexOf('√');
                        let reemplazo = '';
                        let posicionFinal;
                        for(let i = posicion+1; i < operation.length; i++)
                        {
                            /* console.log(!isNaN(operation.charAt(i)))
                            console.log('tag', operation.charAt(i)) */
                            if(!isNaN(operation.charAt(i))){
                                reemplazo += operation.charAt(i);
                            }else{
                                break;
                            }
                            posicionFinal = i;
                        }

                        console.log('reemplazoo', reemplazo);
                        let raizCuadrada = Math.sqrt(reemplazo);
                        let cadena = '√'+reemplazo;

                        operation = operation.replace(cadena, raizCuadrada);
                        console.log('operacionnnnn', operation);
                    }
                    //fin de reemplazar raiz cuadrada
                    console.log('operacion', operation);
                    this.result = eval(operation);
                }                
            },

            resultEvent()
            {
                console.log('emitiendo resultado');
                this.$emit('getResult', this.result);
            }

        },
        watch:{
            data(){
                this.operation=0;
                this.result=this.data;
            }
        }
    }
</script>

<style scoped>

</style>