import Service from "../Service";

const resource = "configuration/";

export default {
  save(config, requestID) {
    console.log(config)
    return Service.post(resource + "save", config, {
      params: {
        requestID: requestID
      },
    });
  },
};
