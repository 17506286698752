<template>
  <div>
    <s-text
      v-model="valueModel"
      :label="label"
      :readonly="readonly"
      :disabled="disabled"
      :clearable="clearable"
      :dark="dark"
      @blur="blur"
      @click="click"
      @input="input"
      @keyup.enter="enter"
      type="tel"
      :mask="formatMask"
      :placeholder="formatView"
      :search="true"
    >
      <template>
        <v-menu
          v-if="!noPicker"
          slot="append"
          :close-on-content-click="false"
          v-model="visiblePicker"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="mt-1">mdi-clock</v-icon>
          </template>

          <v-time-picker locale="es" v-model="valuePicker" @input="inputPicker" />
        </v-menu>
      </template>
    </s-text>
  </div>
</template>

<script>
export default {
  name: "STime",
  components: {},
  props: {
    landscape: { type: Boolean, default: true },
    noPicker: { type: Boolean, default: false },

    label: { type: String, default: "Hora" },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    nullable: { type: Boolean, default: false },
    dark: { type: Boolean, default: false },
    noPicker: { type: Boolean, default: false },
    value: null,
    type: {
      type: String,
      default: "time",
      validator: function (val) {
        return ["time"].indexOf(val) !== -1;
      },
    },
    visible: { type: Boolean, default: false },
  },
  watch: {
    config() {},
  },
  watch: {
    item(value) {
      this.$emit("input", value);
    },
    value(value) {
      this.item = value;
    },
  },
  mounted() {
    this.item = this.value;
  },
  data: () => ({
    //ComboBox
    menu1: "",
    item: "",

    valueModel: null,
    valuePicker: null,

    visiblePicker: false,
    formatMask: "",
    formatDB: "",
    formatView: "",
    date: false,
    datetime: false,
  }),
  created() {
    this.initialize();

    this.$nextTick(function () {
      if (!this.nullable && this.$fun.isEmptyVal(this.value)) {
        let value = this.moment();
        this.valuePicker = value.format(this.formatDB);
        this.valueModel = value.format(this.formatView);

        this.$emit("input", this.valuePicker);
      }
    });
  },
  methods: {
    initialize() {
      this.date = false;
      this.datetime = false;

      if (this.type === "time") {
        this.date = true;
        this.formatMask = this.$const.FormatTimeMask;
        this.formatDB = this.$const.FormatTimeDB;
        this.formatView = this.$const.FormatTimeView;
      }
    },
    click(event) {
      this.$emit("click", event);
    },
    blur(event) {
      this.$emit("blur", event);
    },
    enter(event) {
      this.$emit("enter", event);
    },
    inputPicker() {
      this.valueModel = this.moment(this.valuePicker, this.formatDB).format(this.formatView);
      this.$emit("input", this.valuePicker);
      if(this.visible){
        this.visiblePicker = true;
      }
      else{
        this.visiblePicker = false;
      }
      
    },
    input() {
      console.log(this.valueModel);

      if (this.valueModel != null && this.valueModel != "")
        if ((!this.valueModel.includes("T") || !this.valueModel.includes("-")) && this.valueModel.length >= this.formatMask.length) {
          let x = this.moment(this.valueModel, this.formatView);
          if (x.isValid()) this.$emit("input", x.format(this.formatDB));
          return;
        }

      // this.$emit("input", val);
      if (this.clearable) this.$emit("input", null);
    },
  },
};
</script>
