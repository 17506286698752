import Service from "../Service";

const resource = "/receptionfreshunload/";

export default {

    pagination(parameters, requestID) {
        //console.log("sdfsdfsd", parameters);
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },


    saveStop(obj, requestID) {
        return Service.post(resource + "saveStop", obj, {
            params: { requestID: requestID },
        });
    },

    list(obj, requestID) {
        return Service.post(resource + "list", obj, {
            params: { requestID: requestID },
        });
    },

    searchbyrcfid(RcfID, requestID) {
        return Service.post(resource + "searchbyrcfid", {}, {
            params: { RcfID, requestID: requestID },
        });
    },

    convertexcel(obj, requestID) {
        return Service.post(resource + "convertexcel", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID }
        })
    },

    converterpdf(obj, requestID) {
        return Service.post(resource + "converterpdf", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID }
        })
    },

    getReferraltGuide(obj, requestID) {
        return Service.post(resource + "getReferralGuide", obj, {
            params: { requestID: requestID },
        });
    },

    stopUnload(obj, requestID) {
        return Service.post(resource + "unloadStop", obj, {
            params: { requestID: requestID },
        });
    },
    getNumberPersonByGang(AtaID, requestID){
        return Service.post(resource + "getNumberPersonGang", {}, {
            params: { ataID: AtaID, requestID: requestID },
        });
    }


}