<template>
  <v-container grid-list-xs>
    <v-row class="px-0 mx-0">
      <v-icon> mdi-home </v-icon>
      <v-breadcrumbs :items="items" class="px-2 py-0">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>
  </v-container>
</template>
<script>
export default {
  components: {},
  watch: {
    $route() {
      this.items = this.$route.meta.breadcrumb;
    },
  },
  mounted() {
    this.items = this.$route.meta.breadcrumb;
  },
  data() {
    return {
      items: [],
    };
  },
};
</script>
