window.$ = window.jQuery = require("jquery");
import push from "push.js";

import Vue from "vue";
import App from "./Core.vue";
import router from "./router";

import fun from "./plugins/fun";
import base from "./plugins/base";
import constapp from "./plugins/const";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "./services/Service";
import _ from "lodash";
import moment from "moment";
import VueMoment from "vue-moment";
import IdleVue from "idle-vue";
import VueMask from "vue-the-mask";
import panZoom from "vue-panzoom";
import VueHtmlToPaper from 'vue-html-to-paper';
import HighchartsVue from "highcharts-vue";

import Datetime from 'vue-datetime' //jmr
import 'vue-datetime/dist/vue-datetime.css' //jmr

import VTooltip from 'v-tooltip'
// install plugin
Vue.use(panZoom);
Vue.use(HighchartsVue);
Vue.use(Datetime)//jmr
Vue.use(VTooltip, {
    defaultHtml: false,
  });
/** Activate vue.js plugins **/
Vue.use(VueMask);
const eventsHub = new Vue();
Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    idleTime: store.state.TimeInactive,
    startAtIdle: false,
});

Vue.use(VueHtmlToPaper);

Vue.use(_);
//Vue.use (VueAxios, axios)
Vue.prototype.moment = moment;
Vue.prototype.$const = constapp;
Vue.prototype.$fun = fun;
Vue.prototype.$push = push;
Vue.prototype.$http = axios;
//axios.defaults.timeout = 10000;
axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token != null) {
            config.headers.common["Authorization"] = "Bearer " + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    (response) => {
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    (error) => {
        if (error.response == null) {
            fun.alertFull("Cargando datos...", "warning");
            console.log("error el master daster baster", error)
            fun.alert("Cargando datos...", "warning");
            console.log("error el master daster baster", error)
        }
        //console.log(error.response);

        if (error.response.status) {
            switch (error.response.status) {
                case 400:
                    if (error.response.data.Message.includes("Personal no asignado al bus")) {
                        break;
                    }
                    fun.alert(error.response.data.Message, "warning");
                    console.log(error.response.data);
                    break;
                case 401:
                    localStorage.setItem("isIdle", false);
                    document.onload = null;
                    document.onmousemove = null;
                    document.onmousedown = null;
                    document.ontouchstart = null;
                    document.onclick = null;
                    document.onscroll = null;
                    localStorage.clear();
                    localStorage.removeItem("token");
                    router.push("/login");
                    if (localStorage.getItem("token") != null)
                    //  localStorage.clear();router.replace({ path: "/login",query: { redirect: router.currentRoute.fullPath },);
                        fun.alert("La sesión ha caducado.", "warning");
                    break;
                case 403:
                    fun.alert("403", "warning");
                    break;
                case 404:
                    // en observacion : DAVID DOMADOR
                    if(localStorage.getItem("token") != null){
                        fun.alert("El servicio solicitado no existe.");
                    }else {
                        router.push("/login");
                    }

                    break;
                case 500:
                    // localStorage.clear();
                    // router.replace({
                    //   path: "/login",
                    //   query: { redirect: router.currentRoute.fullPath },
                    // });
                    if (localStorage.getItem("token") != null) fun.alert(error.response.data.ExceptionMessage, "error");
                    break;
                case 502:
                    if (localStorage.getItem("token") != null) fun.alert(error.response.data.Message, "error");
                    break;
            }
            //console.log(' Promise.reject(error) ', Promise.reject(error));
            return Promise.reject(error);
        }
    }
);

//vue filter
Vue.filter('numberDefaultKg', (value) => {
    // console.log("valor tuberia", value)
    if( value == -Infinity ) return 0; 
    if( value == Infinity ) return 0; 

    if( isNaN(value) ) return 0; 
    if( value == undefined ) return 0; 
    // if( value < 0 ) return 0; 
         
    
    // return value;
    return new Intl.NumberFormat("es-PE", { 
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2,
        // style: "unit",
        // unit: "KGM",
    }).format(value) + " Kg"
})

Vue.filter('numberDefault', (value) => {
    // console.log("valor tuberia", value)
    if( value == -Infinity ) return 0; 
    if( value == Infinity ) return 0; 

    if( isNaN(value) ) return 0; 
    if( value == undefined ) return 0; 
    // if( value < 0 ) return 0; 
         
    
    // return value;
    return new Intl.NumberFormat("es-PE", { 
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2 ,
        // style: "unit",
        // unit: "KGM",
    }).format(value)
})

// Load Locales ('en' comes loaded by default)
require("moment/locale/es");

// Choose Locale
moment.locale("es");

Vue.use(VueMoment, { moment });
Vue.directive("focus", {
    inserted: function(el) {
        el.firstChild.firstChild.childNodes[1].childNodes[1].focus();
    },
});

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    Datetime,//jmr
    VTooltip,
    render: (h) => h(App),
}).$mount("#app");