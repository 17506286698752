<template
  ><div style="padding-top:-2px;margin-top:-2px;">
    <s-select-generic
      autocomplete
      itemtext="GenDescription"
      itemvalue="GenID"
      id="GenDescription"
      :value="value"
      @input="input($event)"
      :config="config"
      :label="label" :return-object=returnObject
      placeholder="Selecciona área"
      :full="full" 
      :text="text" :clearable=clearable
    >
    </s-select-generic>
  </div>
</template>

<script>
import _sGenericService from "./../../services/General/GenericService";
export default {
  name: "",
  components: {},
  props: {
     clearable: {
      type: Boolean,
      default: false,
    },
     cceID: {
      type: Number,
      default: 0,
    },
     noDefault: {
      type: Boolean,
      default: false,
    },
    value: null,
    text: { type: String, default: "" },
    label: {
      type: String,
      default: "Linea de Negocio",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    centercost: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: true,
    },
     
  },
  watch: {
    value(value) {
      this.item = value;
    },
    cceID(){
        this.config.params.CceID=this.cceID
        this.config.params.AllLine=this.allline
    }
  },
  methods: {
    input(val) {
      this.$emit("input", val);
    },
  
    },
    data: () => ({
        item: 0,
        val:'',
        items: [],
        config: {},
        allline: 0,
    }),
  
    created() {

      if (this.$fun.hasSpecialPermission('AllLine')) {
        this.allline = 1;
      }   
   
        this.config = {
            url:_sGenericService.getUrlSearchBusinessLine(),
            title: this.label,
            params: {search:'', CceID:this.cceID, requestID:this.$fun.getUserID(), AllLine: this.allline }
        }

        console.log("this.allline permits", this.allline)
        console.log("permiso de allline", this.$fun.hasSpecialPermission('AllLine'))
    }

  }

</script>
