<template
  ><div>
      
    <s-select-generic
      autocomplete
      itemtext="GenDescription"
      itemvalue="GenHelper"
      id="GenDescription"
      :value="value"
      @input="input($event)"
      :config="config" :return-object=returnObject
      :label="label"
      placeholder=""
      :full="full" :noDefault=noDefault
      :text="text" :clearable=clearable
    >
    </s-select-generic>
  </div>
</template>

<script>
import _sGenericService from "./../../services/General/GenericService";
export default {
  name: "",
  components: {},
  props: {
    returnObject: {
      type: Boolean,
      default: false,
    },
     clearable: {
      type: Boolean,
      default: false,
    },
     cceID: {
      type: Number,
      default: 0,
    },
     noDefault: {
      type: Boolean,
      default: false,
    },
    value: null,
    text: { type: String, default: "" },
    label: {
      type: String,
      default: "Proyecto",
    },
    centercost: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    text(){},
    value(value) {
      this.item = value;
    },
    cceID(){
        this.config.params.CceID=this.cceID
        
    }
  },
  methods: {
    input(val) {
      this.$emit("input", val);
    },
  
    },
    data: () => ({
        item: 0,
        val:'',
        items: [],
        config: {},
    }),
  
    created() {
      
        this.config = {
            url:_sGenericService.getUrlProjects(),
            title: this.label,
            params: { requestID:this.$fun.getUserID() }
        }


    }

  }

</script>
