<template>
    <div style="padding-top:-2px;margin-top:-2px;">
      <v-label ><b>{{label}}</b></v-label>
      <s-select-generic style=""
        
        :config="config"
        :disabled="disabled"
        :readonly="readonly" 
        :itemvalue="id"
        :clearable=clearable
        @add="addEvent()"
        :id="id"
        :value="value"
        @input="input($event)"
        :returnObject="returnObject"
      /></div>
  </template>
  
  <script>
  import _sGenericService from "./../../services/General/GenericService";
  
  export default {
    components: {  },
    props: {
      id: {
        type: String,
        default: "GenID",
      },
      value: null,
      label: {
        type: String,
        default: "",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      noDefault: {
        type: Boolean,
        default: false,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      returnObject: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      valueRadio: null,
      selected: [],
  
      dialogAdd: false,
      item: 0,
      items: [],
      config: {
        url: _sGenericService.getUrlSearchCampaignSe(),
        title: "",
        params: {
          defID: 0,
          dgrID: 0,
          requestID: 0,
        },
      },
    }),
  
    watch: {
  
    },
    methods: {
      input(item) {
        this.$emit("input", item);
      },
    },
    created() {
      this.config.title = this.label;
      this.config.params.requestID = this.$fun.getUserID();
  
     
    },
  };
  </script>
  