var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"tile":"","elevation":"2"}},[_c('v-card',{attrs:{"tile":"","elevation":"0"}},[_c('v-row',[_c('v-col',{staticStyle:{"padding":"0px"},attrs:{"lg":"12","md":"12","xs":"12","xl":"12","sm":"12"}},[_c('v-card-title',{staticStyle:{"padding-top":"0px","margin-top":"0px","padding-bottom":"2px"},attrs:{"dense":""}},[_c('v-text-field',{ref:"buscar",attrs:{"append-icon":"mdi-magnify","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"height":_vm.heightTable,"headers":_vm.config.headers,"selectable-key":"isSelectable","items":_vm.desserts,"item-key":_vm.propID,"options":_vm.options,"dense":"","locale":"es-ES","footer-props":{showCurrentPage:true

   // , pageText:''
    ,showFirstLastPage:true,
    itemsPerPageAllText:'Todos', itemsPerPageOptions:[5,10,15,-1],itemsPerPageText:'Registros',

  },"server-items-length":_vm.total,"loading":_vm.loading,"loading-text":"Cargando","no-data-text":""},on:{"click:row":_vm.rowClick,"update:options":function($event){_vm.options=$event}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }