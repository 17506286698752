<template>
  <div style="padding-top:-2px;margin-top:-2px;">
    <v-label>
      <b>{{ label }}</b>
    </v-label>

    <section>
      <v-text-field 
        outlined
        dense
        prepend-inner-icon="mdi-camera"
        @click="openModelCamera()">
      </v-text-field>
    </section>

    

    <v-dialog 
      v-model="viewCamera"
      width="600"
    >
      <v-card height="300">
        <v-row>
                 <video id="video" ></video>

            <canvas id="canvas"></canvas>
        </v-row>
        <v-row>
          <v-col>
            <v-btn>PRESIONA</v-btn>
          </v-col>
        </v-row>
        
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
  component:{},
  props: {
    label: { type: String, default: ""}, 
    value: { type: Array, default: []},
    config: { type: Object, default: null}, 
  },
  
  data()  {
    return {
      viewCamera : false, 
      codVideo : null,

    }
  },
  mounted() {
  
    var $canvas = document.getElementById("canvas");  
  },

  methods: {


    openModelCamera(){

      this.viewCamera = true;

    if(this.viewCamera){
      var miVideo = document.getElementById("video")
      console.log('verificamos', miVideo)


       if(this.$fun.tieneSoporteUserMedia()){
       this.$fun._getUserMedia({video: true}, 
       function(MediaStream){
         try {

          miVideo.srcObject = MediaStream;          
             
          miVideo.play();

         } catch(error) {

          console.log('verificamos que tenemos en el stream', error);
          miVideo.src = window.URL.createObjectURL(MediaStream);          
             
          miVideo.play();



         }
         console.log('Permiso concedido');  
       }, 
       function(error){
         console.log("Permiso denegado o error: ", error);
       })
     }

    }


      
    }


    
  },

  created(){


  }
}
</script>