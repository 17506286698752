<template>
	<v-container>
		
		<s-toolbar
			label="Requerimientos de compra"
			close
			@close="closeDialogRequirementByUser()"
		></s-toolbar>
		<v-data-table
			v-model="selected"
			:headers="headers"
			:items="items"
			@click:row="rowClick($event)"
			
			
			class="elevation-1"
			
			item-key="DerID"
			search-input
			:search="search"
		>
			<!-- :single-select="singleSelect" 
			show-select
			:expanded.sync="expanded"-->
			<template v-slot:top>
				<v-text-field
					v-model="search"
					label="Search"
					class="mx-4"
				></v-text-field>
			</template>

			<template v-slot:item.liberate="{ item }">
				<v-btn
					v-if="item.TypeInput == 1 && item.UsrID == UserLoginID"
					fab
					color="primary"
					x-small
					@click="liberateArticle(item)"
				>
					<i class="fas fa-box-open"></i>
				</v-btn>
			</template>
		</v-data-table>

		<v-dialog
			v-model="dialogLiberate"
			width="500"
		>
			<v-card>
				<v-container>
					<v-row>
						<v-col>
							<s-toolbar
								label="Liberar Artículo"
								save
								@save="saveLiberate()"
							></s-toolbar>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<s-text
								label="Cantidad"
								v-model="newQuantity"
							></s-text>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
	import _sRequirement from "../../../services/Logistics/RequirementService";
	import _sRequirementDetail from "../../../services/Logistics/RequirementDetailService";

	export default {
		data() {
			return {
				UserLoginID : 0,
				search : "",
				items: [],
				headers: [
					{text: "ID", value: "DerID"},
					{text: "Req.", value: "ReqDocumentNumber"},
					{text: 'Cod. Art', value: 'ArtCode' },
					{text: 'Art.', value: 'DerDescription' },
					{text: 'Cantidad', value: 'DerQuantity' },
					/* {text: "Liberar", value: "liberate"}, */
				],
				dialogDetail: false,
				expanded: [],
				singleSelect: true,
				selected: [],
				dialogLiberate: false,
				newQuantity: 0,
				articleReleased: {}
			}
		},

		watch: {
			selected() {
				if(this.selected.length > 0)
				{
					this.$emit("selectOrderPurchase", this.selected[0]);
				}
			}
		},

		methods: {
			closeDialogRequirementByUser()
			{
				this.$emit("closeDialogRequirementByUser", this.items);
			},

			getRequirementPurchaseByUser() {
				let obj = {
					UsrID : this.$fun.getUserID()
				};

				_sRequirement
				.requirementpurchasebyuser(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.items = resp.data;
						
					}
				})
			},

			rowClick(item)
			{
				
				
			},

			liberateArticle(item)
			{
				this.dialogLiberate = true;
				this.articleReleased.ReqID = item.ReqID;
				this.articleReleased.DerID = item.DerID;
				this.articleReleased.RdrQuantity = this.newQuantity;
				this.articleReleased.UsrCreateID = this.$fun.getUserID();
				this.articleReleased.UsrUpdateID = this.$fun.getUserID();
			},

			saveLiberate()
			{
				this.articleReleased.RdrQuantity = this.newQuantity;
				this.$fun.alert("¿Seguro de liberar?", "question")
				.then(r => {
					if(r.value)
					{
						_sRequirementDetail
						.savereleased(this.articleReleased, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Articulo liberado correctamente", "success");
								this.getRequirementPurchaseByUser();
								return;
							}
						})
					}
				})
				//this.newQuantity = 
			}
		},

		created () {
			this.getRequirementPurchaseByUser();
			this.UserLoginID = this.$fun.getUserID();
		},
	}
</script>

<style lang="scss" scoped>
	$data-table-regular-header-height: 300px;
</style>