import Service from "../Service";

const resource = "orderpurchase/";

export default {
    setStatus(ord, requestID) {
        return Service.post(resource + "setStatus", ord, {
            params: { requestID: requestID },
        });
    },
    save(ord, requestID, AccID) {
        return Service.post(resource + "save", ord, {
            params: { requestID: requestID, AccID: AccID },
        });
    },
    excel(dtr, requestID) {
        return Service.post(resource + "excel", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },
    PDF(dtr, requestID) {
        return Service.post(resource + "pdf", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    byID(parameters, requestID) {
        return Service.post(resource + "byid", parameters, {
            params: { requestID: requestID },
        });
    },
    slopes(parameters, requestID) {
        return Service.post(resource + "slopes", parameters, {
            params: { requestID: requestID },
        });
    },
    loadExternal(parameters, UssUserID, requestID) {
        return Service.post(resource + "loadsapoutbound", parameters, {
            params: { UssUserID: UssUserID, requestID: requestID },
        });
    },
    getSupplierSearch() {
        return Vue.prototype.$http.defaults.baseURL + "/orderpurchase/ordersearch";
    },
};