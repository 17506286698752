<template
  ><div style="padding-top:-2px;margin-top:-2px;">
      <v-label ><b>{{label}}</b></v-label>
    <s-select-generic  class="text"
      itemtext="GenDescription"
      itemvalue="GenID"
      id="GenDescription"
      :value="value" :return-object=returnObject
      @input="input($event)" 
      :config="config"
      :full=true
      :text="text" :clearable=clearable :no-default=noDefault
    >
    </s-select-generic>
  </div>
</template>

<script>
import _sGenericService from "./../../services/General/GenericService";
export default {
  name: "SelectArea",
  components: {},
  props: {
     clearable: {
      type: Boolean,
      default: false,
    },
    value: null,
    text: { type: String, default: "Ubicación" },
    label: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    noDefault: {
      type: Boolean,
      default: false,
    },
  
  },
  watch: {
    value(value) {
      this.item = value;
    },
  },
  methods: {
    input(val) {
      this.$emit("input", val);
    },
  
    },
    data: () => ({
        item: 0,
        val:'',
        items: [],
        config: {},
    }),
  
    created() {
        this.config = {
            url:_sGenericService.getUrlCompanySituation(),
            title: this.label,
            params: {requestID:this.$fun.getUserID() }
        }

    }

  }

</script>
