<template>

	<!-- Active Users Card -->
	<v-card :bordered="false" class="dashboard-bar-chart">
		<chart-bar :height="210" :data="barChartData"></chart-bar>
	</v-card>
	<!-- Active Users Card -->

</template>

<script>

	// Bar chart for "Active Users" card.
	import ChartBar from '../Charts/ChartBar' ;

	export default ({
		components: {
			ChartBar,
		},
        props: {
            objLineX: {type: Array, default: []},
            objLineY: {type: Array, default: []},
			objLineY2: {type: Array, default: []},
			text: {type: String, default: "STOCK KG BRUTO: "},
			text2: {type: String, default: "STOCK KG NETO: "}
        },
		data() {
			return {
				// Data for bar chart.
				barChartData: {
					labels: this.objLineX, //["CALIFORNIANO", "CAMARA", "MADURACIÓN", "TÚNEL"],
					datasets: [{
						label: this.text,
						backgroundColor: '#1890FF',
						borderColor: "#1890FF",
						borderWidth: 0,
						borderSkipped: false,
						borderRadius: 10,
						data: this.objLineY,//[1000, 600, 500, 620, 900, 500, 900, 630, 900],
						maxBarThickness: 80,
					},{
						label: this.text2,
						backgroundColor: '#1890FF',
						borderColor: "#1890FF",
						borderWidth: 0,
						borderSkipped: false,
						borderRadius: 10,
						data: this.objLineY2,//[1000, 600, 500, 620, 900, 500, 900, 630, 900],
						maxBarThickness: 80,
					} ],
				},

				
			}
		},
        created() {
			
            	
        }
	})

</script>