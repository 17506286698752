let children = [];

//General
children.push({
    path: "/general/Sistemas",
    name: "GEN_SYSTEM",
    component: () =>
        import ("../views/General/System.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Sistemas", href: "/general/Sistemas" },
        ],
    },
});

children.push({
    path: "/general/Area",
    name: "GEN_AREA",
    component: () =>
        import ("../views/General/Area.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Áreas", href: "/general/Area" },
        ],
    },
});

children.push({
    path: "/general/campana",
    name: "GEN_CAMPAIGN",
    component: () =>
        import ("../views/General/Campaign.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Campañas", href: "/general/campana" },
        ],
    },
});
children.push({
    path: "/general/definicion",
    name: "GEN_DEFINITION",
    component: () =>
        import ("../views/General/Definition.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Definición", href: "/general/definicion" },
        ],
    },
});
children.push({
    path: "/general/persona",
    name: "GEN_PERSON",
    component: () =>
        import ("../views/General/Person.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Persona", href: "/general/persona" },
        ],
    },
});

children.push({
    path: "/general/grupodefinicion",
    name: "GEN_DEFINITIONGROUP",
    component: () =>
        import ("../views/General/DefinitionGroup.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Grupo de Definición", href: "/general/grupodefinicion" },
        ],
    },
});

children.push({
    path: "/general/numeracion",
    name: "GEN_NUMERATION",
    component: () =>
        import ("../views/General/Numeration.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Numeración de Tabla", href: "/general/numeracion" },
        ],
    },
});

children.push({
    path: "/general/numeraciondocumento",
    name: "GEN_NUMERATIONDOCUMENT",
    component: () =>
        import ("../views/General/NumerationDocument.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            {
                text: "Numeración de Documentos",
                href: "/general/numeraciondocumento",
            },
        ],
    },
});

children.push({
    path: "/general/parametro",
    name: "GEN_PARAMETER",
    component: () =>
        import ("../views/General/Parameter.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Parametros", href: "/general/parametro" },
        ],
    },
});

children.push({
    path: "/general/temas",
    name: "MNG_INSTRUCTIONSUBJECT",
    component: () =>
        import ("../views/Management/InstructionSubject.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Temas", href: "/general/temas" },
        ],
    },
});
children.push({
    path: "/general/parametrosconfiguracion",
    name: "GEN_PARAMETER_CONFIGURATION",
    component: () =>
        import ("../views/General/Propertys.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Parametros Configuracion", href: "/general/parametrosconfiguracion" },
        ],
    },
});

children.push({
    path: "/general/logerrores",
    name: "GEN_LOG_ERRORS",
    component: () =>
        import ("../views/General/LogErrors/Log.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Log de Errores", href: "/general/logerrores" },
        ],
    },
});

children.push({
    path: "/produccionfrescos/whatsapp",
    name: "GEN_WHATSAPP",
    component: () =>
        import ("../views/FreshProduction/WhatsApp/whatsapp.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Frescos", disabled: true },
            { text: "WhatsApp", href: "/produccionfrescos/whatsapp" }
        ],
    },
});

children.push({
    path: "/general/SunarpSearch",
    name: "GEN_SEARCHSUNARP",
    component: () =>
        import ("../views/General/Sunarp/SunarpSearch.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Consulta Sunarp", href: "/general/SunarpSearch" }
        ],
    },
});


children.push({
    path: "/general/WorkerReport",
    name: "GEN_REPORT_WORKER",
    component: () =>
        import ("../views/General/ReportWorker/ReportWorker.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Reporte Trabajador", href: "/general/WorkerReport" }
        ],
    },
});

children.push({
    path: "/general/campanaporarea",
    name: "GEN_CAMPAIGN_AREA",
    component: () =>
        import ("../views/General/CampaignArea/CampaignArea.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Campaña por área", href: "/general/campanaporarea" }
        ],
    },
});

export { children };