<template>
  <div>
    <s-crud
      v-if="show == true"
      @doubleClick="doubleClick($event)"
      noBorder
      noDark
      searchInput
      title="Personas "
      :filter="filterGrid"
      :config="this.config"
    >
      <template v-slot:TypeSex="{ row }">
        {{ row.TypeSex == 1 ? "Masculino" : "Femenino" }}
      </template>
    </s-crud>
  </div>
</template>

<script>
import ServicePrs from "@/services/Technicalassistance/DriverEngagementService";
export default {
  name: "sSearchPerson",
  components: {},
  props: {
    typeperson: {
      type: Number,
      default: 0,
    },
    addTypeSex: {
      type: Boolean,
      default: false,
    },
    addBirthDate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    show: false,
    filterGrid: { TypePerson: 1, SecStatus: 1 },
    config: {
      model: {
        PrsID: "ID",
        PrsDocumentNumber: "string",
        PrsFullName: "string",
        TypePersonDocument: "int",
        SecStatus: "",
        NtpBirthDate: "date",
        TypeSex: "",
      },
      service: ServicePrs,
      headers: [
        { text: "ID", value: "PrsID" },
        { text: "N°Documento", value: "PrsDocumentNumber" },
        { text: "Nombre", value: "PrsFullName" },
      ],
    },
  }),
  methods: {
    doubleClick(item) {
      this.$emit("returnPerson", item);
    },
  },
  created() {
    this.config.typeperson = this.typeperson;
    if (this.addTypeSex) this.config.headers.push({ text: "Sexo", value: "TypeSex" });
    if (this.addBirthDate)
      this.config.headers.push({
        text: "Fecha de Nacimiento",
        value: "NtpBirthDate",
      });

    this.show = true;
  },
};
</script>
