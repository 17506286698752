let children = []

children.push({
    path: "/querymanager/configuration",
    name: "QRY_CONFIGURATION",
    component: () =>
        import ("../views/QueryManager/QryConfiguration.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Productividad", disabled: true },
            { text: "Configuración", href: "/querymanager/configuration" },
        ],
    },
});


children.push({
    path: "/querymanager/querys",
    name: "QRY_QUERY_SHOW",
    component: () =>
        import ("../views/QueryManager/QueryShow/QueryShow.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Productividad", disabled: true },
            { text: "Querys", href: "/querymanager/querys" },
        ],
    },
});

children.push({
    path: "/querymanager/transactsql",
    name: "QRY_TRANSACTSQL",
    component: () =>
        import ("../views/QueryManager/QueryShow/TransactSql.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Productividad", disabled: true },
            { text: "Transact SQL", href: "/querymanager/transactsql" },
        ],
    },
});


children.push({
    path: "/querymanager/permisosusuario",
    name: "QRY_CONFIGURATION_PERMISSION_USER",
    component: () =>
        import ("../views/QueryManager/PermissionUser/PermissionUser.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Query Manager", disabled: true },
            { text: "Transact SQL", href: "/querymanager/permisosusuario" },
        ],
    },
});


export { children }