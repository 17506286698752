<template>
  <div style="padding-top: -2px; margin-top: -2px">
    <v-label
      ><b>{{ label }}</b></v-label
    >
    <s-select-generic
      autocomplete
      :autofocus="autofocus"
      class="text"
      :itemvalue="id"
      :disabled="disabled"
      :value="value"
      id="WkrID"
      itemtext="WkrName"
      :returnObject="returnObject"
      @input="input($event)"
      :config="config"
      @add="addEvent()"
      :full="full"
      :text="text"
      :clearable="clearable"
      :noDefault="noDefault"
      @onClearClicked="onClearClicked()"
    />
  </div>
</template>
<script>
import _sWorkerService from "../../../services/HumanResource/WorkerService";
export default {
  name: "sSelectWorker",
  components: {},
  props: {
    label: {
      type: String,
      default: "",
    },

    typepayrollallow: {
      type: Number,
      default: 0,
    },
    id: {
      type: String,
      default: "WkrID",
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    value: null,
    text: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    noDefault: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    input(v) {
      this.$emit("input", v);
    },
    onClearClicked(){
      this.$emit("onClearClicked");     
    },
  },
  watch: {
    typepayrollallow() {
      this.config = {
        url: _sWorkerService.searchworker(), //"http://localhost:63130/api/worker/workerce",
        params: {
          //search: "",
          typepayroll: this.typepayrollallow,
        },
        title: this.label,
      };
    },
  },
  data: () => ({
    item: 0,
    items: [],
    config: {},
    type: 0,
  }),
  created() {
    this.type = this.typepayrollallow;
    this.config = {
      url: _sWorkerService.searchworker(),
      params: { typepayroll: this.type  ,search: "",},
    };
  },
};
</script>
