let children = []

children.push({
    path: "/chat/chat",
    name: "CHT_CHAT",
    component: () =>
        import ("../views/Chat/ChatVue.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Chat", disabled: true },
            { text: "Chat", href: "/chat/chat" },
        ],
    },
});

export { children }