import axios from "axios";
// const baseURL = process.env.VUE_APP_URL_BASE;//dev
// console.log('baseURL', process.env)
// const baseURL = "http://localhost:5005/api";//dev
// const baseURL = "http://172.16.1.9:8184/api" //para produccion;
const baseURL = "https://190.116.178.179:8189/api";
// const baseURL = "https://181.176.215.139:8189/api; //BITEL"
// const baseURL = "http://172.16.1.9:8181/api"; // Este es para pruebas demo cambiado
export default axios.create({
    baseURL,
});