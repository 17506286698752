<template
  >
  <div style="padding-top: -2px; margin-top: -2px">
    <s-select-generic
      ref=""
      autocomplete
      class="text"
      itemtext="cFundName"
      itemvalue="cFundID"
      :disabled="disabled"
      id="cFundName"
      :value="value"
      :return-object="returnObject"
      @input="input($event)"
      :config="config"
      :label="label"
      :full="full"
      :autofocus="autofocus"
      :text="text"
      :clearable="clearable"
      :noDefault="noDefault"
    >
    </s-select-generic>
  </div>
</template>

<script>
import { now } from 'moment';
import _sReceptionService from "../../../services/Technicalassistance/ReceptionSurveillanceService";
export default {
  //name: "SelectArea",
  components: {},
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    value: null,
    text: { type: String, default: "" },
    label: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },

    noDefault: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    originID: {
      type: Number,
      default: "",
    },

    dateInitial: {
      type: String,
      default: "",
    },

    dateFinal: {
      type: String,
      default: "",
    },
  },
  watch: {
    originID() {
      this.config.params.xOriginID = this.originID;
    },

    dateInitial() {
      this.config.params.xDateInitial = this.dateInitial;
    },

    dateFinal() {
      this.config.params.xDateFinal = this.dateFinal;
    },

    value(value) {
      this.item = value;
    },
  },
  methods: {
    //focus(){this.$refs.sarea.focus();},
    input(val) {
      this.$emit("input", val ? val : "");
    },
  },
  data: () => ({
    item: 0,
    val: "",
    items: [],
    config: {},
  }),

  created() {
    this.config = {
      url: _sReceptionService.SearchFundMaquilador(),
      title: this.label,
      params: {
        xOriginID: this.originID,
        xDateInitial: this.dateInitial,
        xDateFinal: this.dateFinal,
        search: "",
        requestID: this.$fun.getUserID(),
      },
    };
    /*           if(this.centercost) 
          {this.config.url=_sGenericService.getUrlSearchCenterCost();
          this.config.params={search:'',TypeCost:this.typeCost,requestID:this.$fun.getUserID() };
          } */
  },
};
</script>
