<template>
<div>
    <s-toolbar label="Proovedor" save @save=save() @close="$emit('close')" close></s-toolbar>
    <v-card>
        <v-container>
            <v-row>
             <v-col lg=12 ><s-text autofocus label="Proveedor" v-model=SupName></s-text></v-col>
            </v-row>
        </v-container>
    </v-card>
</div>
</template>
<script>
import _sSupllier from "@/services/Logistics/SupplierService";
export default {
    components: {},
    props: {
        items: []
    },
    data: () => ({
        SupName:'',
        SupID:null,
    }),
    methods: {
       save(){
           if(this.validation())
            this.$fun.alert('Seguro de Guardar?','question').then((r)=>{
                if(r.value){
                    _sSupllier.save({SupID:this.SupID,SupName:this.SupName,SupStatus:1,SecStatus:1,
                    UsrCreateID:this.$fun.getUserID()},this.$fun.getUserID()).then((r)=>{
                        this.$fun.alert('Agregado Correctamente','success');
                            this.$emit('load',{SupID:this.SupID,SupName:this.SupName});
                            this.$emit('close');
                    })
                }
            });
       },
       validation(){
           if(this.SupName.trim().lenght<1){this.$fun.alert('Complete Proveedor','warning'); return false}
           return true;
       }
    },
    watch: {
     
    }
};
</script>
