<template>
    <v-card  :bordered="false" class="dashboard-bar-chart">
        <chart-pies :height="210" :data="barChartData"></chart-pies>
    </v-card>
</template>
<script>
 import ChartPies from '../Charts/ChartPie' ;

export default({
    components:{
        ChartPies,
    },
    props:{
        objLineX: {type: Array, default: []},
        objLineY: {type: Array, default: []},
        text: {type: String, default: "STOCK KG BRUTO: "},
    },
    data(){
        return{            
            barChartData:{
                labels: this.objLineX, //["CALIFORNIANO", "CAMARA", "MADURACIÓN", "TÚNEL"],
				datasets: [{
                    label: this.text,
                    backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
                    data:this.objLineY
                }],
            },            
        }
    },
    created() {
			
            	
        }
})
</script>