let children = []

children.push({
    path: "/venta/ProgramacionSemanal",
    name: "SLE_SPW",

    component: () =>
        import ("../views/Sale/ProjectWeek/ProjectWeekVG.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Progtramación", disabled: true },
            { text: "Programación Semanal", href: "/venta/ProgramacionSemanal" },
        ],
    },
});
///
///
children.push({
    path: "/venta/CriteriosCobranza",
    name: "SLE_CRITERIACOLLECTION",

    component: () =>
        import ("../views/Sale/CriteriaCollection/CriteriaCollection.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranza", disabled: true },
            { text: "Criterios de Cobranza", href: "/venta/CriteriosCobranza" },
        ],
    },
});


children.push({
    path: "/venta/configuracioncosto",
    name: "SLE_CONFIGURATIONCOST",

    component: () =>
        import ("../views/Sale/ConfigurationCost/ConfigurationCost.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Costo", disabled: true },
            { text: "Configuración de Costo", href: "/venta/configuracioncosto" },
        ],
    },
});
children.push({
    path: "/venta/CriteriosAdelanto",
    name: "SLE_CRITERIAADELANTO",

    component: () =>
        import ("../views/Sale/CriteriaAdvance/CriteriaAdvanceVG.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Criterios Adelanto", disabled: true },
            { text: "Criterios Adelanto", href: "/venta/CriteriosAdelanto" },
        ],
    },
});

children.push({
    path: "/venta/Cobranzas",
    name: "SLE_BILLING",

    component: () =>
        import ("../views/Sale/Billing/Billing.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Ventas", disabled: true },
            { text: "Cobranzas", href: "/venta/Cobranzas" },
        ],
    },
});


children.push({
    path: "/venta/Empaque Barco",
    name: "SLE_PACKING_BOAT",

    component: () =>
        import ("../views/Sale/PackingBoat/PackingBoat.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Ventas", disabled: true },
            { text: "Empaque Barco", href: "/venta/Empaque Barco" },
        ],
    },
});

children.push({
    path: "/venta/programaventa",
    name: "SLE_SALE_PROGRAM",

    component: () =>
        import ("../views/Sale/SaleProgram/SaleProgramG.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Ventas", disabled: true },
            { text: "Programa Venta", href: "/venta/programaventa" },
        ],
    },
});

children.push({
    path: "/venta/pagosprecampana",
    name: "SLE_PRECAMPAIGN_PAYMENT",

    component: () =>
        import ("../views/Sale/PreCampaignPayment/PreCampaignPayment.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Ventas", disabled: true },
            { text: "Pago PreCampaña", href: "/venta/pagosprecampana" },
        ],
    },
});

children.push({
    path: "/venta/compromisoconductor",
    name: "SLE_DRIVER_COMMITMENT",

    component: () =>
        import ("../views/Sale/DriverCommitment/DriverCommitment.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Ventas", disabled: true },
            { text: "Compromiso Conductor", href: "/venta/compromisoconductor" },
        ],
    },
});


children.push({
    path: "/contabilidad/sire",
    name: "ACC_SIRE",

    component: () =>
        import ("../views/Sale/Accounting/sire.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "contabilidad", disabled: true },
            { text: "SIRE", href: "/venta/sire" },
        ],
    },
});





export { children }