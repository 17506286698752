let children = [];

//------------ ADMINISTRACION -------------------------
children.push({
  path: "/administracion/evento",
  name: "MNG_INDUCTION",
  component: () => import("../views/Management/Instruction.vue"),
  meta: {
    authenticated: true,
    breadcrumb: [
      { text: "Inicio", href: "/#/" },
      { text: "Administración", disabled: true },
      { text: "Eventos de Personal", href: "/administracion/evento" },
    ],
  },
});
children.push({
  path: "/administracion/historialcontrolacceso",
  name: "MNG_ACCESSCONTROL_REPORT",
  component: () => import("../views/Management/AccessControlReport.vue"),
  meta: {
    authenticated: true,
    breadcrumb: [
      { text: "Inicio", href: "/#/" },
      { text: "Administración", disabled: true },
      {
        text: "Historial de Control de Acceso",
        href: "/administracion/historialcontrolacceso",
      },
    ],
  },
});
children.push({
  path: "/administracion/combustible",
  name: "MNG_FUELVOUCHER",
  component: () => import("../views/Management/FuelVoucher/FuelVoucher.vue"),
  meta: {
    authenticated: true,
    breadcrumb: [
      { text: "Inicio", href: "/#/" },
      { text: "Administración", disabled: true },
      {
        text: "combustible",
        href: "/administracion/combustible",
      },
    ],
  },
});
children.push({
  path: "/administracion/vehiculo",
  name: "MNG_VEHICLE",
  component: () => import("../views/Management/Vehicle/Vehicle.vue"),
  meta: {
    authenticated: true,
    breadcrumb: [
      { text: "Inicio", href: "/#/" },
      { text: "Administración", disabled: true },
      {
        text: "Vehículo",
        href: "/administracion/vehiculo",
      },
    ],
  },
});
children.push({
  path: "/administracion/programacion",
  name: "MNG_PROGRAMMINGVEHICLE",
  component: () => import("../views/Management/ProgrammingVehicle/ProgrammingVehicle.vue"),
  meta: {
    authenticated: true,
    breadcrumb: [
      { text: "Inicio", href: "/#/" },
      { text: "Administración", disabled: true },
      {
        text: "Programación",
        href: "/administracion/programacion",
      },
    ],
  },
});
//Nueva Opcion
children.push({
  path: "/administracion/detracciones",
  name: "MNG_DETRACTION",
  component: () => import("../views/Management/Detraction/Detraction.vue"),
  meta: {
    authenticated: true,
    breadcrumb: [
      { text: "Inicio", href: "/#/" },
      { text: "Administración", disabled: true },
      { text: "Detracción", href: "/administracion/detraccion" },
    ],
  },
});
children.push({
  path: "/administracion/pagosrecibidos",
  name: "MNG_OUTGOING_PAYMENTS",
  component: () => import("../views/Management/Treasury/OutgoingPayments.vue"),
  meta: {
    authenticated: true,
    breadcrumb: [
      { text: "Inicio", href: "/#/" },
      { text: "Administración", disabled: true },
      { text: "Pagos Recibidos", href: "/administracion/pagosrecibidos" },
    ],
  },
});
children.push({
  path: "/administracion/pagosmasivos",
  name: "MNG_PAYMENTS",
  component: () => import("../views/Management/Detraction/Payments.vue"),
  meta: {
    authenticated: true,
    breadcrumb: [
      { text: "Inicio", href: "/#/" },
      { text: "Administración", disabled: true },
      { text: "Pagos Masivos", href: "/administracion/pagosmasivos" },
    ],
  },
});

children.push({
  path: "/administracion/parking",
  name: "MNG_PARKING",
  component: () => import("../views/Management/Parking/Parking.vue"),
  meta: {
    authenticated: true,
    breadcrumb: [
      { text: "Inicio", href: "/#/" },
      { text: "Administración", disabled: true },
      {
        text: "Vehículo",
        href: "/administracion/parking",
      },
    ],
  },
});

children.push({
  path: "/administracion/validateaccess",
  name: "MNG_VALIDATE_ACCESS",
  component: () => import("../views/Management/Parking/ValidateAccess.vue"),
  meta: {
    authenticated: true,
    breadcrumb: [
      { text: "Inicio", href: "/#/" },
      { text: "Administración", disabled: true },
      {
        text: "Vehículo",
        href: "/administracion/validateaccess",
      },
    ],
  },
});
children.push({
  path: "/administracion/validateaccesslist",
  name: "MNG_PARKING_ACCESS",
  component: () => import("../views/Management/Parking/ValidateAccessList.vue"),
  meta: {
    authenticated: true,
    breadcrumb: [
      { text: "Inicio", href: "/#/" },
      { text: "Administración", disabled: true },
      {
        text: "Vehículo",
        href: "/administracion/validateaccesslist",
      },
    ],
  },
});
export { children };
