<template>
  <div>
    <s-crud
      v-if="show == true"
      @doubleClick="doubleClick($event)"
      noBorder
      noDark
      searchInput
      title="Trabajadores "
      :filter="filterGrid"
      :config="this.config"
    >
      <template v-slot:CtrStatus="{ row }">
        {{ row.CtrStatus == 1 ? "Activo" : "Baja" }}
      </template>
      <template v-slot:filter>
        <v-col>
          <v-row justify="center">
            <v-col lg="2"
              ><s-switch label="Activos" v-model="filterGrid.CtrStatus" :disabled="!addfilter"
            /></v-col>
          </v-row>
        </v-col>
      </template>
    </s-crud>
  </div>
</template>

<script>
import _sWorkerService from "../../../services/HumanResource/WorkerService";
export default {
  name: "sSearchWorker",
  components: {},
  props: {
    typeperson: {
      type: Number,
      default: 0,
    },
    addCtrBegin: {
      type: Boolean,
      default: false,
    },
    addCtrEnd: {
      type: Boolean,
      default: false,
    },
    addfilter: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    show: false,
    filterGrid: { CtrStatus: 1, SecStatus: 1 },
    config: {
      model: {
        PrsID: "int",
        CtrID: "int",
        WkrID: "ID",
        CtrStatus: "int",
        PrsDocumentNumber: "string",
        WkrName: "string",
        TypePersonDocument: "int",
        SecStatus: "",
        NtpBirthDate: "date",
        TypeSex: "",
        CtrBeginDate: "date",
        CtrEndDate: "date",
      },
      service: _sWorkerService,
      headers: [
        { text: "ID", value: "WkrID" },
        { text: "N°Documento", value: "PrsDocumentNumber" },
        { text: "Nombre", value: "WkrName" },
        { text: "Área", value: "AreName" },
      ],
    },
  }),
  methods: {
    doubleClick(item) {
      this.$emit("returnPerson", item);
    },
  },
  created() {
    this.config.typeperson = this.typeperson;
    if (this.addCtrBegin)
      this.config.headers.push({
        text: "Contrato Inicio",
        value: "CtrBeginDate",
      });
    if (this.addCtrEnd)
      this.config.headers.push({
        text: "Contrato Fin",
        value: "CtrEndDate",
      });
       if (this.addfilter)
      this.config.headers.push({
        text: "Contrato",
        value: "CtrStatusName",
      });

    this.show = true;
  },
};
</script>
