<template
  ><div style="padding-top:-2px;margin-top:-2px;" >
    <v-label
      ><b>{{ label }}</b></v-label
    >
    <s-select-generic
      class="text"
      itemvalue="TcdID"
      :disabled="disabled"
      :value="value"
      id="TcdID" itemtext="TcdDescription"
      :return-object="returnObject"
      @input="input($event)"
      :config="config"
      :full="full"
      :text="text"
      :clearable="clearable"
      :noDefault="noDefault"
      @updateData='met($event)'
    >
    </s-select-generic>
  </div>
</template>

<script>
import _sService from "@/services/Technicalassistance/CertificationsService.js";
export default {
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    value: null,
    text: { type: String, default: "" },
    label: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    produceID:{
      type: Number,
      default: 0,
    },
    noDefault: {
      type: Boolean,
      default: false,
    },    
    full: {
      type: Boolean,
      default: false,
    },
    TcID: {
      type: Number,
      default: 0
    }
  },
  watch: {
    value(value) {
      this.item = value;
    },
    produceID(){
        this.config.params.SpvId=this.produceID
    },
    TcID() {
      this.hasAdendas();
      this.config.params.TcID = this.TcID;
    }
  },
  methods: {
    met(items){
     
    },
    input(val) {
      this.$emit("input", val);
    },

    hasAdendas(){
      let Search = '';
      _sService.typecertificationdetaillist(this.TcID, Search, this.$fun.getUserID()).then((r) => {
        if(r.status == 200){
          let lenghData = r.data.length;
          if(lenghData > 0){
            this.hasAdenda = true;
          }
          else{
            this.hasAdenda = false;
            this.$emit('cleanFields');
            //this.val = null;
          }
        }
      })
    }
  },
  data: () => ({
    item: 0,
    val: "",
    hasAdenda: false,
    items: [],
    config: {},
  }),

  created() {
    console.log('llamando')
    //this.TcID = 0;
    this.hasAdendas();
    this.config = {
      url: _sService.getUrlTypeCertificationDetailList(),
      title: this.label,
      params: {TcID: this.TcID, Search: '', requestID: this.$fun.getUserID()},
    };
  },
};
</script>
