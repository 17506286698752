<template>
  <div style="padding-top:-2px;margin-top:-2px;">
    <template v-if="check == false && radio == false && checkList == false">
      <v-label>
        <b>{{ label }}</b>
      </v-label>
      <s-select-generic ref='sgeneric'
        style="margin-top:0px;"
        :config="config"
        :disabled="disabled"
        :readonly="readonly"
        :itemvalue="id"
        :add="add"
        :clearable="clearable"
        :itemtext="abbr ? 'DedAbbreviation' : 'DedDescription'"
        @add="addEvent()"
        :id="id"
        :noDefault="clearable"
        :value="valueLocal"
        @input="input($event)"
        @change="change($event)"
        :returnObject="returnObject"
        :autofocus="autofocus"
        @updateData='$emit("updateData",$event)'
      />
    </template>

    <div v-if="check">
      <v-label>
        <b>{{ label }}</b>
      </v-label>
      <v-checkbox
        hide-details=""
        v-for="(item, i) in items"
        :value="item[id]"
        :disabled="disabled"
        :readonly="readonly"
        v-model="selected"
        :label="abbr ? item.DedAbbreviation : item.DedDescription"
        :key="i"
      />
    </div> 

    <v-radio-group v-if="radio" :row="row" v-model="valueRadio" :mandatory="false" hide-details :disabled="disabled" :readonly="readonly">
      <v-radio v-for="(item, i) in items" :label="item.DedDescription" :value="item[id]" :key="i"></v-radio>
    </v-radio-group>
 
    <div v-if="checkList">
      
      <v-select multiple :items="items" v-model="selected" :returnObject="returnObject" :clearable="clearable" item-value="DedValue" :label="label" item-text="DedDescription">
      
      </v-select>
    </div>
    <v-dialog light style="border-radius:0px !important;" v-model="dialogAdd" v-if="dialogAdd" width="500">
      <v-card>
        <v-toolbar dark flat dense elevation="0" color="primary">
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="dialogAdd = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-divider></v-divider>
        <v-col style="padding-top:0px;"><definition-detail 
        @refresh="refresh($event)" 
        :defID="def"></definition-detail></v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _sDefinition from "@/services/General/DefinitionService.js";
import DefinitionDetail from "@/views/General/Definition/DefinitionDetail";

export default {
  components: { DefinitionDetail },
  props: {
    id: {
      type: String,
      default: "DedValue",
    },
    abbr: {
      type: Boolean,
      default: false,
    },
    value: null,
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noDefault: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    def: {
      type: Number,
      default: 0,
    },
    add: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    check: {
      type: Boolean,
      default: false,
    },
    radio: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Boolean,
      default: false,
    },
    dgr: {
      type: Number,
      default: 0,
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    checkList: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    valueRadio: null,
    selected: [],
    valueLocal:null,
    dialogAdd: false,
    item: 0,
    items: [],
    config: {
      url: _sDefinition.definitiongroupURL(),
      title: "",
      params: {
        defID: 0,
        dgrID: 0,
        requestID: 0,
      },
    },
  }),

  watch: {
    error(msg) {
      this.$fun.alert(msg, "warning");
      this.focus();

      this.setColor("error");
    },
    
    dgr() {
      this.config.params.dgrID = this.dgr;
    },
    def() {
      this.config.params.defID = this.def;
    },
    selected(value) {
      let f = this.items.find((x) => x[this.id] == value);
      if (!this.returnObject) this.$emit("input", value);
      else this.$emit("input", f);
    },
    valueRadio(value) {
      //  let f=  this.items.find(x=>x[this.id]==value);
      // if(!this.returnObject)
      this.$emit("input", value);
      this.$emit(
        "returnObject",
        this.items.find((x) => x[this.id] == value)
      );
    },

    value(value) {
      if (this.radio) this.valueRadio = value;
      else if (this.check) this.selected = value;
     else  this.valueLocal=this.value;
    },
  },
  methods: {
    isFocused(val){this.$refs.sgeneric.isFocused(val);},
    focus(){this.$refs.sgeneric.focus();},

     refresh(item){
      this.$refs.sgeneric.load();
    },
    addEvent() {
      this.dialogAdd = true;
    },
    input(item) {
      this.$emit("input", item);
    },
     change(item) {
      this.$emit("change", item);
    },
  },
  created() { 
    
    this.config.title = this.label;
    this.config.params.defID = this.def;
    this.config.params.dgrID = this.dgr;
    this.config.params.requestID = this.$fun.getUserID();
    this.valueLocal=this.value;
    this.$http
      .post(
        _sDefinition.definitiongroupURL(),
        {},
        {
          params: this.config.params,
        }
      )
      .then((r) => {
        if (r.status == 200) {
          this.items = r.data;
          this.$emit("getDataInitial", this.items);
        }
      });
  },
};
</script>
