import Service from "../Service";
import Vue from "vue";
const resource = "Supplier/";

export default {
    save(cmp, requestID) {
        return Service.post(resource + "save", cmp, {
            params: { requestID: requestID },
        });
    },
    getContactSupplierURL() {
        return Vue.prototype.$http.defaults.baseURL + "/Supplier/SupplierContacts";
    },
    getSupplierSearch() { return Vue.prototype.$http.defaults.baseURL + "/Supplier/Suppliersearch"; },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
};