<template>
  <v-card tile elevation="2">
    <v-card tile elevation="0">
      <v-row>
        <v-col lg="12" md="12" xs="12" xl="12" sm="12" style="padding:0px;">
          <v-card-title dense style="padding-top:0px;margin-top:0px;padding-bottom:2px;">
            <v-text-field
              v-model="search"
              ref="buscar"
              append-icon="mdi-magnify"
              dense
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-data-table
        @click:row="rowClick"
        :height="heightTable"
        :headers="config.headers"
        selectable-key="isSelectable"
        v-model="selected"
        :items="desserts"
        :item-key="propID"
        :options.sync="options"
        dense
        locale="es-ES"
        :footer-props="{showCurrentPage:true

     // , pageText:''
      ,showFirstLastPage:true,
      itemsPerPageAllText:'Todos', itemsPerPageOptions:[5,10,15,-1],itemsPerPageText:'Registros',

    }"
        :server-items-length="total"
        :loading="loading"
        loading-text="Cargando"
        no-data-text
      ></v-data-table>
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: "STable",

  props: {
    config: {
      type: Object,
      default: null
    },
    filter: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: ""
    },
    heightTable: {
      type: Number,
      default: 296
    },
    parentID: {
      type: Number,
      default: 0
    },
    singleRow: {
      type: Boolean,
      default: true
    }
  },
  components: {},

  data: () => ({
    doubleClick: false,
    showForm: false,
    messageError: [],
    row: {},
    total: 0,
    loading: true,
    options: {},
    search: "",
    selected: [],
    desserts: [],
    selectedobject: {},
    parametersPagination: {
      filter: {},
      draw: 1,
      start: 0,
      length: 10,
      order: [
        {
          dir: "asc",
          column: 0
        }
      ],
      searchText: ""
    }
  }),
  watch: {
    filter() {
      this.getDataFromApi().then(data => {
        (this.desserts = data.items), (this.total = data.total);
        if (this.desserts.length > 0) this.refresh(this.desserts[0]);
      });
    },
    search() {
      this.debouncedGetAnswer();
    },
    options: {
      handler() {
        this.getDataFromApi().then(data => {
          (this.desserts = data.items), (this.total = data.total);
          if (this.desserts.length > 0) this.refresh(this.desserts[0]);
        });
      },
      deep: true
    }
  },
  methods: {
    doubleClickFunc() {
      console.log("Hacer Click");
      //if (this.edit)
      //  this.doubleClick = !this.doubleClick
    },
    refresh(obj) {
      if (obj.SecStatus == 0) {
        this.getDataFromApi().then(data => {
          this.desserts = data.items;
          this.total = data.total;
          if (this.desserts.length > 0) {
            this.selected = [this.desserts[0]];
            this.row = Object.assign({}, this.desserts[0]);
          }
        });
      } else if (this.propID != null) {
        let ob = this.desserts.find(x => obj[this.propID] == x[this.propID]);
        for (var prop in ob) {
          ob[prop] = obj[prop];
        }
        this.selected = [ob];
        this.row = Object.assign({}, ob);
      }
    },
    getAnswer() {
      this.parametersPagination.searchText = this.search;
      this.options.page = 1;
      this.getDataFromApi().then(data => {
        this.desserts = data.items;
        this.total = data.total;
        if (this.desserts.length > 0) this.refresh(this.desserts[0]);
      });
    },

    rowClick: function(item, row) {
      if (this.singleRow) {
        this.selected = [item];
        this.row = Object.assign({}, item);
      } else {
        if (
          this.selected.find(x => {
            return x == item;
          }) != null
        ) {
          this.selected = this.selected.filter(x => {
            return x != item;
          }); // row.select(false);
        } else {
          this.selected.push(item); //  row.select(true);
          this.row = Object.assign({}, item);
        }
      }
      //le paso el valor selecciondo
      if (this.selected != null) {
        this.$emit("rowSelected", this.selected);
      }
    },
    restartEntity(entity) {
      this.row = entity;
    },

    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        this.parametersPagination.start =
          (this.options.page - 1) * this.parametersPagination.length;
        this.parametersPagination.filter = this.filter;

        this.parametersPagination.length = itemsPerPage;
        this.config.service
          .pagination(this.parametersPagination, this.config.typeperson)
          .then(response => {
            this.restart();
            this.selected = [];
            let items = response.data.data;
            items.forEach(element => {
              for (var prop in this.config.model)
                if (this.config.model[prop] == "date")
                  element[prop] = this.$moment(element[prop]).format(
                    "YYYY-MM-DD"
                  );
                else if (this.config.model[prop] == "time")
                  element[prop] = this.$moment(element[prop]).format("HH:SS");
            });
            const total = response.data.recordsTotal;
            setTimeout(() => {
              this.loading = false;
              resolve({
                items,
                total
              });
            }, 0);
          });
      });
    },
    restart() {
      for (var prop in this.config.model) {
        if (this.config.model[prop] == "date")
          this.row[prop] = this.$moment().format("YYYY-MM-DD");
        else if (this.config.model[prop] == "string") this.row[prop] = "";
        else if (this.config.model[prop] == "number") this.row[prop] = 0;
        else if (this.config.model[prop] == "ID") this.row[prop] = 0;
        else if (this.config.model[prop] == "time") this.row[prop] = "00:00";
        else if (this.config.model[prop] == "booleam") this.row[prop] = 0;
        else this.row[prop] = "";
      }
    }
  },
  computed: {
    propStatus() {
      for (var prop in this.config.model) {
        if (this.config.model[prop] == "status") return prop;
      }
      return null;
    },
    propID() {
      for (var prop in this.config.model) {
        if (this.config.model[prop] == "ID") return prop;
      }
      return null;
    }
  },
  mounted() {
    this.parametersPagination.filter[this.propID] = this.parentID;
  },
  created() {
    for (var prop in this.config.model) {
      if (this.config.model[prop] == "date")
        this.row[prop] = this.$moment().format("YYYY-MM-DD");
      else if (this.config.model[prop] == "string") this.row[prop] = "";
      else if (this.config.model[prop] == "number") this.row[prop] = 0;
      else if (this.config.model[prop] == "ID") this.row[prop] = 0;
      else if (this.config.model[prop] == "time") this.row[prop] = "00:00";
      else if (this.config.model[prop] == "booleam") this.row[prop] = 0;
      else this.row[prop] = "";
    }
    this.row.SecStatus = 0;

    this.debouncedGetAnswer = _.debounce(this.getAnswer, 500);
    //  this.item=Object.assign({},this.config.model)
  }
};
</script>

<style scoped lang="scss">
</style>
