<template>
<div>
    <s-text :readonly=readonly  label="Proveedor" @click="dialogArticle=true" v-model="textDescription" />

    <v-dialog width="80%" v-model="dialogArticle" v-if=dialogArticle  transition="dialog-bottom-transition">

       

              <v-supplier  style="overflow: hidden !important; " @dblClick="dblClick($event)" ></v-supplier>

 

    </v-dialog>

</div>
</template>

<script>
import VSupplier from '../../../views/Logistics/VSupplier.vue'
export default {
    components:{VSupplier},
    props: {
            value:null,
            readonly:{type:Boolean,default:false}
    },
    data() {
        return {
            article:null,
            textDescription: '',
            dialogArticle: false,
        };
    },
    methods: {
        dblClick(item) {
            this.article=item;
            this.$emit('input', item);
            this.textDescription = item.SupName;
            this.dialogArticle = false;
        }
    },
    created() {
this.article=this.value;
if(this.article!=null){
    this.textDescription=this.article.SupName;
}
    },
    created() {},
    watch: {
value(){
    this.textDescription=this.value.SupName;

}
    },
};
</script>
