import Service from "../Service";

const resource = "usersap/";

export default {
    list(requestID) {
        return Service.post(
            resource + "list", {}, {
                params: { requestID: requestID },
            }
        );
    },
    listLocked(requestID) {
        return Service.post(
            resource + "listLocked", {}, {
                params: { requestID: requestID },
            }
        );
    },
    enabled(uss, requestID) {
        return Service.post(resource + "enabled", uss, {
            params: { requestID: requestID },
        });
    },
    mustChangePassword(uss, requestID) {
        return Service.post(resource + "mustchangepassword", uss, {
            params: { requestID: requestID },
        });
    },
    changePassword(uss, requestID) {
        return Service.post(resource + "changePassword", uss, {
            params: { requestID: requestID },
        });
    },
    save(uss, requestID) {
        return Service.post(resource + "save", uss, {
            params: { requestID: requestID },
        });
    },
};