<template>
  <div>
    <div>
      <v-dialog v-if="dialogLoad" v-model="dialogLoad" persistent transition="dialog-bottom-transition">
        <order-edit
          @onClose="
            dialogLoad = false;
            $refs.crud.refresh();
          "
          :typeOrder="1"
          :order="order"
        ></order-edit>
      </v-dialog>
    </div>

    <s-crud
      ref="crud" deleteNoFunction @delete=deleted($event)
      :single-row="false"
      title="Atencion de Compras"
      excel @excel='excel($event)'
      add-no-function
      searchInput
      remove height="410"
      :config="config"
      :filter="filter" add @add=' previewSap(true)' 
    
      @rowSelected="rowSelected($event)"
    >
      <template v-slot:filter>
        <v-container>
          <v-col style="">
            <v-row justify="center">
              <v-col lg="3" class="s-col-form">
                <s-select-area label="Filtro por Área" v-model="filter.AreID"> </s-select-area>
              </v-col>
              <v-col lg="2" class="s-col-text">
                <s-articles-group clearable no-default :def="1129" label="Grupo de Articulos" v-model="filter.ItmsGrpCod"></s-articles-group>
              </v-col>
             <!-- <v-col lg="2" class="s-col-form">
                <s-select-definition
                  clearable
                  label="Estados"
                  :def="1128" :dgr="30"
                ></s-select-definition>
              </v-col>-->

              <v-col lg="1" class="s-col-form">
                <s-switch class="mt-2" v-model="filter.Everybody" label="Todos" @input="change($event)"></s-switch>
              </v-col>
              <v-col lg="2" class="s-col-form">
                <s-date label="Fecha Inicio" v-model="filter.BeginDate" :nullable="clearDate" :disabled="clearDate"></s-date>
              </v-col>
              <v-col lg="2" class="s-col-form">
                <s-date label="Fecha Fin" v-model="filter.EndDate" :nullable="clearDate" :disabled="clearDate"></s-date>
              </v-col>
            </v-row>
          </v-col>
        </v-container>
      </template>
      <template v-slot:options>
        <v-tooltip bottom="">
          <template v-slot:activator="{ on }">
            <v-btn small text v-on="on" elevation="0" @click="printQoute(true)">
              <v-icon style="font-size:16px;">fas fa-book-reader</v-icon>
            </v-btn>
          </template>
          <span>Generar Cotización</span>
        </v-tooltip>
      </template>
      <template v-slot:DerStatus="{row}">
        <v-chip x-small :color="row.DerStatus==7 ?'error':row.DerStatus==6?'primary':''">
          {{row.DerStatus==7 ?'Artículo de Baja':row.DerStatus==6?'Cotizado':'Autorizado' }}
        </v-chip>
      </template>
    </s-crud>
  </div>
</template>
<script>
import OrderEdit from "@/views/Logistics/OrderPurchase/OrderPurchaseEdit.vue";
import _sRequirementDetail from "@/services/Logistics/RequirementDetailService";
import _sRequirement from "../../../services/Logistics/RequirementService";
import _sOrderPurchase from "@/services/Logistics/OrderPurchaseService";
import _sOtherSupport from "@/services/Security/OtherSupportService.js";
import SArticlesGroup from "@/components/Utils/Logistics/SArticlesGroup.vue";
export default {
   name: "OrderPurchaseAttention",
  components: {
    SArticlesGroup,
    OrderEdit,
  },
  props:{model:false},
  data: () => ({
    filter: {
      AreID: 0,
      BeginDate: null,
      EndDate: null,
      TypeRequirement: 1,
      ItmsGrpCod: 0,
      Everybody: 1,
    },
    clearDate: true,
    config: {
      service: _sRequirementDetail,
      model: {
        DerID: "ID",
        ReqDocumentNumber: "string",
        ReqAuthorizationDate: "date",
        ReqDate: "date",
        ReqAccount: "string",
        ImpDiscount: "double",
        AvgPrice: "numeric",
        DiscPrcnt: "numeric",
        OddNumberLine: 0,DerStatus:''
      },
      headers: [
        {text: "Estado",value: "DerStatus", width: 70, }, 
        {text: "N° Requerimiento",value: "ReqDocumentNumber", width: 150, },   
        {text: "Artículo", value: "DerDescription", width: 530, },
        {text: "UM", value: "UnitMeasurement", width: 50,},
        {text: "Cant.Soli.",value: "DerQuantity", width: 70,},
        { text: "Cant.Aten.",value: "DerQuantityServed",width: 70,},
       // {text: "Cant.Pend.",value: "DerQuantity", width: 100,},
        {text: "F.Autorizacion", value: "ReqAuthorizationDate", width: 120,},
        {text: "Moneda",value: "LastPurCur", width: 100,},
        {text: "U.Prc. Compra",  value: "LastPurPrc", width: 120,},
        {text: "Stock Principal", value: "OnHand", width: 120,},
        {text: "Centro de Costo", value: "CceName", sortable: false, width: 250,},
        { text: "T.Costo", value: "TypeCostName",},
        { text: "Linea Negocio",value: "BslName", sortable: false, width: 250, },
        { text: "Proyecto", value: "PrjName", sortable: false, width: 250 },
        { text: "Comentario", value: "DerObservation", sortable: false, width: 300 },
      ],
    },
    value: null,
    itemsSAP: [],
    SupID: "",
    Supplier: null,
    dialogLoad: false,
    TaxCode: null,
    itemsConcept: [],
    itemsAccount: [],
    AcoID: null,
    ConID: null,
    ConName: null,
    order: { detail: [] },
    exchangeList: [],
    exchangeDate: null,
    DocCurrency: null,
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    excel(){
      _sRequirementDetail.reportAuthorize(this.filter,this.$fun.getUserID()).then((r)=>{
          this.$downloadFile(r.data,this.$const.TypeFile.EXCEL,'Requerimientos por atender');
      });
    },
    initialize() {},
deleted(items){
  items.map((item)=>{
    item.DerStatus=9;
    return item;
  })
  this.$fun.alert('Seguro de Anular?','question').then((r)=>{
    if(r.value){
    _sRequirementDetail.save(items,this.$fun.getUserID()).then(()=>{
      this.$fun.alert('Anulado Correctamente','success');
      this.$refs.crud.refresh();
    });}
  });
 
},
    rowSelected(items) {
      if (items.length > 0) {
        this.itemsSAP = items;
        for (let i = 0; i < this.itemsSAP.length; i++) {
          this.itemsSAP[i].OddNumberLine = i + 1;
          items.forEach((element) => {
            this.itemsSAP[i].OddQuantity = element.DerQuantity;
          });
        }
      } else this.itemsSAP = [];
    },
    change() {
      this.value = this.$fun.getDate();
      if (this.filter.Everybody == 1) {
        this.value = null;
        this.filter.BeginDate = this.value;
        this.filter.EndDate = this.value;
      } else {
        this.filter.BeginDate = this.value;
        this.filter.EndDate = this.value;
      }
      this.clearDate = this.filter.Everybody == 1 ? true : false;
      this.$emit("input", this.value);
    },
    previewSap() {
      if(this.model){this.$emit('selected',this.itemsSAP);return;}
      this.itemsSAP.map((e)=>e.DerQuantity=e.DerQuantity-e.DerQuantityServed);
     // console.log(this.itemsSAP);
      this.order.detail=this.itemsSAP;
      
      for(let i= 0;i<this.order.detail.length;i++){
        this.order.detail[i].OddDescription=this.order.detail[i].DerDescription;
        this.order.detail[i].OddObservation=this.order.detail[i].DerObservation;
        this.order.detail[i].OddQuantity=this.order.detail[i].DerQuantity;
           this.order.detail[i].Line=i+1;
      }    
      if (this.itemsSAP.length > 0)  this.dialogLoad = true; 
    },
    close() {
      this.dialogLoad = false;
    },
    SavePrice(item) {
      if (item.OddQuantity == null) item.DiscPrcnt = 0;
      else item.AvgPrice = item.OddUnitPrice * item.OddQuantity;
      this.SumTotal();
    },
    saveCalcuteImp(item) {
      console.log(item);
      if (item.ImpDiscount == null) item.DiscPrcnt = 0;
      else item.DiscPrcnt = item.AvgPrice * (item.ImpDiscount / 100);
    },
    saveTax(item) {
      if (this.TaxCode != null) {
        this.$nextTick().then(() => {
          this.itemsSAP[item.OddNumberLine - 1].TaxCode = this.TaxCode.DedAbbreviation;
          this.itemsSAP[item.OddNumberLine - 1].ImpDiscount = this.TaxCode.DedHelper;
          this.itemsSAP[item.OddNumberLine - 1].DiscPrcnt = item.AvgPrice == null ? 0 : item.AvgPrice * (item.ImpDiscount / 100);
          this.itemsSAP = Object.assign(this.itemsSAP, []);
          console.log(this.itemsSAP);
          this.SumTotal();
        });
      }
    },
    saveAccount(item) {
      if (this.AcoID != null) {
        this.$nextTick().then(() => {
          console.log(this.AcoID);
          this.itemsSAP[item.OddNumberLine - 1].ReqAccount = this.AcoID.CsaAccount;
          this.itemsSAP = Object.assign(this.itemsSAP, []);
        });
      }
    },
    SumTotal() {
      this.itemsSAP.forEach((element) => {
        this.Opor.OrdSubTotal = +element.AvgPrice;
        this.Opor.OrdTaxAmount = +element.DiscPrcnt;
      });

      this.Opor.OrdTotal = this.Opor.OrdSubTotal + this.Opor.DiscPrcnt + this.Opor.OrdTaxAmount;
    },
    Isvalidate() {},
    load() {
      this.Opor.TypeCurrency = this.DocCurrency.DedValue;
      this.Opor.OrdDate = this.$fun.getDate();
      this.Opor.Detail = this.itemsSAP;
      if (this.Isvalidate()) {
        this.Opor.OrdCardCode = this.Supplier.SupID;
        this.Opor.OrdCardName = this.Supplier.SupName;

        this.$fun.alert("Seguro de Guardar?", "question").then((val) => {
          if (val.value) {
            this.processingSAP = true;
            _sOrderPurchase.save(this.Opor, this.$fun.getUserSAPInfo().UsrID).then(
              (r) => {
                if (r.status == 200) this.$fun.alert("Registro correcto", "success");
                this.dialogLoad = false;
              },
              (e) => {
                this.dialogLoad = false;
              }
            );
          }
        });
      }
    },

    SelectRate(item) {
      let Rate;
      let Currency = 0;
      console.log(item);
      if (this.DocCurrency != null) {
        this.exchangeList.forEach((element) => {
          if (item.DedAbbreviation == element.name) {
            Rate = element.value;
            Currency = item.DedValue;
          }
        });
      } else Rate = 0;
      this.Opor.OrdDocRate = Rate;
    },
    printQoute() {
      let req = {
        ReqID: 0,
        RequestCollectedSAP: this.$fun.getUserInfo().NtpFullName,
        Detail: this.itemsSAP,
      };
      _sRequirement.quote(req, this.$fun.getUserID()).then((r) => {
        this.$fun.downloadFile(r.data, this.$const.TypeFile.PDF, this.$fun.getUserID());
      });
      console.log(req);
    },
  },
};
</script>
