<template
  ><div style="padding-top:-2px;margin-top:-2px;">
    <v-label
      ><b>{{ label }}</b></v-label
    >
    <s-select-generic
      class="text"
      itemvalue="AtvID"
      :disabled="disabled"
      :value="value"
      id="AtvID" itemtext="ArtDescription"
      :return-object="returnObject"
      @input="input($event)"
      :config="config"
      :full="full"
      :text="text"
      :clearable="clearable"
      :noDefault="noDefault"
    >
    </s-select-generic>
  </div>
</template>

<script>
import _sService from "@/services/Technicalassistance/TasTechnicalVisitService.js";
export default {
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    value: null,
    text: { type: String, default: "" },
    label: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    dedID : {
      type: Number,
      default: 0,
    },
    noDefault: {
      type: Boolean,
      default: false,
    },    
    full: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(value) {
      this.item = value;
    },
    dedID (){
        this.config.params.DedID=this.dedID;
    }
  },
  methods: {
    input(val) {
      this.$emit("input", val);
    },
    
  },
  data: () => ({
    item: 0,
    val: "",
    items: [],
    config: {},
  }),

  created() {
    this.config = {
      url: _sService.GetUrllisttasarticletypevisita(),
      title: this.label,
      params: { Search: "", requestID: this.$fun.getUserID() , DedID:this.dedID==0? 0 :this.dedID },
    };
  },
};
</script>
