import Service from "../Service";
import Vue from "vue";
const resource = "/ReceptionSurveillance/";

export default {

    GetTransport(obj, requestID) {

        return Service.post(resource + "GetTransport", obj, {
            params: { requestID: requestID },
        });
    },

    GetGang(obj, requestID) {

        return Service.post(resource + "GetGang", obj, {
            params: { requestID: requestID },
        });
    },
    GetDriver() {
        /*  return Service.post(resource + "GetDriver", "", {
             params: { AdvPlaqueVehicle: AdvPlaqueVehicle, requestID: requestID },
         }); */
        return Vue.prototype.$http.defaults.baseURL + resource + "GetDriver";
    },

    //Copiado de HarvestSchedule
    GetLot(PrdID = 0, fagID = 0, TypeLot = 0, search = '', requestid) {
        return Service.post("HarvestSchedule/listLote", {}, {
            params: { PrdID: PrdID, FagID: fagID, indicador: TypeLot, Search: search, RequestID: requestid }
        });
    },
    //Copiado de HarvestSchedule
    GetAgriculturalFarm(prdID = 0, search = '', requestid) {
        return Service.post("HarvestSchedule/listAgriculturalFarm", {}, {
            params: { PrdID: prdID, Search: search, RequestID: requestid },
        });
    },

    pagination(parameters, requestID) {
        //console.log("sdfsdfsd", parameters);
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "SaveReception", obj, {
            params: { requestID: requestID },
        });
    },


    /* LoadDestiny() {
        return Vue.prototype.$http.defaults.baseURL + resource + "GetDestiny";
    },

    SearchFundMaquilador() {
        return Vue.prototype.$http.defaults.baseURL + resource + "GetFundMaquilador";
    }, */



    /* GetRecord(cHarvestNumberProgramming, requestID) {
        return Service.post(resource + "GetRecord", cHarvestNumberProgramming, {
            params: { requestID: requestID },
        });
    }, */

    /* GetGang(parameters, requestID) {
        //console.log("sdfsdfsd", parameters);
        return Service.post(resource + "GetGang", parameters, {
            params: { requestID: requestID },
        });
    }, */



};