<template
  ><div style="padding-top:-2px;margin-top:-2px;">
    <s-select-generic ref="sProvince"
      autocomplete  class="text"
      itemtext="GenDescription"
      itemvalue="GenHelper" :disabled=disabled
      id="GenDescription"
      :return-object=returnObject
      @input="input($event)" 
      :config="config" :label=label
      :full="full" :autofocus=autofocus
      :text="text" :clearable=clearable :noDefault=noDefault
    >
    </s-select-generic>
  </div>
</template>

<script>
import _sGenericService from "./../../services/General/GenericService";
export default {
  components: {},
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
     clearable: {
      type: Boolean,
      default: false,
    },
    //value: {type:String,default:""},
    text: { type: String, default: "" },
    label: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    noDefault: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    DepartmentUbigeo: {
      type: String,
      default: "",
    },
    SearchText: {
      type: String,
      default: "",
    },
  },
  watch: {

    /* value(value) {
      this.item = value;
    }, */
    DepartmentUbigeo() {
      if(this.DepartmentUbigeo != null)
      {
        this.config.params.DepartmentUbigeo = this.DepartmentUbigeo;
        this.config.params.SearchText = this.SearchText;
      }
    },
  },
  methods: {
    focus(){
      this.$refs.sarea.focus();
    },

    input(val) {
      this.$emit("input", val);
    },
  
  },
    data: () => ({
        item: "",
        val:'',
        items: [],
        config: {},
    }),
  
    created() {
      if(this.DepartmentUbigeo != null)
      {
        this.config = {
          title: "Provincia",
          url: _sGenericService.getProvince(),
          params: { 
            DepartmentUbigeo: this.DepartmentUbigeo ,
            requestID: this.$fun.getUserID(), 
            SearchText: this.SearchText ,
            // DepartmentUbigeo: "20" 
          },
        };
      }
  }
}

</script>



<!-- this.configDepartment = {
        title: "Departamento",
        url: _sGeneric.getDepartment(),
        params: { requestID: this.$fun.getUserID() },
      };

      this.configDistrict = {
        title: "Distrito",
        url: _sGeneric.getDistrict(),
        params: { requestID: this.$fun.getUserID(), DistrictUbigeo: "0" },
      };
      this.configProvince = {
        title: "Provincia",
        url: _sGeneric.getProvince(),
        params: { requestID: this.$fun.getUserID(), DepartmentUbigeo: "0" },
      };

      this.DepartmentUbigeo = "20"; -->