import Service from "../Service";
import Vue from "vue";

const resource = "certifications/";

export default {
    save(cmp, requestID) {
        return Service.post(resource + "save", cmp, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    typecertificationlist(Search, requestID) {
        return Service.post(resource + "typecertificationlist", Search, {
            params: { requestID }
        });
    },

    getUrlTypeCertificationList() {
        return Vue.prototype.$http.defaults.baseURL + "/certifications/typecertificationlist";
    },

    typecertificationdetaillist(TcID, Search, requestID) {
        return Service.post(resource + "typecertificationdetaillist", {}, {
            params: { TcID, Search, requestID }
        });
    },

    getUrlTypeCertificationDetailList() {
        return Vue.prototype.$http.defaults.baseURL + "/certifications/typecertificationdetaillist";
    },
    //pdf
};