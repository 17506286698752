import Service from "../Service";

const resource = "certificationproducerrequirement/";

export default {
    save(tcs, requestID) {
      return Service.post(resource + "save", tcs, {
          params: {requestID}
      });
    },

    pagination(parameters, requestID){
        return Service.post(resource + "pagination", parameters, {
            params: {requestID}
        });
    },

    list(CpID, requestID){
        return Service.post( resource + "list",{},{
          params:{CpID: CpID, RequestID: requestID}
        });
      },
}