import Vue from "vue";
import VueRouter from "vue-router";
import SunExpert from "../views/Home.vue";
import AuthService from "../services/AuthService";
import { children as general } from "@/router//general.js";
import { children as management } from "@/router//management.js";
import { children as humanResource } from "@/router//humanResource.js";
import { children as dinningHall } from "@/router//dinningHall.js";
import { children as logistic } from "@/router//logistics.js";
import { children as security } from "@/router//security.js";
import { children as freshProduction } from "@/router//FreshProduction.js";
import { children as frozenProduction } from "@/router//FrozenProduction.js";
import { children as querymanager } from "@/router//QueryManager.js";
import { children as technicalAssistance } from "@/router//technicalAssistance.js";
import { children as externaltrade } from "@/router//ExternalTrade.js";
import { children as informationTechnology } from "@/router//informationTechnology.js";
import { children as production } from "@/router//production.js";
import { children as sale } from "@/router//sale.js";
import { children as chat } from "@/router//Chat.js";
import { children as helpDesk } from "@/router//HelpDesk.js";
Vue.use(VueRouter);

let children = [];
//General
children.push(...informationTechnology);
//General
children.push(...production);
//------------ ADMINISTRACION -------------------------
//General
children.push(...general);
//------------ ADMINISTRACION -------------------------
children.push(...management);
//------------- RECURSOS HUMANOS -----------------------
children.push(...humanResource);
// ---------------- COMEDOR ---------------------------
children.push(...dinningHall);
// -------- SEGURIDAD --------------------------
children.push(...security);
//LOGISTICS
children.push(...logistic);
//TECHNICAL ASSISTANCE
children.push(...technicalAssistance);
//EXTERNAL TRADE
children.push(...externaltrade);
//VENTAS
children.push(...sale);
//FRESH PRODUCTION
children.push(...freshProduction);
//FROZEN PRODUCTION
children.push(...frozenProduction);

children.push(...querymanager);
children.push(...chat);
children.push(...helpDesk);
//SYSTEMS
children.push({
    path: "/sistemas/tareas",
    name: "TASK",
    component: () =>
        import ("../views/Systems/Task.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Tecnologias de Información", disabled: true },
            { text: "Tareas", href: "/sistemas/tareas" },
        ],
    },
});

const routes = [{
        path: "*",
        redirect: "/login",
    },
    {
        path: "/",
        name: "HOME",
        component: SunExpert,
        meta: {
            authenticated: true,
            breadcrumb: [{ text: "Inicio", disabled: false, href: "/#/" }],
        },
        children: children,
    },
    {
        path: "/comedor/tiemporeal",
        name: "DGH_REAL_TIME",
        component: () =>
            import ("../views/DinningHall/TimeRealReserve"),
        meta: {
            authenticated: true,
            breadcrumb: [
                { text: "Inicio", href: "/#/" },
                { text: "Comedor", disabled: true },
                { text: "Reservas", href: "/comedor/tiemporeal" },
            ],
        },
    },
    {
        path: "/reservausuario",
        name: "DGH_RESERVE_USER",
        component: () =>
            import ("../views/DinningHall/ReserveUser.vue"),
        meta: {
            authenticated: true,
            breadcrumb: [
                { text: "Inicio", href: "/#/" },
                { text: "Comedor", disabled: true },
                { text: "Reserva de Usuario", href: "/comedor/reservausuario" },
            ],
        },
    },
    {
        path: "/comedor/consumo",
        name: "DGH_CONSUMPTION",
        component: () =>
            import ("../views/DinningHall/Consumption.vue"),
        meta: {
            authenticated: true,
            breadcrumb: [
                { text: "Inicio", href: "/#/" },
                { text: "Comedor", disabled: true },
                { text: "Consumo", href: "/comedor/consumo" },
            ],
        },
    },
    {
        path: "/seguridad/controlacceso",
        name: "MNG_ACCESSCONTROL",
        component: () =>
            import ("../views/Management/AccessControlDetail.vue"),
        meta: {
            authenticated: true,
            breadcrumb: [
                { text: "Inicio", href: "/#/" },
                { text: "seguridad", disabled: true },
                { text: "Control de Acceso", href: "/seguridad/controlacceso" },
            ],
        },
    },


    {
        path: "/seguridad/controlaccesostatic",
        name: "MNG_ACCESSCONTROLSTATIC",
        component: () =>
            import ("../views/Management/AccesControlDetailStatic.vue"),
        meta: {
            authenticated: true,
            breadcrumb: [
                { text: "Inicio", href: "/#/" },
                { text: "seguridad", disabled: true },
                { text: "Control de Acceso static", href: "/seguridad/controlaccesostatic" },
            ],
        },
    },


    {
        path: "/seguridad/controlbus",
        name: "MNG_ACCESSBUS",
        component: () =>
            import ("../views/HumanResource/AccessBus.vue"),
        meta: {
            authenticated: true,
            breadcrumb: [
                { text: "Inicio", href: "/#/" },
                { text: "seguridad", disabled: true },
                { text: "Control Bus", href: "/seguridad/controlbus" },
            ],
        },
    },
    {
        path: "/login",
        name: "LOGIN",
        breadcrumb: [{
            text: "login",
            disabled: false,
            href: "login",
        }, ],
        component: () =>
            import ("../views/Auth/Login.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

router.beforeEach((to, from, next) => {
    let activeSession = localStorage.getItem("UsrID") != null ? true : false;
    let authenticated = to.matched.some((record) => record.meta.authenticated);

    if (to.name.toUpperCase() != "HOME" && to.name.toUpperCase() != "LOGIN") {
        AuthService.userOption(localStorage.getItem("UsrID"), to.name).then((r) => {
            if (r.data.UsoID != null) {
                to.params.security = r.data;
                console.log(r.data);
                if (authenticated && !activeSession) next("login");
                else if (!authenticated && activeSession) next("");
                else next();
            }
        });
    } else if (authenticated && !activeSession) next("login");
    else if (!authenticated && activeSession) {
        next("");
    } else if (to.name.toUpperCase() == "HOME" && localStorage.getItem("UsrID") != null) {
        AuthService.userOption(localStorage.getItem("UsrID"), to.name).then((r) => {
            to.params.security = r.data;
            console.log(r.data);
            next();
        });
    } else next();
});

export default router;