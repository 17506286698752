import Service from "../Service";

const resource = "vehicle/";

export default {
  save(veh, requestID) {
    return Service.post(resource + "save", veh, {
      params: { requestID: requestID },
    });
  },
  pagination(dtr, requestID) {
    return Service.post(resource + "pagination", dtr, {
      params: { requestID: requestID },
    });
  },
  list(filter, requestID) {
    return Service.post(resource + "list", filter, {
      params: { requestID: requestID },
    });
  },
  listURL() {
    return resource + "list";
  },
  get(veh, requestID) {
    return Service.post(resource, veh, {
      params: { requestID: requestID },
    });
  },
  excel(cmp, requestID) {
    return Service.post(resource + "reportexcel", cmp, {
        responseType: "blob",
        headers: {
            "Content-Type": "application/json",
        },
    });
},
};
