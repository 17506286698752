<template>
  <s-crud @returnObject='refresh($event)'
    no-border
    title="Valores de Definición"
    :config="this.config"
    @save="saveDed($event)"
    add
    @clearForm="clearForm()"
    edit
    restore
    remove
    :filter="filter"
    formPermanent
    noConfimationSave
  > 
    <template scope="props">
      <v-container style="padding-top:0px;" v-if="props.item != null" class="pb-0">
        <v-row>
          <v-col cols="10" class="s-col-form">
            <s-text autofocus ref="DedDescription" v-model="props.item.DedDescription" label="Descripción Detalle" />
          </v-col>
          <v-col cols="2" class="s-col-form">
            <s-text :disabled="props.item.DedID == 0" v-model="props.item.DedValue" label="Valor" />
          </v-col>
          <v-col cols="6" class="s-col-form">
            <s-text v-model="props.item.DedAbbreviation" label="Abreviatura" />
          </v-col>
          <v-col cols="6" class="s-col-form">
            <s-text v-model="props.item.DedHelper" label="Utilitario" />
          </v-col>
            <v-col cols="12" class="s-col-form">
            <s-text v-model="props.item.DedFormat" label="Formato" />
          </v-col>
          <!--  <v-col cols="12" class="s-col-form">
            <s-textarea v-model="props.item.DedObservation" label="Observación" />
          </v-col>-->
        </v-row>
      </v-container>
    </template>
    <template v-slot:SecStatus="{ row }">
      <v-icon :color="row.SecStatus == 1 ? 'success' : 'default'"> mdi-checkbox-blank-circle</v-icon>
    </template>
  </s-crud>
</template>

<script>
import _sDefinitionDetail from "@/services/General/DefinitionDetailService";
export default {
  props: {
    defID: 0,
    defDescription: "",
  },
  data: () => ({
    // filter: { DefID: 0, SecStatus: 0 },
    config: {
      title: "Definiciones Detalles",
      service: _sDefinitionDetail,
      model: {
        DedID: "ID",
        DefID: "int",
        DedDescription: "string",
        DedAbbreviation: "string",
        DedHelper: "string",
        DedValue: "int",
        SecStatus: "status",
      },
      headers: [
        {
          text: "ID",
          value: "DedID",
          width: "5%",
          align: "end",
          sorteable: true,
        },
        {
          text: "Definicion",
          value: "DedDescription",
          width: "55%",
          sorteable: true,
        },
        {
          text: "Abreviatura",
          value: "DedAbbreviation",
          width: "15%",
          sorteable: false,
        },
        {
          text: "Helper",
          value: "DedHelper",
          width: "15%",
          sorteable: false,
        },
        {
          text: "Formato",
          value: "DedFormat",
           
          sorteable: false,
        },
        {
          text: "Valor",
          value: "DedValue",
          width: "5%",
          align: "center",
          sorteable: true,
        },
        {
          text: "Estado",
          value: "SecStatus",
          width: "5%",
          align: "center",
          sorteable: false,
        },
      ],
    },
  }),
  // watch: {
  //   defID() {
  //     this.filter = {
  //       DefID: this.defID,
  //       SecStatus: 0,
  //     };
  //   },
  // },
  computed: {
    filter() {
      return { DefID: this.defID, SecStatus: 0 };
    },
  },
  methods: {
    refresh(item){
      this.$emit('refresh',item);
    },
    clearForm() {
      this.$refs.DedDescription.focus();
    },
    saveDed(item) {
      
      this.$fun.alert('¿Seguro de Guardar?','question').then((r)=>{
        if(r.value){
          item.DefID = this.defID;
      if (item.DedDescription.length == 0) {
        this.$fun.alert("Registre descripción de valor", "warning");
        return;
      }
      item.save();
      console.log(item);
        }
      });
      
    },
  },
};
</script>
