import Service from "../Service";

const resource = "certificationrequirement/";

export default {
    list(CsID, requestID){
        return Service.post( resource + "list",{},{
          params:{CsID: CsID, RequestID: requestID}
        });
      },
}