import Service from "../Service";
import Vue from "vue";
const resource = "articleTypeVisita/";

export default {
 /* save(prd, requestID) {
    console.log(prd);
    return Service.post(resource + "save", prd, {
      params: { requestID: requestID },
    });
  },

  pagination(parameters, requestID) {
    console.log('parametro',parameters)
    return Service.post(resource + "pagination", parameters, {
      params: { requestID: requestID },
    });
  },*/

  //CODIGO EDGAR
  listArticles(search, RequestID){
    
      return Service.post(resource + "listArticles",{},{
      params:{Search: search, RequestID: RequestID}
    });
   
  },
 GetUrllistArticles(){
    return Vue.prototype.$http.defaults.baseURL + "/articleTypeVisita/listArticles";
  },
};
