<template>
    <div>
        <Pie
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
        />
        <!-- <canvas ref="chart"  :style="{'height': height + 'px'}" class="pie"></canvas> -->
    </div>
</template>

<script>

import {Pie} from  'vue-chartjs/legacy'
import {Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default{
    // name: 'PieChart',
    components: { Pie},
    props: {
                
		data:{
            type: Object,
            default: () => {}
        },        
        chartId: {
        type: String,
        default: 'pie-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {}
        },
        plugins: {
            type: Array,
            default: () => []
        },

    },
    data(){
        return{
              
            chartData: this.data,   
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            }         
        }
    },
    mounted () { 
        
        
        },
    }

</script>