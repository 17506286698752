<template>
  <v-card width="300">
    <v-card-title>
      Configuración
    </v-card-title>

    <v-tabs background-color="transparent" grow v-model="tab">
      <v-tab href="#tab-0">
        General
      </v-tab>
      <v-tab href="#tab-1">
        Diseño
      </v-tab>
      <v-tab href="#tab-2">
        SAP
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item :value="'tab-0'">
        <v-card class="text-center">
          <v-list v-if="user.NtpID > 0" class="mx-auto">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="headline">
                  {{ user.NtpName }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ user.NtpPaternalSurname }}
                  {{ user.NtpMaternalSurname }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-img v-if="personImage != null" contain :src="personImage" height="200"></v-img>
            <v-list-item v-if="user.CtrContract != null">
              <v-list-item-content>
                <v-list-item-title class="headline">
                  {{ user.CtrContract == null ? "" : user.CtrContract.PstName }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ user.CtrContract == null ? "" : user.CtrContract.AreName }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-btn small color="primary" block @click="logout">
              Cerrar sesión
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item :value="'tab-1'">
        <v-card max-height="400" style="overflow-x:hidden">
          <v-card-text>
            Tema
            <v-switch v-model="Statusbackground" label="Modo Oscuro" @change="status(Statusbackground)"></v-switch>

            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header>Toolbar</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-color-picker :mode.sync="mode" v-model="generatetext" dot-size="10" @input="inputTool"></v-color-picker>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header> Navigation</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-color-picker :mode.sync="mode" v-model="navtext" dot-size="10" @input="inputNavigation"></v-color-picker>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-switch v-model="IsReset" label="Configuracion Defecto" @change="Reset(IsReset)"></v-switch>
          </v-card-text>
          <v-card-actions>
            <v-btn block small color="primary" @click="insert">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item :value="'tab-2'">
        <s-manager-sap :activate="managerSAP" v-model="userSAP" />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import sManagerSap from "@/views/Security/UserSAP/ManagerAccount.vue";
import _sUserConfig from "@/services/Security/UserConfigurationService.js";
import _sHelper from "@/services/HelperService";
export default {
  components: { sManagerSap },
  props: {
    activate: {
      type: Boolean,
      default: false,
    },
    configUser: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: { PrsDocumentNumber: "" },
    },
    personImage: null,
  },
  data: () => ({
    IsReset: false,
    method: "",
    tab: null,
    managerSAP: false,
    userSAP: {},
    Statusbackground: false,
    IsInitialize: false,
    Navigation: "",
    Tool: "",
    generatetext: "",
    navtext: "",
    loading: false,
    mode: "hexa",
    panel: [],
    config: [{}],
    stylemodedark: "rgb(53, 54, 58)",
  }),
  mounted() {
    this.method = "show";
  },
  methods: {
    initialize() {
      this.generatetext = "#e0e0e0";
      this.navtext = "#E0E0E0";
      this.Statusbackground = this.configUser == null ? false : this.configUser.CfgTheme === 1 ? true : false;
    },
    status(value) {
      this.IsReset = false;
      if (value) {
        this.Statusbackground = value;
        this.Tool = this.stylemodedark;
        this.Navigation = this.stylemodedark;
      } else {
        this.Statusbackground = false;
        this.Tool = "";
        this.Navigation = "";
        this.$emit("getReset", true);
      }
      this.$emit("getBackground", value);
    },
    Reset(value) {
      this.Statusbackground = false;
      this.Tool = "";
      this.Navigation = "";
      this.$emit("getReset", value);
    },
    inputNavigation(value) {
      this.loading = true;
      if (this.loading) {
        this.IsReset = false;
        this.$emit("getNavigation", value);
        this.Navigation = value;
      } else this.Navigation = this.navtext;
    },
    inputTool(value) {
      this.loading = true;
      if (this.loading) {
        this.$emit("getToolbar", value);
        this.Tool = value;
      }
    },
    insert() {
      let conf = {
        CfgID: this.configUser == null ? 0 : this.configUser.CfgID,
        CfgDescription: "Configuration",
        CfgTheme: this.Statusbackground ? 1 : 0,
        CfgToolbar: this.Tool,
        CfgNavigation: this.Navigation == "" ? this.navtext : this.Navigation,
        UsrID: this.$fun.getUserID(),
        SecStatus: 1,
        UsrCreateID: this.$fun.getUserID(),
      };
      _sUserConfig.save(conf, this.$fun.getUserID()).then((response) => {
        this.$fun.alert("Registros actualizados correctamente", "success");
      });
    },
    logout() {
      this.$emit("getLogout");
    },
  },
  computed: {
    show() {
      return this.method == "show";
    },
  },
  watch: {
    activate() {
      this.method = "show";
      this.Statusbackground = this.configUser == null ? false : this.configUser.CfgTheme === 1 ? true : false;
    },
  },
  created() {
    this.initialize();
  },
};
</script>
