import Service from "../Service";
import Vue from "vue";
const resource = "HarvestSchedule/";

export default {
    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    list(typeMaterial, search, requestID) {
        return Service.post(resource + "listMaterial", {}, {
            params: { TypeMaterial: typeMaterial, Search: search, RequestID: requestID },
        });
    },
    getUrlHarvestList() {
        return Vue.prototype.$http.defaults.baseURL + "/HarvestSchedule/listMaterial";
    },
    listTransport(advID, search, requestid) {
        return Service.post(resource + "listTransport", {}, {
            params: { AdvID: advID, Search: search, RequestID: requestid },
        });
    },

    getUrlHarvestListTransport() {
        return Vue.prototype.$http.defaults.baseURL + "/HarvestSchedule/listTransport";
    },

    listProducer(FagID, search, requestid) {
        return Service.post(resource + "listProducer", {}, {
            params: { FagID: FagID, Search: search, RequestID: requestid },
        });
    },
    getUrllistProducer() {
        return Vue.prototype.$http.defaults.baseURL + "/HarvestSchedule/listProducer";
    },

    listAgriculturalFarm(prdID, search, requestid) {
        return Service.post(resource + "listAgriculturalFarm", {}, {
            params: { PrdID: prdID, Search: search, RequestID: requestid },
        });
    },
    GetUrllistAgriculturalFarm() {
        return Vue.prototype.$http.defaults.baseURL + "/HarvestSchedule/listAgriculturalFarm";
    },

    listLote(PrdID, fagID, TypeLot, search, requestid) {
        return Service.post(resource + "listLote", {}, {
            params: { PrdID: PrdID, FagID: fagID, indicador: TypeLot, Search: search, RequestID: requestid }
        });
    },
    GetUrllistLote() {
        return Vue.prototype.$http.defaults.baseURL + "/HarvestSchedule/listLote";
    },

    listVariaty(defID, search, requestid) {
        return Service.post(resource + "listVariaty", {}, {
            params: { DefID: defID, Search: search, RequestID: requestid }
        });
    },
    GetUrllistVariaty() {
        return Vue.prototype.$http.defaults.baseURL + "/HarvestSchedule/listVariaty";
    },
    listSupervice(spvID, search, requesid) {
        return Service.post(resource + "listSupervice", {}, {
            params: { SpvID: spvID, Search: search, RequestID: requesid }
        });
    },
    getUrlListSupervice() {
        return Vue.prototype.$http.defaults.baseURL + "/HarvestSchedule/listSupervice";
    },
    listForeman(frmid, search, requesid) {
        return Service.post(resource + "listForeman", {}, {
            params: { FrmId: frmid, Search: search, RequestID: requesid }
        });
    },
    getUrlListForeman() {
        return Vue.prototype.$http.defaults.baseURL + "/HarvestSchedule/listForeman";
    },


    //BEGIN:: completar cuadros el editar
    listMaterial(GfrID) {
        return Service.post(resource + "materialSearchByHvs", {}, {
            params: { GfrID: GfrID }
        })
    },

    listProducerS(HvsID) {
        return Service.post(resource + "transportSearchByHvs", {}, {
            params: { HvsID: HvsID }
        })
    },

    listGenForemanS(HvsID) {
        return Service.post(resource + "genForemanSearchByHvs", {}, {
            params: { HvsID: HvsID }
        })
    },


    groupcreate(HvsID, requestID) {
        return Service.post(resource + "groupcreate", {}, {
            params: { HvsID: HvsID, requestID }
        })
    }
};