import { render, staticRenderFns } from "./SLoad.vue?vue&type=template&id=0b44bbde&"
import script from "./SLoad.vue?vue&type=script&lang=js&"
export * from "./SLoad.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VLabel } from 'vuetify/lib/components/VLabel';
installComponents(component, {VFileInput,VLabel})
