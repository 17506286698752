<template>
  <v-toolbar class="tran" dense height="30" rounded="" :elevation="!noDark ? '0' : '0'" color="black" dark>
    <v-toolbar-title> {{ title }}</v-toolbar-title>
    <v-spacer></v-spacer>

    <v-toolbar-title> {{ label }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-tooltip bottom="" v-if="approve">
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on" small @click="$emit('approved')"> <v-icon style="font-size:16px;"> far
            fa-thumbs-up</v-icon></v-btn>
      </template>
      <span>Aprobar</span>
    </v-tooltip>

    <v-tooltip bottom="" v-if="approve">
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on" small @click="$emit('disapproved')"> <v-icon style="font-size:16px;"> far
            fa-thumbs-down</v-icon></v-btn>
      </template>
      <span>Desaprobar</span>
    </v-tooltip>

    <v-tooltip bottom="" v-if="sync">
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on" small @click="$emit('sync')"> <v-icon> mdi-reload</v-icon></v-btn>
      </template>
      <span>Sincronizar</span>
    </v-tooltip>

    <slot name="options"> </slot>

    <v-tooltip bottom="" v-if="load">
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on" small @click="loadButton()" elevation="0"> <v-icon
            style="font-size:18px;">mdi-arrow-up</v-icon></v-btn>
      </template>
      <span>Cargar</span>
    </v-tooltip>

    <v-tooltip bottom="" v-if="download">
      <template v-slot:activator="{ on }" x-small>
        <v-btn text v-on="on" x-small @click="$emit('download')"> <v-icon>mdi-cloud-download</v-icon></v-btn>
      </template>
      <span>Descargar</span></v-tooltip>

    <v-tooltip bottom="" v-if="transfer">
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on" small @click="transferButton()" elevation="0"> <v-icon style="font-size:18px;">fas
            fa-exchange-alt</v-icon></v-btn>
      </template>
      <span>Transferir</span>
    </v-tooltip>

    <v-tooltip bottom="" v-if="$fun.isAdmin() && enable">
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on" small @click="enableButton()" elevation="0"> <v-icon style="font-size:16px;">fas
            fa-lock-open</v-icon></v-btn>
      </template>
      <span>Habilitar LP</span>
    </v-tooltip>

    <v-tooltip bottom="" v-if="$fun.getSecurity().IsAllowAdd && add && activeAdd && !formPermanent">
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on" small @click="showForm(true, false)" elevation="0">
          <v-icon style="font-size:18px;">mdi-plus-circle-outline</v-icon></v-btn>
      </template>
      <span>Agregar</span>
    </v-tooltip>

    <v-tooltip bottom="" v-if="$fun.getSecurity().IsAllowEdit && edit && activeItem && !formPermanent">
      <template v-slot:activator="{ on }">
        <v-btn text small v-on="on" @click="showForm(false, true)"> <v-icon
            style="font-size:14px;">mdi-file-edit-outline</v-icon></v-btn>
      </template>
      <span>Editar</span></v-tooltip>

    <v-tooltip bottom="" v-if="view && activeItem && !formPermanent">
      <template v-slot:activator="{ on }">
        <v-btn text small v-on="on" @click="showForm(false, true)"> <v-icon style="font-size:14px;">far
            fa-eye</v-icon></v-btn>
      </template>
      <span>Ver</span>
    </v-tooltip>

    <!--print-->
    <v-tooltip bottom v-if="print">
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on" small @click="printRow()" elevation="0">
          <v-icon style="font-size:18px;">mdi-printer</v-icon>
        </v-btn>
      </template>
      <span>Imprimir</span></v-tooltip>

    <!--CANCELAR-->
    <v-tooltip bottom="" v-if="openForm && !formPermanent">
      <template v-slot:activator="{ on }">
        <v-btn text small style="margin-right:5px;" v-on="on" @click="cancel()"> <v-icon style="font-size:18px;">
            mdi-arrow-left</v-icon></v-btn>
      </template>
      <span>Cancelar</span>
    </v-tooltip>

    <v-tooltip bottom="" v-if="(activeSave && openForm && !formPermanent) || save">
      <template v-slot:activator="{ on }">
        <v-btn text small v-on="on" class="capitalize save" @click="saveEvent()"> <v-icon
            style="font-size:16px;">mdi-content-save</v-icon></v-btn>
      </template>
      <span>Guardar</span>
    </v-tooltip>

    <v-tooltip bottom="" v-if="off">
      <template v-slot:activator="{ on }">
        <v-btn text small v-on="on" @click="$emit('off')"> <v-icon style="font-size:16px;">
            mdi-link-variant-off</v-icon></v-btn>
      </template>
      <span>Desabilitar</span>
    </v-tooltip>
    
    <div v-if="$fun.getSecurity().IsAllowDelete && entity != null && activeItem">
      <template>
        <v-tooltip bottom="" v-if="restore && entity.SecStatus == 0">
          <template v-slot:activator="{ on }">
            <v-btn text small v-on="on" @click="changeStatus(1)"> <v-icon
                style="font-size:18px;">mdi-checkbox-marked-circle-outline</v-icon></v-btn>
          </template>
          <span>Habilitar</span>
        </v-tooltip>

        <v-tooltip bottom="" v-if="remove">
          <template v-slot:activator="{ on }">
            <v-btn text small v-on="on" @click="changeStatus(0)"> <v-icon
                style="font-size:18px;">mdi-minus-circle-outline</v-icon></v-btn>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
    </div>
    <div v-if="search">
      <v-menu v-if="!searchInput" v-model="menuSearch" :close-on-content-click="false" offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ ons }">
              <div v-on="ons" style="display:inline;">
                <v-btn text small v-on="on" v-bind="attrs">
                  <v-icon style="font-size:18px;">mdi-magnify</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Buscar</span>
          </v-tooltip>
        </template>
        <v-card rounded="">
          <v-list>
            <v-col style="padding-top:0px !important;padding-bottom:0px !important;">
              <v-text-field style="margin:5px;" class="s-toolbar-search" ref="buscar" v-model="searchText"
                append-icon="mdi-magnify" autofocus="" outlined dense clearable hide-details />
            </v-col>
          </v-list>
        </v-card>
      </v-menu>
    </div>
    <div>
      <v-text-field v-if="searchInput" style="margin-top:-1px;width:150px !important;" ref="buscar" height="30" class=""
        width="60" v-model="searchText" :append-icon="btnsearch ? '' : 'mdi-magnify'" dense clearable hide-details />
    </div>
    <v-tooltip bottom="" v-if="btnsearch">
      <template v-slot:activator="{ on }">
        <v-btn :disabled="loading" :color="btnsearchKey ? 'red' : ''" text v-on="on" small @click="$emit('btnsearch')"> <v-icon> mdi-magnify</v-icon></v-btn>
      </template>
      <span>Buscar</span>
    </v-tooltip>
    <v-menu left bottom v-if="$fun.getSecurity().IsAllowExport && (pdf || excel)">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" small>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item v-if="excel && !openForm" @click="excelReport()">
          <v-list-item-title>
            <v-icon style="font-size:14px; margin-right:10px;" color="green darken-1">far fa-file-excel</v-icon>Reporte
            Excel
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="pdf && !openForm" @click="pdfReport()">
          <v-list-item-title><v-icon style="font-size:14px; margin-right:10px;" color="red darken-2">far
              fa-file-pdf</v-icon>Reporte PDF
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-tooltip bottom="" v-if="close">
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on" small @click="$emit('close')" elevation="0"> <v-icon
            style="font-size:18px;">mdi-close</v-icon></v-btn>
      </template>
      <span>Cerrar</span>
    </v-tooltip>
  </v-toolbar>
</template>

<script>
export default {
  name: "Toolbar",
  props: {
    save: {
      type: Boolean,
      default: false,
    },
    approve: {
      type: Boolean,
      default: false,
    },
    searchInput: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    view: {
      type: Boolean,
      default: false,
    },
    colorToolbar: {
      type: String,
      default: "primary",
    },
    title: {
      type: String,
      default: "",
    },
    entity: {
      type: Object,
      default: null,
    },
    noDark: {
      type: Boolean,
      default: true,
    },
    load: {
      type: Boolean,
      default: false,
    },

    sync: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    btnsearchKey: {
      type: Boolean,
      default: false,
    },
    btnsearch: {
      type: Boolean,
      default: false,
    },
    transfer: {
      type: Boolean,
      default: false,
    },
    download: {
      type: Boolean,
      default: false,
    },
    enable: {
      type: Boolean,
      default: false,
    },

    saveList: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    off: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    add: {
      type: Boolean,
      default: false,
    },
    pdf: {
      type: Boolean,
      default: false,
    },
    excel: {
      type: Boolean,
      default: false,
    },

    remove: {
      type: Boolean,
      default: false,
    },
    doubleClick: {
      type: Boolean,
      default: false,
    },
    service: {
      type: Object,
      default: null,
    },
    search: {
      type: Boolean,
      default: false,
    },
    formPermanent: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    restore: {
      type: Boolean,
      default: false,
    },
    deleteNoFunction: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Boolean,
      default: false,
    },
    print: {
      type: Boolean,
      default: false,
    },
    addNoFunction: { type: Boolean, default: false },
    noConfimationSave: { type: Boolean, default: false },
  },

  data: () => ({
    entityEditing: null,
    adding: false,
    activeItem: true,
    openForm: false,
    editing: false,
    modeForm: false,
    activeSave: false,
    activeEdit: true,
    activeRemove: true,
    activeAdd: true,
    entityBack: null,
    menuSearch: "",
    searchText: "",
  }),
  mounted() { },
  methods: {
    loadButton() {
      this.$emit("load");
    },
    printRow() {
      this.$emit("print");
    },
    transferButton() {
      this.$emit("transfer");
    },

    enableButton() {
      this.$emit("enable");
    },

    getBorder() {
      if (this.noBorder) {
        return "none";
      } else return "1px solid rgba(215,215,215,0.5) !important";
    },
    getAnswer() {
      if (this.searchText == null) this.searchText = "";
      this.$emit("searchEvent", this.searchText);
    },
    excelReport() {
      this.$emit("excel");
    },
    pdfReport() {
      this.$emit("pdf");
    },

    changeStatus(value) {
      if (!this.deleteNoFunction) {
        let obj = Object.assign({}, this.entity);
        obj.SecStatus = value;
        obj.UsrUpdateID = this.$fun.getUserID();
        let Text = "Seguro de Eliminar Registro";
        if (value == 1) Text = "Seguro de Restablecer Registro?";
        if (this.noConfimationSave) {
          this.service.save(obj, this.$fun.getUserID()).then((res) => {
            this.$emit("refresh", null);
            this.$fun.alert("Actualizado Correctamente", "success");
            if (value == 0) {
              this.$emit("removed");
            }
          });
        } else
          this.$fun.alert(Text, "question").then((val) => {
            if (val.value) {
              this.service.save(obj, this.$fun.getUserID()).then((res) => {
                this.$emit("refresh", null);
                if (value == 0) {
                  this.$emit("removed");
                }
                this.$fun.alert("Actualizado Correctamente", "success");
              });
            }
          });
      } else this.$emit("delete", this.entity);
    },
    delete: function () {
      this.$emit("delete", this.objEdit, true);
    },
    saveEvent: function () {
      this.$emit("save");
    },

    cancel() {
      this.activeAdd = true;
      this.activeSave = false;
      this.activeRemove = true;
      this.openForm = false;
      this.$emit("showFormEvent", {
        add: false,

        edit: false,
      });
      if (this.entityEditing != null) {
        this.activeItem = true;
        this.$emit("restartEntity", this.entityEditing);
        this.entityEditing = null;
      }
      if (this.entity.SecStatus != null) this.activeEdit = true;
      else this.activeEdit = false;
    },
    showForm: function (add, edit) {

      if (this.addNoFunction) {
        if (add) this.$emit("add");
        if (edit) this.$emit("edit");
      } else {
        this.entityEditing = Object.assign({}, this.entity);
        if (add) {
          this.$emit("activePrd");
          this.$emit("addEvent");
          this.$emit("showFormEvent", {
            add: true,
            edit: false,
          });
          this.activeSave = true;
          this.editing = false;
          this.activeAdd = false;
          this.activeItem = false;
          this.activeRemove = false;
          this.adding = true;
          this.openForm = true;
        } else if (edit && this.$fun.getSecurity().IsAllowEdit) {
          this.editing = true;
          this.activeSave = true;
          this.openForm = true;
          this.activeEdit = false;
          this.activeAdd = false;
          this.activeRemove = false;
          this.activeItem = false;
          this.$emit("EditEvent");
          this.$emit("showFormEvent", {
            add: false,
            edit: true,
          });
        } else {
          this.openForm = false;
          this.activeSave = false;
          this.editing = false;
          this.activeAdd = true;
          this.activeEdit = true;
          this.activeItem = true;
          this.activeRemove = true;
          this.$emit("showFormEvent", {
            add: false,
            edit: false,
          });
        }
      }
    },
  },
  watch: {

    searchText() {
      this.debouncedGetAnswer();
    },
    doubleClick() {
      this.showForm(false, true);
    },
    entity() {
      if (this.entity.SecStatus != null) {
        this.activeEdit = true;
        this.activeItem = true;
      }
      if (this.openForm && this.entity.SecStatus != null) {
        this.activeAdd = true;
        this.activeSave = false;
        this.activeRemove = true;
        this.openForm = false;
        this.$emit("showFormEvent", {
          add: false,
          edit: false,
        });
        this.activeEdit = true;
        this.activeItem = true;
      }
    },
  },
  created() {
    this.debouncedGetAnswer = _.debounce(this.getAnswer, 500);
  },
};
</script>

<style>
.basil {
  background-color: #fffbe6 !important;
}

.font-20 {
  font-size: 18px !important;
  padding-right: 5px;
}

.save {
  margin-right: 7px;
}

.tran {
  opacity: 0.4 !important;
  /*background: rgba(76, 175, 80, 1)*/
}
</style>
