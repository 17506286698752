import Service from "../Service";
import Vue from "vue";
const resource = "/worker/";

export default { 
  searchworker() {
    return Vue.prototype.$http.defaults.baseURL  +'/worker/workerce'
   },
   pagination(dtr, requestID) {
    return Service.post(resource + "pagination", dtr, {
      params: { requestID: requestID },
    });
  },
};
