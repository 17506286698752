import Service from "./Service";
const resource = "helper/";
import Vue from "vue";

export default {
    getPhotoWorker(document, requestID) {
        // return Vue.prototype.$http.get(resource + "HelperWorkerPhoto", {
        //   responseType: "blob",
        //   headers: {
        //     "Content-Type": "image/jpg",
        //   },
        //   params: { PrsDocumentNumber: document },
        // });
        return Service.get(resource + "HelperWorkerPhoto", {
            responseType: "blob",
            headers: {
                "Content-Type": "image/jpg",
            },
            params: { PrsDocumentNumber: document, requestID: requestID },
        });
    },
    getPhotoFignature(document, requestID) { 
        return Service.get(resource + "photoFignature", {
            responseType: "blob",
            headers: {
                "Content-Type": "image/jpg",
            },
            params: { DocumentNumber: document, requestID: requestID },
        });
    },
    getPhotoMealUrl(TypeMeal) {
        // return (
        //   Vue.prototype.$http.defaults.baseURL +
        //   "/" +
        //   menu +
        //   "MealPhoto?TypeMeal=" +
        //   TypeMeal
        // );
        return Vue.prototype.$http.defaults.baseURL + "/" + resource + "MealPhoto?TypeMeal=" + TypeMeal;
    },

    uploadfile(document, requestID, files, parameter) {
        return Service.post(resource + "uploadfiles", files, {
            params: {
                PrsDocumentNumber: document,
                requestID: requestID,
                parameter: parameter,
            },
            "Content-Type": "multipart/form-data",
        });
    },
    uploadfilesgeneric(documentName, requestID, files, parameterName, isZip) {
        return Service.post(resource + "uploadfilesgeneric", files, {
            params: {
                documentName,
                requestID,
                parameterName,
                isZip
            },
            "Content-Type": "multipart/form-data",
        });
    },
    _uploadfile(document, requestID, files, parameter) {
        return new Promise((resolve, reject) => { 
            
            try {
                Service.post(resource + "uploadfiles", files, {
                params: {
                    PrsDocumentNumber: document,
                    requestID: requestID,
                    parameter: parameter,
                },
                "Content-Type": "multipart/form-data",
                })
                .then(data => {
                    return resolve(data);
                });

            } catch (error) {
                return reject(error)
            }
        });
    },
    getfile(document, requestID, file, parameter) {
        return Service.get(resource + "dowloadfiles", {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                PrsDocumentNumber: document,
                requestID: requestID,
                filename: file,
                parameter: parameter,
            },
        });
    },
    download(path, requestID) {
        return Service.post(resource + "download", {}, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                path: path,
                requestID: requestID,
            },
        });
    },

    delete(document, requestID, parameter, file) {
        console.log(document, requestID, parameter, file);
        return Service.post(resource + "deletefile", file, {
            params: {
                PrsDocumentNumber: document,
                requestID: requestID,
                parameter: parameter,
                fileName: file,
            },
        });
    },
};