<template
  ><div style="padding-top:-2px;margin-top:-2px;">
    <v-label
      ><b>{{ label }}</b></v-label
    >
    <s-select-generic
      autocomplete
      class="text"
      itemtext="GenDescription"
      itemvalue="GenID"
      :disabled="disabled"
      id="GenDescription"
      :value="value"
      :return-object="returnObject"
      @input="input($event)"
      :config="config"
      :full="true"
      :text="text"
      :clearable="clearable"
      :noDefault="noDefault"
    >
    </s-select-generic>
  </div>
</template>

<script>
import _sArticleCategory from "@/services/General/GenericService";
export default {
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    value: null,
    text: { type: String, default: "" },
    label: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    typeparameters: {
      type: Boolean,
      default: false,
    },
    noDefault: {
      type: Boolean,
      default: false,
    },    
    full: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(value) {
      this.item = value;
    },
  },
  methods: {
    input(val) {
      this.$emit("input", val);
    },
  },
  data: () => ({
    item: 0,
    val: "",
    items: [],
    config: {},
  }),

  created() {
    this.config = {
      url: _sArticleCategory.getArticlesGroup(),
      title: this.label,
      params: { search: "", requestID: this.$fun.getUserID() },
    };
  },
};
</script>
