let children = [];
//------------ ADMINISTRACION -------------------------
children.push({
    path: "/produccion/trazabilidad",
    name: "PRO_ TRACEABILITY",
    component: () =>
        import ("@/views/Production/Traceability/Traceability.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción", disabled: true },
            { text: "Trazabilidad", href: "/produccion/trazabilidad" },
        ],
    },
});
export { children }