import Service from "../Service";

const resource = "othersupport/";

export default {
  list(uss, requestID) {
    return Service.post(resource + "userlist", uss, {
      params: { requestID: requestID },
    });
  },
  changePassword(uss, requestID) {
    return Service.post(resource + "changePassword", uss, {
      params: { requestID: requestID },
    });
  },
  exchangeRateList(requestID) {
    return Service.post(
      resource + "exchangeratelist",
      {},
      {
        params: { requestID: requestID },
      }
    );
  },
  exchangeRateSave(date, requestID) {
    return Service.post(
      resource + "exchangeratesave",
      {},
      {
        params: { requestID: requestID, date: date },
      }
    );
  },
};
