import Service from "../Service";

const resource = "workerallowance/";

export default {
    get(adm, requestID) {
        return Service.post(resource, adm, {
            params: { requestID: requestID },
        });
    },

    save(wac, requestID) {
        return Service.post(resource + "save", wac, {
            params: { requestID: requestID },
        });
    },
    birthday(wac, requestID) {
        return Service.post(resource + "birthday", wac, {
            params: { requestID: requestID },
        });
    },

    pagination(dtr, requestID) {
        return Service.post(resource + "pagination", dtr, {
            params: { requestID: requestID },
        });
    },
    excel(dtr) {
        return Service.post(
            resource + "report", {}, {
                responseType: "blob",
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    typepayroll: dtr.filter.TypePayroll,
                    typegroup: dtr.filter.TypeGroup,
                    typeincident: dtr.filter.TypeIncident,
                    init: dtr.filter.WacBeginDate,
                    end: dtr.filter.WacEndDate,
                },
            }
        );
    },
    saveMassive(hmw, usr) {
        console.log(hmw);
        return Service.post(resource + "saveMassive", hmw, {
            params: { usrID: usr },
        });
    },
};