import Service from "../Service";

const resource = "requirementdetail/";

export default {
    save(cmp, requestID) {
        return Service.post(resource + "save", cmp, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "paginationDetail", parameters, {
            params: { requestID: requestID },
        });
    },
    detail(req, requestID) {
        return Service.post(resource + "detailByRequirement", req, {
            params: { requestID: requestID },
        });
    },
    excel(dtr, requestID) {
        console.log(dtr);
        return Service.post(resource + "excelAutorizeDetail", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },

    savereleased(cmp, requestID) {
        return Service.post(resource + "savereleased", cmp, {
            params: { requestID: requestID },
        });
    },

};