let children = [];

//codigo edgar
children.push({
    path: "/asistenciatecnica/TiposdeCertificaciones",
    name: "ATS_TYPECERT",
    component: () =>
        import ("../views/TechnicalAssistance/typeCertification/TypeCertificationVG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Tipos de Certificaciones", href: "/asistenciatecnica/Tipos de Certificaciones" },
        ],
    },
});
children.push({
    path: "/asistenciatecnica/VisitasConInsumosNoPermitidos",
    name: "ATS_VISITSNA",
    component: () =>
        import ("../views/TechnicalAssistance/TechnicalVisitSNA/TechnicalVisitSNAVG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Visitas con Insumos no Permitidos", href: "/asistenciatecnica/Visitas con Insumos no Permitidos" },
        ],
    },
});
children.push({
    path: "/asistenciatecnica/MargenesNegociacion",
    name: "ATS_MARGIN",
    component: () =>
        import ("../views/TechnicalAssistance/MarginPrice/MarginPriceVG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Margenes de Negociacion", href: "/asistenciatecnica/Margenes de Negociacion" },
        ],
    },
});
children.push({
    path: "/asistenciatecnica/Plagas",
    name: "ATS_PLAGAS",
    component: () =>
        import ("../views/TechnicalAssistance/Plagues/PlaguesVG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Tipos de Plagas", href: "/asistenciatecnica/Tipos de Plagas" },
        ],
    },
});
children.push({
    path: "/asistenciatecnica/Visitasg",
    name: "TAS_VISIT_G",
    component: () =>
        import ("../views/TechnicalAssistance/Visits/VisitsG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Visitas", href: "/asistenciatecnica/Visitasg" },
        ],
    },
});
children.push({
    path: "/asistenciatecnica/TiposVisitasTecnicas",
    name: "ATS_ASSIGNMATERIALTECHNICALVISIT",
    component: () =>
        import ("../views/TechnicalAssistance/AsignMaterialTechVisit.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Tipos de Visita Técnica", href: "/asistenciatecnica/Tipos de Visita Técnica" },
        ],
    },
});

children.push({
    path: "/asistenciatecnica/ListSupplies",
    name: "ATS_SUPPLIES",
    component: () =>
        import ("../views/TechnicalAssistance/ListSupplies/ListSuppliesVG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Lista de Insumos", href: "/asistenciatecnica/Lista de Insumos" },
        ],
    },
});
children.push({
    path: "/asistenciatecnica/CategorizationPago",
    name: "ATS_CATEGORIZATION",
    component: () =>
        import ("../views/TechnicalAssistance/Categorization/CategorizationPaymentVG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Categorias de Pagos", href: "/asistenciatecnica/Categorias de Pagos" },
        ],
    },
});

children.push({
    path: "/asistenciatecnica/zonas",
    name: "ATS_ZONAS",
    component: () =>
        import ("../views/TechnicalAssistance/Zone/ZoneSubzoneVG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Tecnica", disabled: true },
            { text: "Zonas", href: "/asistenciatecnica/zonas" },
        ],
    },
});

children.push({
    path: "/asistenciatecnica/visitasTecnicas",
    name: "ATS_TECHNICALVISIT",
    component: () =>
        import ("../views/TechnicalAssistance/TechnicalVisit/TechnicalVisitVG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Tecnica", disabled: true },
            { text: "Visitas Tecnicas", href: "/asistenciatecnica/visitasTecnicas" },
        ],
    },
});

//fin codigo edgar
children.push({
    path: "/asistenciatecnica/vehiculo",
    name: "MNG_VEHICLE_AT",
    component: () =>
        /* import ("../views/Management/Vehicle/Vehicle.vue"), */
        import ("../views/TechnicalAssistance/Vehicles/Vehicle.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            {
                text: "Vehículo",
                href: "/asistenciatecnica/vehiculo",
            },
        ],
    },
});


children.push({
    path: "/asistenciatecnica/variedades",
    name: "ATS_VARIETY",
    component: () =>
        import ("../views/TechnicalAssistance/Variety/VarietyVG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Variedades", href: "/asistenciatecnica/variedades" },
        ],
    },
});

//fin codigo edgar

children.push({
    path: "/asistenciatecnica/productor",
    name: "TAS_PRODUCER",
    component: () =>
        import ("../views/TechnicalAssistance/ProducersFarms/Producer.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Productores", href: "/asistenciatecnica/productor" },
        ],
    },
});

children.push({
    path: "/asistenciatecnica/proveedoresaprobados",
    name: "TAS_APPROVED_PROVIDERS",
    component: () =>
        import ("../views/TechnicalAssistance/ProducersFarms/approvedProviders.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Proveedores aprobados", href: "/asistenciatecnica/proveedoresaprobados" },
        ],
    },
});

children.push({
    path: "/asistenciatecnica/certificacion",
    name: "ATS_CERTIFICATION",
    component: () =>
        import ("../views/TechnicalAssistance/Certification.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Certificación", href: "/asistenciatecnica/certificacion" },
        ],
    },
});
children.push({
    path: "/asistenciatecnica/digitador",
    name: "ATS_DIGITADOR",
    component: () =>
        import ("../views/TechnicalAssistance/ProducersFarms/ProducersFarms_G.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Digitador", href: "/asistenciatecnica/digitador" },
        ],
    },
});
children.push({
    path: "/asistenciatecnica/programacioncampana",
    name: "STS_HARVESTSCHEDULE",
    component: () =>
        import ("../views/TechnicalAssistance/HarvestSchedule/HarvestSchedule.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Programación Campaña", href: "/asistenciatecnica/programacioncampana" },
        ],
    },
});
children.push({
    path: "/asistenciatecnica/Asignacionchofervehiculo",
    name: "STS_ASSISTEMENTDRIVERVEHICLE",
    component: () =>
        import ("../views/TechnicalAssistance/AssistementDriverVehicle/AssistementDriverVehicle.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Asignación Chofer Vehículo", href: "/asistenciatecnica/Asignacionchofervehiculo" },
        ],
    },
});
children.push({
    path: "/asistenciatecnica/AsignacionSupervisorCapataz",
    name: "ASF_ASIGNMENTSUPERVISEFOREMAN",
    component: () =>
        import ("../views/TechnicalAssistance/AsignmentSuperviceForeman/AsignmentSuperviceForeman.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Tecnica", disabled: true },
            { text: "Asignación Supervisor Capataz", href: "/asistenciatecnica/AsignacionSupervisorCapataz" },
        ],
    },
});
children.push({
    path: "/carga/SAP",
    name: "SAP_LOAD",
    component: () =>
        import ("../views/TechnicalAssistance/SapLoad.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Carga SAP", href: "/asistenciatecnica/cargasap" },
        ],
    },
});

children.push({
    path: "/asistenciatecnica/asignmentcultivematerial",
    name: "ASF_ASIGNMENTCULTIVEMATERIAL",
    component: () =>
        import ("../views/TechnicalAssistance/CultiveMaterial.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Tecnica", disabled: true },
            { text: "Asignación Cultivo Material", href: "/asistenciatecnica/asignmentcultivematerial" },
        ],
    },
});

children.push({
    path: "/asistenciatecnica/commitmentcarriermaterial",
    name: "AS_COMMITMENTCARRIERMATERIAL",
    component: () =>
        import ("../views/TechnicalAssistance/CommitmentCarrierMaterial/CommitmentCarrierMaterial.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Tecnica", disabled: true },
            { text: "Compromiso Transportista Material", href: "/asistenciatecnica/commitmentcarriermaterial" },
        ]
    }
});


children.push({
    path: "/asistenciatecnica/inputoutputmaterial",
    name: "AS_INPUTOUTPUTMATERIAL",
    component: () =>
        import ("../views/TechnicalAssistance/InputOutputMaterial/InputOutputMaterial.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Ténica", disabled: true },
            { text: "Entrada Salida de Materiales", href: "/asistenciatecnica/" }
        ]
    }
});


children.push({
    path: "/asistenciatecnica/ConfiguracionVehicle",
    name: "CONFIG_VEHICLE",
    component: () =>
        import ("../views/TechnicalAssistance/ConfiguracionVehicle/ConfigVehicle.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Tecnica", disabled: true },
            { text: "Configuración Vehículo", href: "/asistenciatecnica/ConfiguracionVehicle" },
        ],
    },
});
children.push({
    path: "/asistenciatecnica/ConfigurationPrice",
    name: "CONFIG_PRICE",
    component: () =>
        import ("../views/TechnicalAssistance/ConfigurationPrice/ConfigPrice.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Tecnica", disabled: true },
            { text: "Configuración Precios", href: "/asistenciatecnica/ConfigurationPrice" },
        ],
    },
});

children.push({
    path: "/asistenciatecnica/certificationschedule",
    name: "AS_CERTIFICATIONSCHEDULE",
    component: () =>
        import ("../views/TechnicalAssistance/CertificationSchedule/CertificationSchedule.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Tecnica", disabled: true },
            { text: "Cronograma de Certificaciones", href: "/asistenciatecnica/certificationschedule" },
        ],
    },
});
children.push({
    path: "/asistenciatecnica/visitas",
    name: "TAS_VISIT",
    component: () =>
        import ("../views/TechnicalAssistance/Visits/Visit.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Tecnica", disabled: true },
            { text: "Visitas", href: "../views/TechnicalAssistance/Visits/Visit.vue" },
        ],
    },

});

children.push({
    path: "/technicalassistance/recepcionsurveillance",
    name: "ATS_RECEPCTIONSURVEILLANCE",
    component: () =>
        import ("../views/TechnicalAssistance/ReceptionSurveillance/ReceptionSurveillance.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Reception Surveillance", href: "" },
        ],
    },
});

children.push({
    path: "/asistenciatecnica/CrewsAssistance",
    name: "ATS_CrewsAssistance",
    component: () =>
        import ("../views/TechnicalAssistance/CrewsAssistance/CrewsAssistance.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Tecnica", disabled: true },
            { text: "Asistencia Cuadrilla", href: "/asistenciatecnica/CrewsAssistance" },
        ],
    },
});

children.push({
    path: "/asistenciatecnica/ConfigurationKmZon",
    name: "ATS_KM_ZON_TRANSPORT",
    component: () =>
        import ("../views/TechnicalAssistance/TransportConfiguration/TransportZonKm.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Tecnica", disabled: true },
            { text: "Configuracion de Km - Zona", href: "/asistenciatecnica/TransportZonKm" },
        ],
    },
});

children.push({
    path: "/asistenciatecnica/TransportPrice",
    name: "ATS_TRANSPORT_PRICE",
    component: () =>
        import ("../views/TechnicalAssistance/TransportConfiguration/TransportPrice.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Tecnica", disabled: true },
            { text: "Precios", href: "/asistenciatecnica/TransportPrice" },
        ],
    },
});

children.push({
    path: "/asistenciatecnica/TransportPayment",
    name: "ATS_TRANSPORT_PAYMENT",
    component: () =>
        import ("../views/TechnicalAssistance/TransportConfiguration/TransportPayment.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Tecnica", disabled: true },
            { text: "Pagos", href: "/asistenciatecnica/TransportPayment" },
        ],
    },
});


children.push({
    path: "/asistenciatecnica/warehouseentrance",
    name: "ATS_WAREHOUSEENTRANCE",
    component: () =>
        import ("../views/TechnicalAssistance/WarehouseEntrance/Warehouse.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Tecnica", disabled: true },
            { text: "Almacén", href: "/asistenciatecnica/warehouseentrance" },
        ],
    },
});


children.push({
    path: "/asistenciatecnica/warehouse",
    name: "ATS_WAREHOUSES",
    component: () =>
        import ("../views/TechnicalAssistance/Warehouse/Warehouse.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Tecnica", disabled: true },
            { text: "Almacén", href: "/asistenciatecnica/warehouse" },
        ],
    },
});

children.push({
    path: "/asistenciatecnica/ConfigurationCoste",
    name: "ATS_CONFIG_COSTE",
    component: () =>
        import ("../views/TechnicalAssistance/TransportConfiguration/ConfigurationCoste.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Tecnica", disabled: true },
            { text: "Configuracion Costo", href: "/asistenciatecnica/ConfigurationCoste" },
        ],
    },
});

children.push({
    path: "/asistenciatecnica/ProgrammingStorage",
    name: "ATS_PROGRAMMING",
    component: () =>
        import ("../views/TechnicalAssistance/ProgrammingStorage/ProgrammingStorage.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Tecnica", disabled: true },
            { text: "Programacion Acopio", href: "/asistenciatecnica/ProgrammingStorage" },
        ],
    },
});

children.push({
    path: "/asistenciatecnica/ReceptionStorage",
    name: "ATS_RECEPTION_STORAGE",
    component: () =>
        import ("../views/TechnicalAssistance/ProgrammingStorage/ReceptionStorage.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Tecnica", disabled: true },
            { text: "Recepcion Acopio", href: "/asistenciatecnica/ReceptionStorage" },
        ],
    },
});

children.push({
    path: "/asistenciatecnica/AssignLicenseVehicle",
    name: "ATS_AssignLicenseVehicle",
    component: () =>
        import ("../views/TechnicalAssistance/AssignLicenseVehicle/AssignLicenseVehicle.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Tecnica", disabled: true },
            { text: "Asignar Categoria Brevete con Vehiculo", href: "/asistenciatecnica/AssignLicenseVehicle" },
        ],
    },
});
children.push({
    path: "/asistenciatecnica/cultivoarticulo",
    name: "ATS_ASIGNMENTARTICLECULTIVE",
    component: () =>
        import ("../views/TechnicalAssistance/MaintainerCropArticleSap/MaintainerCropArticle.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Cultivo Artículos SAP", href: "/asistenciatecnica/cultivoarticulo" },
        ],
    },
});

children.push({
    path: "/asistenciatecnica/MaintainerSector",
    name: "ATS_MAINTAINERSECTOR",
    component: () =>
        import ("../views/TechnicalAssistance/MaintainerSector/MaintainerSectorSubSector.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Mantenedor Sector", href: "/asistenciatecnica/MaintainerSector" },
        ],
    },
});

children.push({
    path: "/asistenciatecnica/TransportParameters",
    name: "ATS_TRANSPORT_PARAMETERS",
    component: () =>
        import ("../views/TechnicalAssistance/TransportConfiguration/TransportParameters.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Parametros", href: "/asistenciatecnica/TransportParameters" },
        ],
    },
});

children.push({
    path: "/asistenciatecnica/ZonGroup",
    name: "TAS_GROUP_ZON",
    component: () =>
        import ("../views/TechnicalAssistance/ZonGroup/ZonGroup.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Grupo Zonas", href: "/asistenciatecnica/ZonGroup" },
        ],
    },
});

children.push({
    path: "/asistenciatecnica/trucktransportcost",
    name: "TAS_TRUCK_TRANSPORT_COST",
    component: () =>
        import ("../views/TechnicalAssistance/TruckTransportCost/TruckTransportCost.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Costo Transporte Camión", href: "/asistenciatecnica/trucktransportcost" },
        ],
    }
});


children.push({
    path: "/asistenciatecnica/transfertruck",
    name: "TAS_TRANSFER_TRUCK",
    component: () =>
        import ("../views/TechnicalAssistance/TransferTruck/TransferTruck.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Transbordo", href: "/asistenciatecnica/transfertruck" },
        ],
    }
});

children.push({
    path: "/asistenciatecnica/reports",
    name: "TAS_REPORT_TECH_ASS",
    component: () =>
        import ("../views/TechnicalAssistance/Reports/Reports.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Técnica", disabled: true },
            { text: "Reportes", href: "/asistenciatecnica/reports" },
        ],
    }
});

children.push({
    path: "/asistenciatecnica/imprimirformato",
    name: "ATS_RECEPTION_PLANT",
    component: () =>
        import ("../views/TechnicalAssistance/TransportConfiguration/ReceptionPlant.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Asistencia Tecnica", disabled: true },
            { text: "Recepcion de Fruta", href: "/asistenciatecnica/imprimirformato" },
        ],
    },
});

export { children };