import Service from "../Service";

const resource = "parameter/";

export default {
    save(prm, requestID) {
        return Service.post(resource + "save", prm, {
            params: { requestID: requestID },
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    search(obj, requestID) {
        return Service.post(resource + "search", obj, {
            params: { requestID: requestID },
        });
    },
    list(obj, requestID) {
        return Service.post(resource + "list", obj, {
            params: { requestID: requestID },
        });
    },

    searchlist(parameters, requestID) {
        return Service.post(resource + "searchlist", {}, {
            params: { parameters, requestID: requestID },
        });
    }
};