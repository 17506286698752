import Service from "../Service";
import Vue from "vue";
const resource = "Supplier/";

export default {
  save(cmp, requestID) {
    return Service.post(resource + "save", cmp, { params: { requestID: requestID } });
  },
  getTypePurchaseURL() {
    return Vue.prototype.$http.defaults.baseURL + "/purchase/typepurchase";
  },
  getOperationClassPurchaseURL() {
    return Vue.prototype.$http.defaults.baseURL + "/purchase/OperationClassPurchase";
  },
  getPurchasingManagerURL() {
    return Vue.prototype.$http.defaults.baseURL + "/purchase/PurchasingManager";
  },
  pagination(parameters, requestID) {
    return Service.post(resource + "pagination", parameters, { params: { requestID: requestID } });
  },
};
