<template>
   <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">Tiempo de inactividad</v-card-title>

        <v-card-text>
       La  Aplicación tiene {{timeInactive}} minutos de inactividad en {{time/1000}} segundos se cerrara sesión
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Sigo Aquí
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'ModalTimeSession',
 
  components: { },
 methods: {
  
 },
  computed: {
    timeInactive() {
			return (  localStorage.getItem('TimeToken')/1000)/60;
		}
  },
  data: () => ({
 dialog: true,time:20000
  }),
  created(){
     let timerId =  setInterval(() => {
      if (!localStorage.isIdle){this.time=10000; clearInterval(timerId);
      }else  this.time -= 1000;

      if (this.time < 1) {
         document.onload = null; 
         document.onmousemove =null; 
         document.onmousedown = null;  
          document.ontouchstart = null; 
          document.onclick =null;
           document.onscroll = null; 
      localStorage.clear();
       // localStorage.setItem('token','')
       this.$emit('exit')
     this.$router.push("/login")
       clearInterval(timerId);
      }
    }, 1000);
  }
}
</script>
<style scoped>

</style>