<template>
  <div>
      <v-col>
    <v-row>
      <v-col  style="padding-top:5px;padding-bottom:0px;">
        <s-crud
          title="Circuitos de Aprobacion"
          :config="config"
      search-input
          ref="gridAcc" @dblClick="dblClickCircuit($event)"
          :filter="filter"
          @save="saveAcc($event)"
          @rowSelected="rowSelected"
        >
         
          <template v-slot:SecStatus="{ row }">
            <v-icon :color="row.SecStatus == 1 ? 'success' : 'default'">
              mdi-checkbox-blank-circle</v-icon
            >
          </template>
        </s-crud>
      </v-col>
      <v-col lg=5 style="padding-top:5px;padding-bottom:0px;">
            <s-crud v-if="this.filterLevels.AccID!=null"
            :config="configLevels"
            :filter=filterLevels
            title="Niveles"
          >
         
           
          </s-crud>
      </v-col>
    </v-row>
    </v-col>
  </div>
</template>
<script>
import _sApprovalCircuit from "../../../services/Security/ApprovalCircuitPositonService";
import _sApprovalLevel from "@/services/Security/ApprovalLevelsService";
export default {
  components: { },
  props: {
    isCrud: { type: Boolean, default: true },
  },
  data() {
    return {
        filterLevels:{},
         configLevels: {
        model: {
          AccID: "int",
          AlvID: "ID",
          SecStatus: "boleam",
          PstID: "int",
          PstName: "string",
        },
        service: _sApprovalLevel,
        headers: [
          { text: "Cargo", value: "PstName" },
          { text: "Nivel", value: "TypeLevelText" },
        ],
      },
      acc: [],
      filter: {
        SecStatus: 1,PstID:null
      },
      parentID: 0,
    };
  },
  computed: {
    config() {
      return {
        model: {
          AccName: "string",
          AccID: "ID",
          AccBeginDate: "date",
          AccEndDate: "date",
          TypeCircuitText: "string",
          AreName: "string",
          SecStatus: "status",
        },
        service: _sApprovalCircuit,
        headers: this.isCrud
          ? [
              {
                text: "ID",
                value: "AccID",
                align: "end",
              },
              { text: "Circuito", value: "AccName" },
              { text: "Tipo Circuito", value: "TypeCircuitText" },
           
              {
                width: "5%",
                text: "Sec",
                value: "SecStatus",
                sortable: false,
              },
            ]
          : [
              {
                text: "ID",
                value: "AccID",
                align: "end",
              },
              { text: "Circuito", value: "AccName" },
            ],
      };
    },
  },
  watch:{
   
      parentID(){
          this.filterLevels={ AccID: this.parentID };
      }
  },
  methods: {
         dblClickCircuit(item){
          this.$fun.alert('Seguro de seguir con: '+item.AccName,'question').then((r)=>{
             if(r.value){
            this.$emit('dblClick',item)
          }
          });
  
            
      },
    rowSelected(rows) {
      if (rows.length > 0) {
        this.parentID = rows[0].AccID;
      }
    },

    validatedCircuit(item) {
      let isValid = false;
      let message = "";

      isValid = item.AccName.length > 0;
      if (!isValid) message = "Ingrese Nombre de Circuito";
      else {
        isValid = item.AccBeginDate;
        if (!isValid) message = "Ingrese Fecha de Inicio Circuito";
        else {
          isValid = item.AreID;
          if (!isValid) message = "Seleccione Área";
        }
      }

      if (!isValid) this.$fun.alert(message, "warning");

      return isValid;
    },
    saveAcc(item) {
      if (this.validatedCircuit(item)) {
        item.save();
      }
    },
  },
  created(){
   this.filter.PstID=this.$fun.getUserInfo().CtrContract.PstID;
  }
};
</script>
