import Service from "./Service";
const menu = "account/";

export default {
    login(UsrName, UsrPassword) {

        console.log(UsrName)
        localStorage.clear();
        return Service.post(menu + "login", {
            UsrName: UsrName,
            UsrPassword: UsrPassword,
        });
    },
    logout() {
        return Service.post(menu + "logout", {
            UsrName: UsrName,
            UsrPassword: UsrPassword,
        });
    },
    initialize(UsrID, token) {
        return Service.get(menu + "ApplicationUserInitialize", {
            params: { usrID: UsrID },
        });
    },
    userOption(UsrID, optCode) {
        return Service.get(menu + "ApplicationUserOptionAllow", {
            params: { optCode: optCode, UsrID: UsrID, requestID: UsrID },
        });
    },
    changePassword(password, usr) {
        usr.UsrPassword = password;
        return Service.post(menu + "ApplicationChangePassword", usr, {
            params: { requestID: usr.UsrID },
        });
    },
    refreshToken(UsrID) {
        return Service.post(
            menu + "RefreshToken", {}, {
                params: {
                    requestID: UsrID,
                },
            }
        );
    },
};