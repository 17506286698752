import Service from "../Service";

const resource = "approvalcircuit/";

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "paginationbyposition", parameters, {
            params: { requestID: requestID },
        });
    },
};