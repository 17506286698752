<template>
  <div>
    <s-text
      label="Nro. Documento"
      ref="textPerson"
      v-model="prs.PrsDocumentNumber"
      :autofocus="autofocus"
      type="tel"
      @keyupEnter="toggleSaveData(prs.PrsDocumentNumber)"
      @keypress="validation($event)"
    >
      <template v-if="!noCamera" v-slot:prepend-inner>
        <div style="margin-top:4px;">
          <v-icon style="cursor:pointer" @click="dialogCamera = true">
            mdi-camera
          </v-icon>
        </div>
      </template>
    </s-text>

    <!-- MODAL PARA CAMARA -->
    <v-dialog v-if="!noCamera" v-model="dialogCamera" max-width="700">
      <v-card>
        <v-col>
          <qrcode-stream @decode="onDecode" @init="onInit" />
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import Service from "@/services/General/PersonService";

export default {
  name: "sToolbarPerson",
  components: {QrcodeStream },
  props: {
    value: null,
    autofocus: {
      type: Boolean,
      default: true,
    },
    isValidation: {
      type: Number,
      default: 0,
    },
    noCamera: {
      type: Boolean,
      default: false,
    },
  },
  created() {
  },
  watch: {
    value(val) {
      this.prs.PrsID = 0;
      this.prs.PrsDocumentNumber = val;
    },
  },
  methods: {
    onDecode(result) {
      this.prs.PrsDocumentNumber = result;
      var obj = this.prs.PrsDocumentNumber.split(",", 8);
      this.toggleSaveData(obj[0])
      this.dialogCamera = false;
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    },
    clickCamera() {
      this.prs.PrsDocumentNumber = '';
      this.dialogCamera = true;
    },
    validation(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    focus() {
      this.$refs.textPerson.focus();
    },


    toggleSaveData(value) {
      this.person = null;
      if (value == null) return;
      if (value.length > 3) {
        let obj = {
          PrsDocumentNumber: value,
          UsrID: this.$fun.getUserID(),
          IsValidation: this.isValidation,
        };

        Service.personSearch(obj, this.$fun.getUserID()).then((response) => {
          if (response.status == 200) {
            if (response.data != null && response.data.StatusPerson != 1) {
              this.prs = Object.assign(response.data, {});
              this.$emit("returnPerson", this.prs);
            }
          }
        });
      }
    },
  },
  data: () => ({
    dialogCamera: false,
    color: "success",
    prs: { PrsDocumentNumber: ""},
    config: {
      model: {
        PrsID: "ID",
        PrsDocumentNumber: "string",
      },
    },
  }),
  mounted(){
    console.log("doc", this.prs.PrsDocumentNumber)
  }
};
</script>
