<template>
  <div>
    <v-card class="pb-1 pr-1 pl-1 pt-1">
      <s-crud
        height="150"
        save
        :config="configCrud"
        title="Notificaciones"
        :filter="filter"
        @save="saveApproved()"
      >
        <template v-slot:AleStatus="{ row }">
          <v-icon style="font-size:16px;" v-if="(row.AleStatus = 1)">
            far fa-envelope-open</v-icon
          >
          <v-icon v-else> far fa-envelope-open</v-icon>
        </template>
        <template v-slot:AtsStatus="{ row }">
          <s-select-definition :def=1128></s-select-definition>
        </template>
      </s-crud>
    </v-card>

      <v-card class="pb-1 pr-1 pl-1 pt-1">
      <s-crud
        height="150"
        save
        :config="configCrud"
        title="Notificaciones"
        :filter="filter"
        @save="saveApproved()"
      >
        <template v-slot:AleStatus="{ row }">
          <v-icon style="font-size:16px;" v-if="(row.AleStatus = 1)">
            far fa-envelope-open</v-icon
          >
          <v-icon v-else> far fa-envelope-open</v-icon>
        </template>
        <template v-slot:AtsStatus="{ row }">
          <v-chip
            style="margin:0px"
            x-small
            :color="
              row.AtsStatus == 1
                ? 'primary'
                : row.AtsStatus == 3
                ? 'error'
                : 'success'
            "
          >
          </v-chip>
        </template>
      </s-crud>
    </v-card>

    
  </div>
</template>

<script>
import _sAlert from "@/services/Security/AlertService";
export default {
  name: "RequirementWarehouse",
  components: {},
  props: {},
  data: () => ({
    filter: { UsrID: 0, AleStatus: 0 },
    configCrud: {
      model: {
        AleID: "ID",
        AtsDateTransaction: "date",
        AleStatus: "int",
        AtsStatus: "",
      },
      headers: [
        { text: "Notificación", value: "AleName" },
        { text: "Descripción", value: "AleMessage" },
        { text: "Fecha", value: "AtsDateTransaction" },
        { text: "Estado Alerta", value: "AleStatus", align: "center" },
        { text: "Estado Operación", value: "AtsStatus", align: "center" },
      ],
      service: _sAlert,
    },
  }),
  created() {
    this.filter.UsrID = this.$fun.getUserID();
  },
  methods: {
    saveApproved() {
      alert();
    },
  },
};
</script>
