<template>
  <div>
    <s-toolbar
      label="Cotización"
      save
      @save="save()"
      @close="$emit('close')"
      close
    ></s-toolbar>
    <v-card>
      <v-container>
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <s-toolbar
                  label="Datos de Proveedor"
                  @add="addSupplier()"
                  dark
                  color="primary"
                  add
              /></v-col>
            </v-row>
            <v-card class="p-4">
              <v-container>
                <v-row>
                  <v-col class="pb-0">
                    <s-provider
                      add
                      return-object
                      v-model="supObject"
                      label="Proveedor"
                  /></v-col>
                </v-row>
                <v-row>
                  <v-col class="pb-0 pr-0 pt-1"
                    ><s-text label="Contacto" v-model="QuoContactName"
                  /></v-col>
                  <v-col class="pb-0 pt-1" lg="5"
                    ><s-text
                      label="Telefono"
                      number
                      :maxlength="16"
                      v-model="QuoContactTelephone"
                  /></v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-1"
                    ><s-text
                      type="email"
                      label="Email"
                      v-model="QuoContactEmail"
                  /></v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <v-col lg="7">
            <s-toolbar
              label="Proveedores"
              color="primary"
              remove
              dark
              @removed="removeItem()"
            />
            <v-card>
              <v-data-table
                v-model="selected"
                show-select
                :items="itemsSupplier"
                :headers="headersSupplier"
                dense
                hide-default-footer
                item-key="SupCode"
              />
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row
              ><v-col class="pt-0"><s-toolbar label="Detalle" /></v-col
            ></v-row>
            <v-data-table
              :items="items"
              :headers="headers"
              dense
              hide-default-footer
              item-key="DerID"
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import _sQuoteGroup from "@/services/Logistics/QuoteService.js";
export default {
  name: "RequirementService",
  components: {},
  props: {
    items: [],
  },
  data: () => ({
    selected: [],
    supObject: null,
    headers: [
      { text: "Descripción", value: "DerDescription" },
      { text: "Cantidad", value: "DerQuantity" },
      { text: "Unidad", value: "UnitMeasurement" },
    ],
    headersSupplier: [
      { text: "Proveedor", value: "SupName" },
      { text: "Contacto", value: "QuoContactName" },
      { text: "Teléfono", value: "QuoContactTelephone" },
      { text: "Email", value: "QuoContactEmail" },
    ],
    itemsSupplier: [],
    itemsDetail: [],
    QuoContactEmail: "",
    QuoContactTelephone: "",
    QuoContactName: "",
  }),
  methods: {
    clear() {
      this.QuoContactEmail = "";
      this.QuoContactTelephone = "";
      this.QuoContactName = "";
      this.supObject = null;
    },
    removeItem() {
      this.selected.forEach((element) => {
        this.itemsSupplier = this.itemsSupplier.filter(
          (x) => x.SupCode != element.SupCode
        );
      });
    },
    save() {
      if (this.itemsSupplier.length < 1) {
        this.$fun.alert("Ingrese al menos un Proveedor", "warning");
        return;
      }
      this.$fun
        .alert("Seguro de generar solicitudes?", "question")
        .then((r) => {
          this.itemsSupplier.forEach((element) => {
            element.details = this.items;
            (element.UsrCreateID = this.$fun.getUserID()),
              (element.QuoContactName = element.QuoContactName.toUpperCase());
          });
          _sQuoteGroup
            .save(
              {
                Quotes: this.itemsSupplier,
                GrqObservation: "",
                UsrCreateID: this.$fun.getUserID(),
              },
              this.$fun.getUserID()
            )
            .then((r) => {
              this.items.map((x) => {
                x.DerStatus = 6;
                return;
              });
              this.$fun.downloadFile(
                r.data,
                this.$const.TypeFile.ZIP,
                "cotizacion"
              );
              this.$emit("close");
            });
        });
    },
    validationFormSupplier() {
      if (this.supObject == null) {
        this.$fun.alert("Seleccione Proveedor", "warning");
        return false;
      }
      if (this.QuoContactName.trim().length < 2) {
        this.$fun.alert("Complete Nombre de Contacto", "warning");
        return false;
      }
      if (this.QuoContactTelephone.trim().length < 2) {
        this.$fun.alert("Complete Teléfono de Contacto", "warning");
        return false;
      }
      if (this.QuoContactEmail.trim().length < 2) {
        this.$fun.alert("Complete Email de Contacto", "warning");
        return false;
      }
      if (
        this.itemsSupplier.filter((x) => x.SupCode == this.supObject.SupID)
          .length > 0
      ) {
        this.$fun.alert("Ya existe Proveedor", "warning");
        return false;
      }
      return true;
    },
    addSupplier() {
      if (this.validationFormSupplier()) {
        this.itemsSupplier.push({
          SupName: this.supObject.SupName,
          SupCode: this.supObject.SupID,
          QuoContactName: this.QuoContactName,
          QuoContactTelephone: this.QuoContactTelephone,
          QuoContactEmail: this.QuoContactEmail,
          UsrCreateID: this.$fun.getUserID(),
        });
        this.clear();
      }
    },
  },
  watch: {
    supObject() {
      console.log(this.supObject);
    },
  },
};
</script>
