import Service from "../Service";
import Vue from "vue";
const resource = "Quote/";

export default {
    save(cmp, requestID) {
        return Service.post(resource + "save", cmp, { 
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID }, });
    },
    pagination(dtr, requestID) {
        return Service.post(
            resource + "Pagination",
            dtr, {
                params: { requestID: requestID },
            }
        );
    },
    PDF(dtr, requestID) {
        return Service.post(resource + "quotepdf", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },
};