<template>
  <div style="padding-top: -2px; margin-top: -2px">
    <s-select-generic
      ref="sarea"
      autocomplete
      class="text"
      itemtext="GenDescription"
      itemvalue="GenID"
      :disabled="disabled"
      id="GenDescription"
      :value="value"
      :return-object="returnObject"
      @input="input($event)"
      :config="config"
      :label="label"
      :full="full"
      :autofocus="autofocus"
      :text="text"
      :clearable="clearable"
      :noDefault="noDefault"
      @change="change($event)"
    >
    </s-select-generic>
  </div>
</template>

<script>
import _sGenericService from "./../../services/General/GenericService";
export default {
  name: "SelectArea",
  components: {},
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    value: null,
    text: { type: String, default: "" },
    label: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    centercost: {
      type: Boolean,
      default: false,
    },
    noDefault: {
      type: Boolean,
      default: false,
    },
    typeCost: {
      type: Number,
      default: 0,
    },
    typeArea: {
      type: Number,
      default: 3,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    typeArea() {
      this.config.params.TypeArea = this.typeArea;
    },
    typeCost() {
      this.config.params.TypeCost = this.typeCost;
    },
    value(value) {
      this.item = value;
    },
  },
  methods: {
    change(elements)
    {
      if (this.centercost) 
      {
        console.log('elemeneeeee ', elements);
      }
    },

    focus() {
      this.$refs.sarea.focus();
    },
    input(val) {
      this.$emit("input", val);
    },
  },
  data: () => ({
    item: 0,
    val: "",
    items: [],
    config: {},
  }),

  created() {
    this.config = {
      url: _sGenericService.getUrlSearchArea(),
      title: this.label,
      params: {
        TypeArea: this.typeArea,
        search: "",
        requestID: this.$fun.getUserID(),
      },
    };
    if (this.centercost) {
      this.config.url = _sGenericService.getUrlSearchCenterCost();
      this.config.params = {
        search: "",
        TypeCost: this.typeCost,
        requestID: this.$fun.getUserID(),
      };
    }
  },
};
</script>
