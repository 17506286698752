<template>
    <div style="padding-top:-2px;margin-top:-2px;">
      <v-label ><b>{{label}}</b></v-label>
      <s-select-generic style=""
        
        :config="config"
        :disabled="disabled"
        :readonly="readonly" 
        itemvalue="GenID"
        id="GenID" itemtext="GenDescription"
        :clearable=clearable
        @add="addEvent()"
        
        :value="value"
        @input="input($event)"
        :returnObject="returnObject"
      /></div>
  </template>
  
  <script>
  import _sGenericService from "../../services/General/GenericService";
  
  export default {
    components: {  },
    props: {
      id: {
        type: String,
        default: "GenID",
      },
      value: null,
      label: {
        type: String,
        default: "",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      noDefault: {
        type: Boolean,
        default: false,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      returnObject: {
        type: Boolean,
        default: false,
      },
      typeArea: {
        type: String,
        default: "0",
      },
      typeCultive: {
        type: String,
        default: "0",
      },
    },
    data: () => ({
      valueRadio: null,
      selected: [],
  
      dialogAdd: false,
      item: 0,
      items: [],
      config: {},
    }),
  
    watch: {
       value(){
        console.log("valuesds  2", this.value) ;
       }

    },
    methods: {
      input(item) {
        this.$emit("input", item);
      },
    },
    created() {
      // this.config.title = this.label;
      // this.config.params.requestID = this.$fun.getUserID();
  
      this.config = {
        url: _sGenericService.getUrlSearchCampaignArea(),
        title: this.label,
        params: { 
            requestID: this.$fun.getUserID(), 
            typeArea: this.typeArea,
            typeCultive: this.typeCultive
        },
      }
    },
    mounted(){
      console.log("valuesds", this.value) ;
    }
  };
  </script>
  