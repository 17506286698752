<template>
  <div style="padding-top:-2px;margin-top:-2px;">
     <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
      <v-card color="primary" dark>
        <v-card-text v-if="messageProcessing.length == 0">
          Por favor espere
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
        <v-card-text v-else>
          <div class="text--white pt-4">
            Error al realizar operacion <br />
            {{ messageProcessing }}
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    message: {
      type:String,
      default: null,
    },
   value:null
  },
  data() {
    return {
    processing:false,
    messageProcessing:'',
    };
  },
  watch: {
    value(){
      this.processing=this.value;
    },
    processing(){this.$emit('input',this.processing);},
  message(){
    this.messageProcessing=this.message
  }
  },
  methods: {
    
  },
  created(){},
  mounted(){this.messageProcessing='';},
};
</script>
