<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
      persistent
    >
      <v-card>
        <s-toolbar  
            color="primary" 
            
            @save="save"
            dark
            close
            @close="close"
            label="Requisitos"
        >
        </s-toolbar>

        <v-card-text>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="dataReceived"
            :single-select="singleSelect"
            item-key="CrqID"
            show-select
            class="elevation-1"
            @item-selected="itemSelected"
            
          >
          </v-data-table>

          <!-- <template v-slot:CprMeets="{ row }">
                <v-btn icon color="primary" v-if="row.CprMeets == 0" x-small  @click="showModal">
                    <v-icon>fas fa-eye</v-icon>
                </v-btn>
            </template> -->

        </v-card-text>

        <v-divider></v-divider>

        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="hideModal"
          >
            Cerrar
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _sCertificationRequirementService from "@/services/Technicalassistance/CertificationRequirementService";
import _sCertificationProducerRequirementService from "@/services/Technicalassistance/CertificationProducerRequirementService";

  export default {
    props: {
      dialog: {
        type: Boolean,
        default: false
      },
      CsID: {
        type: Number,
        default: 0
      },
      CpID: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        rowSel: {},
        singleSelect: false,
        selected: [],
        headers: [
          { text: 'ID', align: 'start', sortable: false, value: 'CrqID' },
          { text: 'Requisito', value: 'Requirement' },
          { text: 'Observación', value: 'CprObservation' },
        ],

        /* dialog: true, */
        filter: {},
        dataReceived: [],
        requirementProducer: [],
        arrayRequiremetFulfilled: [],
      }
    },
    methods: { 
      close()
      {
        this.hideModal();
      },

      itemSelected(item){
        if(item.value)
        {
          item.CpID = this.CpID;
          item.CprMeets = 1;
          item.SecStatus = 1;
          item.UsrCreateID = this.$fun.getUserID();
          item.CrqID = item.item.CrqID;
          /* this.selected.forEach((element) => {
            element.CpID = this.CpID;
            element.CprObservation = '';
            element.CprMeets = 1;
            element.SecStatus = 1;
            element.UsrCreateID = this.$fun.getUserID();
            if(!element.CprID){
              element.CprID = 0;
            }
          }); */
        }else{
          item.CpID = this.CpID;
          item.CprMeets = 0;
          item.SecStatus = 0;
          item.UsrCreateID = this.$fun.getUserID();
          item.CrqID = item.item.CrqID;
          /* this.selected.forEach((element) => {
            element.CpID = this.CpID;
            element.CprObservation = '';
            element.CprMeets = 0;
            element.SecStatus = 0;
            element.UsrCreateID = this.$fun.getUserID();
            if(!element.CprID){
              element.CprID = 0;
            }
          }); */
        }
        
        console.log(this.selected);
        _sCertificationProducerRequirementService
                .save(item, this.$fun.getUserID())
                .then((resp) => {
                    this.$fun.alert("Guardado Correctamente", "success");
                    this.$emit("recharge");
                })
      },
      Initialize() {
        this.filter = {CsID: this.CsID};
      },
      hideModal() {
        this.$emit('hideModal');
      },
      rowSelected(item)
      {
        console.log(this.selected);
        if (item.length > 0) {
          this.fillRequirements(item);
          /* this.arrayRequiremetFulfilled[item[0].CprID] = item[0];
          console.log('this.arrayRequiremetFulfilled', this.arrayRequiremetFulfilled) */
          /* if(item.CprMeets == 0){
            item.CprMeets = 1
          }else{
            item.CprMeets = 0;
          } */
        }
        /* console.log('itemrow', item[0])
        console.log('array', this.arrayRequiremetFulfilled) */
      },

      fillRequirements(item)
      {
        this.arrayRequiremetFulfilled = [];
        item.forEach((element) => {
          this.arrayRequiremetFulfilled.push(element);
        });
        console.log('tag', this.arrayRequiremetFulfilled)
      },

      loadDataInitial()
      {
        _sCertificationRequirementService
        .list(this.CsID, this.$fun.getUserID())
        .then((resp) => {
          console.log('res', resp.data)
          this.dataReceived = resp.data;
        })
      },

      save(){
        //console.log('this.selected, antes de procesar', this.selected)
        this.selected.forEach((element) => {
          element.CpID = this.CpID;
          element.CprObservation = '';
          element.CprMeets = 1;
          element.SecStatus = 1;
          element.UsrCreateID = this.$fun.getUserID();
          if(!element.CprID){
            element.CprID = 0;
          }
        });
        console.log('this.selected', this.selected)
        this.$fun.alert("Seguro de guardar", "question").then((r) => {
            if(r.value){
                _sCertificationProducerRequirementService
                .save(this.selected, this.$fun.getUserID())
                .then((resp) => {
                    this.$fun.alert("Guardado Correctamente", "success");
                    this.$emit("recharge");
                    //this.dialog = false;
                    //this.$refs.crudMaterialGuiaDetail.refresh();
                })
            }
        });
      },
    },
    watch: {
      CsID() {
        this.Initialize();
        this.loadDataInitial();
      },
      CpID(){
        _sCertificationProducerRequirementService
        .list(this.CpID, this.$fun.getUserID())
        .then((resp2) => {
          this.requirementProducer = resp2.data;
          this.selected = this.requirementProducer;
          console.log("this.selected", this.selected);
        });
        
      },

      /* selected(){
        console.log(this.rowSel);
      } */
    },
    created() {
      this.filter = {CsID: this.CsID};
    },
  }
</script>
