let children = [];
children.push({
    path: "/comedor/menus",
    name: "DGH_MENU",
    component: () =>
        import ("../views/DinningHall/MenuType.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Comedor", disabled: true },
            { text: "Menús", href: "/comedor/menus" },
        ],
    },
});

children.push({
    path: "/comedor/reportedetallado",
    name: "DGH_REPORTDETAIL",
    component: () =>
        import ("../views/DinningHall/MealConsumptionDetail.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Comedor", disabled: true },
            { text: "Menús", href: "/comedor/reportedetallado" },
        ],
    },
});
children.push({
    path: "/comedor/regularizacion",
    name: "DGH_REGULARIZATION_MASIVE",
    component: () =>
        import ("../views/DinningHall/Regularization.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Comedor", disabled: true },
            { text: "Regularizacion Masiva", href: "/comedor/regularizacion" },
        ],
    },
});
children.push({
    path: "/comedor/platos",
    name: "DGH_MEAL",
    component: () =>
        import ("../views/DinningHall/MealType.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Comedor", disabled: true },
            { text: "Platos", href: "/comedor/platos" },
        ],
    },
});
children.push({
    path: "/comedor/programacion",
    name: "DGH_MENU_DAILY",
    component: () =>
        import ("../views/DinningHall/MenuDaily.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Comedor", disabled: true },
            { text: "Programación", href: "/comedor/programacion" },
        ],
    },
});
children.push({
    path: "/comedor/aprobaciones",
    name: "DGH_APPROVAL",
    component: () =>
        import ("../views/DinningHall/ValidityDaily.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Comedor", disabled: true },
            { text: "Aprobación de Menú", href: "/comedor/aprobaciones" },
        ],
    },
});
children.push({
    path: "/comedor/reporteconsumos",
    name: "DGH_REPORT_CONSUMPTION",
    component: () =>
        import ("../views/DinningHall/MealConsumptionReport.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Comedor", disabled: true },
            { text: "Reporte de Consumos", href: "/comedor/reporteConsumos" },
        ],
    },
});
children.push({
    path: "/comedor/reporteconsumoscostcenter",
    name: "DGH_REPORT_CENTERCOST",
    component: () =>
        import ("../views/DinningHall/MealConsumptionCostCenter.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Comedor", disabled: true },
            {
                text: "Reporte de Centro de Costo",
                href: "/comedor/reporteCostcenter",
            },
        ],
    },
});
children.push({
    path: "/comedor/asignacion",
    name: "DGH_ASSING",
    component: () =>
        import ("../views/DinningHall/AssingMenu"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Comedor", disabled: true },
            { text: "Asignación de Menú", href: "/comedor/asignacion" },
        ],
    },
});
children.push({
    path: "/comedor/reservaadmin",
    name: "DGH_RESERVE_ADMIN",
    component: () =>
        import ("../views/DinningHall/ReserveAdmin"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Comedor", disabled: true },
            { text: "Reserva de Administrador", href: "/comedor/reservaadmin" },
        ],
    },
});
children.push({
    path: "/comedor/asignaciondesayuno",
    name: "DGH_ASSING_BREAKFAST",
    component: () =>
        import ("../views/DinningHall/AssingBreakfast"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Comedor", disabled: true },
            { text: "Asignación de Desayuno", href: "/comedor/asignaciondesayuno" },
        ],
    },
});
export { children }