<template>
  <div style="padding-top:-2px;margin-top:-2px;">
    <v-label ><b>Área</b></v-label>
    <v-autocomplete
      :return-object="returnObject"
      :item-value="itemvalue"
      :item-text="itemtext"
      :disabled="disabled"
      :readonly="readonly"
      :items="items"
      :clearable="clearable"
      dense
      outlined
      :value="value"
      @input="input($event)"
      hide-details="false"
    />
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: null,
    itemtext: {
      type: String,
      default: "AreName",
    },
    itemvalue: {
      type: String,
      default: "AreID",
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {},
  created() {
    this.load();
  },
  watch: {},
  data() {
    return {
      items: [],
    };
  },
  methods: {
    input(value) {
      this.$emit("input", value);
    },
    load() {
      this.items = this.$fun.getSecurity().AreasAllow;
    },
  },
};
</script>
