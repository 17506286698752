<template>
    <div style="padding-top:-2px;margin-top:-2px">
        <v-label>{{ label }}</v-label>
        <s-select-generic
            ref='sgeneric'
            :autocomplete="autocomplete"
            class="text"
            itemvalue="TehID"
            :disabled="disabled"
            :value="value"
            id="" itemtext=""
            :return-object="returnObject"
            @input="input($event)"
            @change="change($event)"
            :config="config"
            :full="full"
            :text="text"
            :clearable="clearable"
            :noDefault="clearable">

        </s-select-generic>    
    </div>
</template>

<script>

import _sService from "../../services/FrozenProduction/TemperatureHoursEthyleneService.js";

export default {
    components: {},
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        autocomplete:{ 
            type: Boolean,
            default: false,
        },
        value: null,
        text: { type: String, default: null },
        label: {
            type: String,
            default: "",
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        noDefault: {
            type: Boolean,
            default: false,
        },    
        full: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        value(value){
            this.item = value;
        },
    },
    methods:{
        input(val){
            this.emit("input", val);
        },
        change(items){
            this.$emit("change", items);
        },
    },
    data:()=> ({
        item:0,
        val: "",
        items:[],
        config: {},
    }),

    created(){
        this.config = {
            url: _sService.getUrlTypeTemperatureList(),
            title: this.label,
            params: { search: '', requestID: this.$fun.getUserID()},
        };
    },

    getUrlTypeTemperatureList(){
        return Vue.prototype.$http.defaults.baseUrl + "/certifications/typetemperaturelist";
    }


};
</script>
