<template>
  <v-app class="app scroll">
    <router-view></router-view>
  </v-app>
</template>
<script>
export default {
  name: "App",
  data: () => ({ timeInactiveShow: 0 }),
  created() {},

  mounted() {
    /*this.$nextTick().then(()=>{
  
require("signalr");

           //AGREGA SCRIPT DE HUBS A LA CABERA DE LA APLICACION
    let labelScript = document.createElement("script");
    labelScript.setAttribute("src", "http://localhost:63130/signalr/hubs");
    document.head.appendChild(labelScript);
    ////EVENTO CUANDO CARGA EL SCRIP DE SIGNALR
    labelScript.addEventListener("load", () => {
 


});
        
   


    })*/

      //INICIA LA CONECCION DEL HUB
  /*   
*/
  },
  methods: {},
};
</script>
<style scoped>
@import "./styles/app.css";
</style>
