
<template>
	<div>
		<v-card>
			<v-container>
				<v-row>
					<h2
						class="mt-6 ml-6"
					>
						Flujo de camiones
					</h2>
					<v-spacer></v-spacer>
					<v-btn
						class="mt-3 mr-3"
						small
						fab
						color="success"
						v-if="items.length > 0"
						@click="downloadExcel()"
					>
						<i 
							style="font-size:20px;" color="error" 
							class="fas fa-file-excel"
						></i>
					</v-btn>
					<v-btn
						class="mt-3 mr-3"
						small
						fab
						color="error"
						v-if="items.length > 0"
						@click="downloadPdf()"
					>
						<i 
							style="font-size:16px;" color="error" 
							class="fas fa-file-pdf"
						></i>
					</v-btn>
				</v-row>
				<v-row>
					<v-col>
						<s-text
							label="N° Guía"
							v-model="guia"
							@keyupEnter="keyupEnter()"
						></s-text>
					</v-col>
					<v-col>
						<s-date
							label="Fecha Inicio"
							v-model="filter.DateBegin"
							@enter="keyupEnter()"
						></s-date>
					</v-col>
					<v-col>
						<s-date
							label="Fecha Fin"
							v-model="filter.DateEnd"
						></s-date>
					</v-col>
					<v-col>
						<s-select-definition
							label="Turno"
							:def="1278"
							v-model="filter.TypeTurn"
						></s-select-definition>
					</v-col>
					<v-col>
						<v-btn
							color="info" 
							fab	
							@click="keyupEnter()"
						>
							<i
								class="fas fa-search"
							></i>
						</v-btn>
					</v-col>
				</v-row>

				<v-container v-for="item in items" :key="item.RcfNumberReferralGuide"> 
					<v-row>
						<v-col>
							{{item.Num_Guia}}
							<v-divider></v-divider>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="6" class="pt-0">
							<v-list>
								<v-list-item>
									<v-list-item-avatar>
										<v-btn
											color="info" 
											fab					
										>
											<i 
												class="fas fa-clock" 
												style="font-size:18px"
											>
											</i>
										</v-btn>
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title>
											Hora Inicio Descarga
										</v-list-item-title>
										<v-list-item-subtitle>
											{{item.Hora_Inicio_Descarga}}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>

								<v-list-item>
									<v-list-item-avatar>
										<v-btn
											color="info" 
											fab					
										>
											<i 
												class="fas fa-clock" 
												style="font-size:18px"
											>
											</i>
										</v-btn>
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title>
											Hora Fin Descarga
										</v-list-item-title>
										<v-list-item-subtitle>
											{{item.Hora_Fin_Descarga == null ? '-' : item.Hora_Fin_Descarga}}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>

								<v-list-item>
									<v-list-item-avatar>
										<v-btn
											color="warning" 
											fab					
										>
											<i 
												class="fas fa-user-clock" 
												style="font-size:18px"
											>
											</i>
										</v-btn>
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title>
											Cantidad de Personas
										</v-list-item-title>
										<v-list-item-subtitle>
											{{item.Cantidad_Trabajadores}}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>

								<v-list-item>
									<v-list-item-avatar>
										<v-btn
											color="success" 
											fab					
										>
											<i 
												class="fas fa-box" 
												style="font-size:18px"
											>
											</i>
										</v-btn>
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title>
											Jabas descargadas
										</v-list-item-title>
										<v-list-item-subtitle>
											{{item.Cantidad_Jabas}}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>

								<v-list-item>
									<v-list-item-avatar>
										<v-btn
											color="warning" 
											fab>
											<i 
												class="fas fa-users"
												style="font-size:18px">
											</i>
										</v-btn>
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title>
											Cuadrilla
										</v-list-item-title>
										<v-list-item-subtitle>
											{{item.Cuadrilla}}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
								
							</v-list>
						</v-col>

						<v-col cols="6" class="pt-0">
							<v-list-item>
								<v-list-item-avatar>
									<v-btn
										color="info" 
										fab					
									>
										<i 
											class="fas fa-truck"
											style="font-size:18px"
										>
										</i>
									</v-btn>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>
										Placa camión
									</v-list-item-title>
									<v-list-item-subtitle>
										{{item.Placa}}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item>
								<v-list-item-avatar>
									<v-btn
										color="success" 
										fab					
									>
										<i 
											class="fas fa-door-open"
											style="font-size:18px"
										>
										</i>
									</v-btn>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>
										Puerta descarga
									</v-list-item-title>
									<v-list-item-subtitle>
										{{item.Puerta_Descarga}}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item>
								<v-list-item-avatar>
									<v-btn
										color="warning" 
										fab					
									>
										<i 
											class="fas fa-weight"
											style="font-size:18px"
										>
										</i>
									</v-btn>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>
										Kilos Descarga
									</v-list-item-title>
									<v-list-item-subtitle>
										{{item.Kilos_Descargados}}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item>
								<v-list-item-avatar>
									<v-btn
										color="error" 
										fab					
									>
										<i 
											class="fas fa-people-carry"
											style="font-size:18px"
										>
										</i>
									</v-btn>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>
										Kilos / Hombre
									</v-list-item-title>
									<v-list-item-subtitle>
										{{item.Kilos_Hombre}}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item>
								<v-list-item-avatar>
									<v-btn
										color="primary" 
										fab					
									>
										<i 
											class="fas fa-cube"
											style="font-size:18px"
										>
										</i>
									</v-btn>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>
										Muñeco
									</v-list-item-title>
									<v-list-item-subtitle>
										{{item.TypeDollName}}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-col>
					</v-row>
				</v-container>
			</v-container>
		</v-card>
	</div>
</template>

<script>
	import sReceptionFreshUnloadService from '@/services/FreshProduction/ReceptionFreshUnloadService.js';

	export default {
		data() {
			return {
				guia: "",
				filter:{},
				items: []
			}
		},
		
		methods: {
			keyupEnter() {
				this.filter.guia = this.guia;
				console.log('filter ', this.filter);
				sReceptionFreshUnloadService
				.list(this.filter, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.items = resp.data;
						console.log('items ', this.items);
					}
				})
			},

			downloadExcel(){
				this.$fun.alert("¿Seguro de descargar?", "question")
				.then(r => {
					if(r.value){
						sReceptionFreshUnloadService
						.convertexcel(this.items, this.$fun.getUserID())
						.then(resp => {
							this.$fun.downloadFile(resp.data, this.$const.TypeFile.EXCEL, this.$fun.getUserID());
						})
					}
				})
			},

			downloadPdf(){
				this.$fun.alert("¿Seguro de descargar?", "question")
				.then(r => {
					if(r.value){
						sReceptionFreshUnloadService
						.converterpdf(this.filter, this.$fun.getUserID())
						.then(resp => {
							this.$fun.downloadFile(resp.data, this.$const.TypeFile.PDF, this.$fun.getUserID());
						})
					}
				})
			}
		},
	}
</script>