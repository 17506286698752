<template>
  <v-card>
    <v-card-text>
      <v-container grid-list-xs class="pa-0">
        <v-row>
          <v-col>
            <v-text-field
              v-model="uss.UssUserCode"
              label="Usuario"
              prepend-icon="mdi mdi-account-outline"
              hide-details=""
              :disabled="show || uss.UssID > 0"
              ref="usercode"
            />
            <v-text-field
              v-show="!show"
              v-model="uss.UssPassword"
              prepend-icon="mdi mdi-lock-outline"
              :append-icon="visiblePassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :type="visiblePassword ? 'text' : 'password'"
              label="Contraseña"
              @click:append="visiblePassword = !visiblePassword"
              hide-details=""
              autofocus
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions v-if="show && !uss.UssID > 0" class="justify-center">
      <v-btn v-if="show" block small class="white--text" color="primary" @click="edit">
        Editar
      </v-btn>
    </v-card-actions>
    <v-card-actions v-else-if="show && uss.UssID > 0">
      <v-container>
        <v-row>
          <v-col cols="6" class="my-0 py-0">
            <v-btn small block class="white--text" color="primary" @click="edit">
              Editar
            </v-btn>
          </v-col>
          <v-col cols="6" class="my-0 py-0">
            <v-btn left block small text color="error" @click="deleteUser()" :loading="loadingDelete">
              Borrar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
    <v-card-actions v-else>
      <v-container grid-list-xs>
        <v-row>
          <v-col cols="6" class="my-0 py-0">
            <v-btn block small class="white--text" color="primary" @click="insert" :loading="loadingInsert">
              Guardar
            </v-btn>
          </v-col>
          <v-col cols="6" class="my-0 py-0">
            <v-btn left block small text @click="clear">
              Atras
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>
<script>
import _sUserSAP from "@/services/Security/UserSAPService.js";

export default {
  props: {
    activate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    method: "",
    visiblePassword: false,
    loadingInsert: false,
    loadingDelete: false,
    uss: {},
  }),
  mounted() {
    this.method = "show";
    this.uss = this.$fun.getUserSAPInfo() || {};
  },
  computed: {
    show() {
      return this.method == "show";
    },
  },
  methods: {
    edit() {
      if (!this.uss.UssID > 0) this.$refs.usercode.focus();

      this.method = "edit";
    },
    validate() {
      var isValid = this.uss.UssUserCode.length > 0;
      var message;
      if (!isValid) message = "Complete Codigo de Usuario SAP";
      else {
        isValid = this.uss.UssPassword.length > 0;
        if (!isValid) message = "Complete Contraseña";
      }
      if (!isValid) this.$fun.alert(message, "warning");

      return isValid;
    },
    clear() {
      this.uss = this.$fun.getUserSAPInfo() || {};
      this.method = "show";
    },
    insert() {
      this.loadingInsert = true;

      if (this.validate()) {
        this.uss.SecStatus = 1;
        this.uss.UsrID = this.$fun.getUserID();
        _sUserSAP.save(this.uss, this.$fun.getUserID()).then(
          (response) => {
            this.uss.UssID = response.data.UssID;
            localStorage.setItem("UserSAPInfo", JSON.stringify(this.uss));
            this.$emit("input", this.uss);
            this.method = "show";
            this.$fun.alert("Registros actualizados correctamente", "success");
          },
          (e) => {
            this.loadingInsert = false;
          }
        );
      } else this.loadingInsert = false;
    },
    deleteUser() {
      this.loadingDelete = true;

      this.uss.SecStatus = 0;

      this.$fun.alert("Desea desasociar el usuario " + this.uss.UssUserCode + " de SunExpert", "question").then((r) => {
        _sUserSAP.save(this.uss, this.$fun.getUserID()).then(
          (response) => {
            this.uss = {};
            localStorage.setItem("UserSAPInfo", JSON.stringify({}));
            this.$emit("input", {});
            this.method = "show";
            this.$fun.alert("Registros actualizados correctamente", "success");
          },
          (e) => {
            this.loadingDelete = false;
          }
        );
      });
    },
  },
  watch: {
    activate() {
      this.uss = this.$fun.getUserSAPInfo() || {};
      this.method = "show";
    },
  },
};
</script>
