import Service from "../../Service";

const resource = "article/";

export default {
    save(cmp, requestID) {
        return Service.post(resource + "save", cmp, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    excel(dtr, requestID) {
        console.log(dtr);
        return Service.post(resource + "excel", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },
};