let children = [];

children.push({
    path: "/produccioncongelados/TamanosdeSeleccion",
    name: "FRZ_ZOOMSELECTION",
    component: () =>
        import ("../views/FrozenProduction/ZoomSelection/ZoomSelectionVG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Tamaños de Seleccion", href: "/produccioncongelados/TamanosdeSeleccion" },
        ],
    }
});



children.push({
    path: "/produccioncongelados/Tuneles_maduracion",
    name: "FRZ_TUNNELMADURATION",
    component: () =>
        import ("../views/FrozenProduction/FrzTunnel/FrzTunnel.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Túneles", href: "/produccioncongelados/Tuneles_maduracion" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/recepcion_cosecha",
    name: "FRZ_HARVESTSRECEPCTION",
    component: () =>
        import ("../views/FrozenProduction/Reception/HarvestReception.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Recepcion Cosecha", href: "/produccioncongelados/recepcion_cosecha" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/recepcion_acopio",
    name: "FRZ_ACCUMULATIONSRECEPCTION",
    component: () =>
        import ("../views/FrozenProduction/Reception/AccumulationReception.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Recepcion Acopio", href: "/produccioncongelados/recepcion_acopio" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/seleccion",
    name: "FRZ_ SUPPLY_CALIBRATOR",
    component: () =>
        import ("../views/FrozenProduction/FrzSelectionCalibrated/FrzSupplyCalibrator.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Seleccion", href: "/produccioncongelados/seleccion" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/armado_parihuela_lote",
    name: "FRZ_CALIBRATED_PRODUCTION_LOT",
    component: () =>
        import ("../views/FrozenProduction/FrzSelectionCalibrated/FrzSupplyPallet.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Selección", disabled: true },
            { text: "Armado Parihuela", href: "/produccioncongelados/armado_parihuela_lote" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/ingresartunelmain",
    name: "FRZ_ENTER_TUNNEL",
    component: () =>
        import ("../views/FrozenProduction/FrzMaduration/FrzEnterTunnel/EnterTunelMain.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Ingresar Túnel", href: "/produccioncongelados/ingresartunelmain" },
        ],
    }
});

// vista stock de todos los tuneles y camaras
children.push({
    path: "/produccioncongelados/stock",
    name: "FRZ_MADURATION_STOCK",
    component: () =>
        import ("../views/FrozenProduction/FrzMaduration/Stock.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Stock de maduración", href: "/produccioncongelados/stock" },
        ],
    }
});

// vista de reportes de maduración 

children.push({
    path: "/produccioncongelados/reporte",
    name: "FRZ_MADURATION_REPORT",
    component: () =>
        import ("../views/FrozenProduction/FrzMaduration/Report.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "reportes", href: "/produccioncongelados/reporte" },
        ],
    }
});

// imprimir QR en recepcion 
children.push({
    path: "/produccioncongelados/impresion_qr_recepcion",
    name: "FRZ_PRINT_QR_RECEPTION",
    component: () =>
        import ("../views/FrozenProduction/Reception/PrintQrReception.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Impresion QR recepcion", href: "/produccioncongelados/impresion_qr_recepcion" },
        ],
    }
});

// pesado y muestreado despues de tunel
children.push({
    path: "/produccioncongelados/pesado_muestreo",
    name: "FRZ_WEIGHING_SAMPLING_TUNNEL",
    component: () =>
        import ("../views/FrozenProduction/FrzMaduration/WeighingSampling.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Pesado y muestreo", href: "/produccioncongelados/pesado_muestreo" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/impresion_qr",
    name: "FRZ_PRINT_QR",
    component: () =>
        import ("../views/FrozenProduction/PrintQR/PrintQR.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Impresion QR", href: "/produccioncongelados/impresion_qr" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/camaras_enfriamiento",
    name: "FRZ_COOLING_CHAMBER",
    component: () =>
        import ("../views/FrozenProduction/CoolingChamber/CoolingChamber.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Camaras de Enfriamiento", href: "/produccioncongelados/camaras_enfriamiento" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/ingresar_camara_enfriamiento",
    name: "FRZ_ENTER_CHAMBER",
    component: () =>
        import ("../views/FrozenProduction/EnterChamber/EnterChamber.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Ingresar Cámara", href: "/produccioncongelados/ingresar_camara_enfriamiento" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/ingreso_ambiente",
    name: "FRZ_ENTER_AMBIENT",
    component: () =>
        import ("../views/FrozenProduction/EnterAmbient/EnterAmbient.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Ingresar Ambiente", href: "/produccioncongelados/ingreso_ambiente" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/lavado_desinfeccion",
    name: "FRZ_WASH_DISINFECT",
    component: () =>
        import ("../views/FrozenProduction/WashDisinfect/WashDisinfect.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Lavar y Desinfectar", href: "/produccioncongelados/lavado_desinfeccion" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/config_tratamiento",
    name: "FRZ_CONFIG_TREATMENT",
    component: () =>
        import ("../views/FrozenProduction/WashDisinfect/ConfigTreatment.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Configuración de tratamiento", href: "/produccioncongelados/config_tratamiento" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/Lugares_de_muestra",
    name: "FRZ_SAMPLING_LOCATION",
    component: () =>
        import ("../views/FrozenProduction/SamplingLocation/SamplingLocationG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Lugares de Muestra", href: "/produccioncongelados/Lugares_de_muestra" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/pallets_desinfectados",
    name: "FRZ_DISINTECTED_PALLET",
    component: () =>
        import ("../views/FrozenProduction/WashDisinfect/DisinfectedPallet.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Pallets Desinfectados", href: "/produccioncongelados/pallets_desinfectados" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/estado_cultivo",
    name: "FRZ_FRUIT_STATE",
    component: () =>
        import ("../views/FrozenProduction/FruitState/FruitStateG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Estado Cultivo", href: "/produccioncongelados/estado_cultivo" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/lote_seleccion",
    name: "FRZ_SELECTION_LOT",
    component: () =>
        import ("../views/FrozenProduction/SelectionLot/SelectionLot.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Estado Cultivo", href: "/produccioncongelados/lote_seleccion" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/destinos_pallets",
    name: "FRZ_DESTINY_PALLET",
    component: () =>
        import ("../views/FrozenProduction/DestinyPallet/DestinyPallet.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Destino Pallet", href: "/produccioncongelados/destinos_pallets" },
        ],
    }
});

// REGISTRO DE PROCESO 
children.push({
    path: "/produccioncongelados/registro_proceso",
    name: "FRZ_PROCESS_REGISTRATION",
    component: () =>
        import ("../views/FrozenProduction/Process/ProcessRegistrationG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Registro de proceso", href: "/produccioncongelados/registro_proceso" },
        ],
    }
});

// parihuelas a espera de abastecimiento
children.push({
    path: "/produccioncongelados/espera_abastecimiento",
    name: "FRZ_WAIT_SUPPLY",
    component: () =>
        import ("../views/FrozenProduction/Process/WaitSupply.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Parihuela por abastecer", href: "/produccioncongelados/espera_abastecimiento" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/camaras_embarque",
    name: "FRZ_BOARDING_CHAMBER",
    component: () =>
        import ("../views/FrozenProduction/BoardingChamber/BoardingChamber.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Registro cámaras embarque", href: "/produccioncongelados/camaras_embarque" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/palet_exportacion",
    name: "FRZ_EXPORT_PALLET",
    component: () =>
        import ("../views/FrozenProduction/ExportPallet/ExportPallet.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Pallet Exportacion", href: "/produccioncongelados/palet_exportacion" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/pre_packing",
    name: "FRZ_PRE_PACKING",
    component: () =>
        import ("../views/FrozenProduction/PrePacking/PrePacking.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Pre Packing", href: "/produccioncongelados/pre_packing" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/print_qr",
    name: "FRZ_PRINT_QR_EXPORT_PALLET",
    component: () =>
        import ("../views/FrozenProduction/ExportPallet/PrintQRExportPallet.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Impresion QR", href: "/produccioncongelados/print_qr" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/palletsintunnel",
    name: "FRZ_PALLET_IN_TUNNEL",
    component: () =>
        import ("../views/FrozenProduction/PalletInTunnel/PalletInTunnel.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Pallets por tunel", href: "/produccioncongelados/palletsintunnel" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/receptionresumekg",
    name: "FRZ_RECEPTION_RESUMEKG",
    component: () =>
        import ("../views/FrozenProduction/Reception/ReceptionResumeKG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Resumen de Kg en Recepción", href: "/produccioncongelados/receptionresumekg" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/selectionnresumekg",
    name: "FRZ_SELECTION_RESUMEKG",
    component: () =>
        import ("../views/FrozenProduction/FrzSelectionCalibrated/FrzSelectionResumeKG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Resumen de Kg en Selección", href: "/produccioncongelados/selectionnresumekg" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/processresumekg",
    name: "FRZ_PROCESS_RESUMEKG",
    component: () =>
        import ("../views/FrozenProduction/Process/FrzProcessResumeKG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Resumen de Kg en Proceso", href: "/produccioncongelados/processresumekg" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/selectioncloselp",
    name: "FRZ_SELECTION_CLOSELP",
    component: () =>
        import ("../views/FrozenProduction/FrzSelectionCalibrated/FrzCloseLotSelection.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cerar Lote Producción", href: "/produccioncongelados/selectioncloselp" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/madurationresumekg",
    name: "FRZ_MADURATION_RESUMEKG",
    component: () =>
        import ("../views/FrozenProduction/FrzMaduration/FrzMadurationResumeKG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Resumen de Kg en Maduración", href: "/produccioncongelados/madurationresumekg" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/processavailablekg",
    name: "FRZ_PROCESS_AVAILABLEKG",
    component: () =>
        import ("../views/FrozenProduction/Process/FrzProcessAvailableKG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Kilos disponible para Proceso", href: "/produccioncongelados/processavailablekg" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/assigncaliber",
    name: "FRZ_ASIGN_CALIBER",
    component: () =>
        import ("../views/FrozenProduction/FrzSelectionCalibrated/FrzAssignementCaliber.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Asignación Calibres", href: "/produccioncongelados/assigncaliber" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/viewsupplypallets",
    name: "FRZ_PARIHUELAS_SUPPLY",
    component: () =>
        import ("../views/FrozenProduction/FrzSelectionCalibrated/FrzParihuelaSupplyView.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Pallets abastecidas", href: "/produccioncongelados/viewsupplypallets" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/viewpalletinchamber",
    name: "FRZ_PARIHUELA_IN_CHAMBER",
    component: () =>
        import ("../views/FrozenProduction/PalletInChamber/PalletInChamber.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Pallets en Cámaras", href: "/produccioncongelados/viewpalletinchamber" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/maturationstate",
    name: "FRZ_MATURATION_STATE",
    component: () =>
        import ("../views/FrozenProduction/MaturationState/MaturationState.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Estados de Maduración", href: "/produccioncongelados/maturationstate" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/pallets_particionados_unidos",
    name: "FRZ_PALLET_PJ",
    component: () =>
        import ("../views/FrozenProduction/PalletsPU/Palletspu.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Pallets Particionados Unidos", href: "/produccioncongelados/pallets_particionados_unidos" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/reportheavyfrozen",
    name: "FRZ_REPORT_HEAVY",
    component: () =>
        import ("../views/FrozenProduction/Reception/FrzReportHeavy.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Reporte Pesado", href: "/produccioncongelados/reportheavyfrozen" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/ambientes",
    name: "FRZ_MATURATION_AMBIENTE",
    component: () =>
        import ("../views/FrozenProduction/MaturationAmbient/MaturationAmbient.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Ambientes de Maduración", href: "/produccioncongelados/ambientes" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/californianmaturation",
    name: "FRZ_MATURATION_CALIFORNIAN",
    component: () =>
        import ("../views/FrozenProduction/MaturationCalifornian/MaturationCalifornian.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Californiano", href: "/produccioncongelados/californianmaturation" },
        ],
    }
});


// PALLET EN PISO DE MADURACION
children.push({
    path: "/produccioncongelados/palletSeleccionando",
    name: "FRZ_MATURATION_PALLET_FLOOR",
    component: () =>
        import ("../views/FrozenProduction/PalletInFloor/palletInFloor.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Pallet seleccionando", href: "/produccioncongelados/palletSeleccionando" },
        ],
    }
});



children.push({
    path: "/produccioncongelados/palletincalifornian",
    name: "FRZ_PALLET_IN_CALIFORNIAN",
    component: () =>
        import ("../views/FrozenProduction/PalletInCalifornian/PalletInCalifornian.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Pallets en Californiano", href: "/produccioncongelados/palletincalifornian" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/palletinambient",
    name: "FRZ_PALLET_IN_AMBIENT",
    component: () =>
        import ("../views/FrozenProduction/PalletInAmbient/PalletInAmbient.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Pallets en Plataforma", href: "/produccioncongelados/palletinambient" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/categorystatefruit",
    name: "FRZ_CATEGORY_STATE_FRUIT",
    component: () =>
        import ("../views/FrozenProduction/CategoryStateFruit/CategoryStateFruit.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Categorías Estado de Fruta", href: "/produccioncongelados/categorystatefruit" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/informacionpallet",
    name: "FRZ_PALLET_INFORMATION",
    component: () =>
        import ("../views/FrozenProduction/PalletInformation/PalletInformation.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Información de Pallet", href: "/produccioncongelados/informacionpallet" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/palletprocessprintqr",
    name: "FRZ_PALLET_PROCESS_PRINT_QR",
    component: () =>
        import ("../views/FrozenProduction/PalletProcess/PrintQR.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Imprimir QR", href: "/produccioncongelados/palletprocessprintqr" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/greenprocess",
    name: "FRZ_GREEN_PROCESS",
    component: () =>
        import ("../views/FrozenProduction/GreenProcess/GreenProcess.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Verde Proceso", href: "/produccioncongelados/greenprocess" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/palletsinchamberexport",
    name: "FRZ_PALLET_IN_CHAMBER_EXPORT",
    component: () =>
        import ("../views/FrozenProduction/BoardingChamberInPallet/PalletInChamber.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Pallets en Cámara", href: "/produccioncongelados/palletsinchamberexport" },
        ],
    }
});

//FRZ_DAMAGE_FLAW_CULTIVE
children.push({
    path: "/produccioncongelados/damageflawcultive",
    name: "FRZ_DAMAGE_FLAW_CULTIVE",
    component: () =>
        import ("../views/FrozenProduction/DamageFlawCultive/DamageFlawCultive.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Daños por Cultivo", href: "/produccioncongelados/damageflawcultive" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/CultiveByColor",
    name: "FRZ_CULTIVE_COLOR",
    component: () =>
        import ("../views/FrozenProduction/CultiveByColor/CultiveByColor.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cultivos por Color", href: "/produccioncongelados/CultiveByColor" },
        ],
    }
});


// despacho
children.push({
    path: "/produccioncongelados/despachocamara",
    name: "FRZ_DISPATCH_CHAMBER",
    component: () =>
        import ("../views/FrozenProduction/Dispatch/DispatchChamber.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Producción Congelado", disabled: true },
            { text: "Despacho", href: "/produccioncongelados/despachocamara" }
        ],
    },
});



children.push({
    path: "/produccioncongelados/DiscardDefectCultive",
    name: "FRZ_DISCARD_DEFECT_CULTIVE",
    component: () =>
        import ("../views/FrozenProduction/DiscardDefectCultive/DiscardDefectCultive.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Defecto de Descarte", href: "/produccioncongelados/DiscardDefectCultive" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/desinfeccionselection",
    name: "FRZ_DISINFECTION_SELECTION",
    component: () =>
        import ("../views/FrozenProduction/DisinfectionSelection/DisinfectionSelectionNew2.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Desinfección", href: "/produccioncongelados/desinfeccionselection" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/desinfeccionselectionaditional",
    name: "FRZ_DISINFECTION_SELECTION_ADITIONAL",
    component: () =>
        import ("../views/FrozenProduction/DisinfectionSelection/DisinfectionSelection.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Desinfección", href: "/produccioncongelados/desinfeccionselectionaditional" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/lugarmuestraseleccion",
    name: "SAMPLING_LOCATION_SELECTION",
    component: () =>
        import ("../views/FrozenProduction/SamplingLocationSelection/SamplingLocationG.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Desinfección", href: "/produccioncongelados/lugarmuestraseleccion" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/prelavadoseleccion",
    name: "WASH_SELECTION",
    component: () =>
        import ("../views/FrozenProduction/WashSelection/WashSelection.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Pre Lavado", href: "/produccioncongelados/prelavadoseleccion" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/kardex-maduracion",
    name: "FRZ_KARDEX_MATURATION",
    component: () =>
        import ("../views/FrozenProduction/KardexMaturation/KardexMaturation.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Pre Lavado", href: "/produccioncongelados/kardex-maduracion" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/especificaciones-tecnicas",
    name: "FRZ_TECHNICAL_ESPECIFICATIONS",
    component: () =>
        import ("../views/Quality/TechnicalSpecifications.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Especificaciones técnicas", href: "/produccioncongelados/especificaciones-tecnicas" },
        ],
    }
});




children.push({
    path: "/produccioncongelados/lotes-en-observacion",
    name: "FRZ_LOTS_UNDER_OBSERVATION",
    component: () =>
        import ("../views/Quality/LotsUnderObservation.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Lotes en observación", href: "/produccioncongelados/lotes-en-observacion" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/configuracionparametros",
    name: "FRZ_CONFIGURATION_PARAMETER",
    component: () =>
        import ("../views/FrozenProduction/Parameters/Parameter.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Configuración de parámetros", href: "/produccioncongelados/configuracionparametros" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/palletsintunnellist",
    name: "FRZ_PALLET_IN_TUNNEL_LIST",
    component: () =>
        import ("../views/FrozenProduction/PalletInTunnel/PalletInTunnelList.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Pallets por tunel en Lista", href: "/produccioncongelados/palletsintunnellist" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/palletinambientlist",
    name: "FRZ_PALLET_IN_AMBIENT_LIST",
    component: () =>
        import ("../views/FrozenProduction/PalletInAmbient/PalletInAmbientList.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Pallets en Ambiente Lista", href: "/produccioncongelados/palletinambientlist" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/palletincalifornianlist",
    name: "FRZ_PALLET_IN_CALIFORNIAN_LIST",
    component: () =>
        import ("../views/FrozenProduction/PalletInCalifornian/PalletInCalifornianList.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Pallets en Californiano", href: "/produccioncongelados/palletincalifornianlist" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/viewpalletinchamberlist",
    name: "FRZ_PARIHUELA_IN_CHAMBER_LIST",
    component: () =>
        import ("../views/FrozenProduction/PalletInChamber/PalletInChamberList.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Pallets en Cámaras Lista", href: "/produccioncongelados/viewpalletinchamberlist" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/dashboardmaduracion",
    name: "FRZ_DASHBOARD_MATURATION",
    component: () =>
        import ("../views/FrozenProduction/FrzMaduration/Dashboard/DashboardMaturation.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Pallets en Cámaras Lista", href: "/produccioncongelados/dashboardmaduracion" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/optionsadvanced",
    name: "FRZ_OPTIONS_ADVANCED",
    component: () =>
        import ("../views/FrozenProduction/OptionsAdvanced/FrzOptionsAdvanced.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Opciones Avanzadas", href: "/produccioncongelados/optionsadvanced" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/ConfigTunnelStatus",
    name: "FRZ_CONFIG_TUNNEL_STATUS",
    component: () =>
        import ("../views/FrozenProduction/ConfigTunnelStatus/ConfigTunnelStatus.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Mantenimiento", disabled: true },
            { text: "Configuracion de Estado de Tunel", href: "/produccioncongelados/ConfigTunnelStatus" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/temperaturayhorasetileno",
    name: "Frz_Temperature_Hours_Ethylene",
    component: () =>
        import ("../views/FrozenProduction/TemperatureEthylene/TemperatureHoursEthylene.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Temperatura y horas etileno", href: "/produccioncongelados/temperaturayhorasetileno" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/FrzCustomersMaturation",
    name: "Frz_CUSTOMERS_MATURATION",
    component: () =>
        import ("../views/FrozenProduction/FrzCustomersMaturation/FrzCustomersMaturation.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Mantenimiento", disabled: true },
            { text: "Clientes de Maduración", href: "/produccioncongelados/FrzCustomersMaturation" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/armarmuneco",
    name: "DOLL_ARMED",
    component: () =>
        import ("../views/FrozenProduction/ArmedDoll/ArmedDoll.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },

            { text: "Armado de Muñecos", href: "/produccioncongelados/armarmuneco" },

        ],
    }
});

children.push({
    path: "/produccioncongelados/produccionlinea",
    name: "Frz_Production_Line",
    component: () =>
        import ("../views/FrozenProduction/ProductionCategory/FrzProductionLine.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Produccion Linea", href: "/produccioncongelados/produccionlinea" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/produccioncategoria",
    name: "Frz_Production_Category",
    component: () =>
        import ("../views/FrozenProduction/ProductionCategory/FrzProductionCategory.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Tipo de Congelamiento", href: "/produccioncongelados/produccioncategoria" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/FrzCreationTable",
    name: "FRZ_CREATION_TABLE",
    component: () =>
        import ("../views/FrozenProduction/FrzCreationTable/FrzCreationTable.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Acondicionado", disabled: true }, 
            { text: "Estaciones de Trabajo", href: "/produccioncongelados/FrzCreationTable" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/FrzProductionGroup",
    name: "FRZ_PRODUCTION_GROUP",
    component: () =>
        import ("../views/FrozenProduction/FrzProductionGroup/FrzProductionGroup.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Acondicionado", disabled: true }, 
            { text: "Grupo de Producción", href: "/produccioncongelados/FrzProductionGroup" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/acondicionado/ubicacionmuneco",
    name: "FRZ_LOCATION_DOLL",
    component: () =>
        import ("../views/FrozenProduction/ArmedDoll/LocationDoll.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Acondicionado", disabled: true }, 
            { text: "Ubicación munecos", href: "/produccioncongelados/acondicionado/ubicacionmuneco" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/recepcion/reportes",
    name: "FRZ_REPORT_RECEPTION",
    component: () =>
        import ("../views/FrozenProduction/Reception/Reports/ReportReception.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Recepción", disabled: true }, 
            { text: "Reportes", href: "/produccioncongelados/recepcion/reportes" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/changetunelmain",
    name: "FRZ_CHANGE_ENTER_TUNNEL",
    component: () =>
        import ("../views/FrozenProduction/FrzMaduration/FrzChangeTunnel/EnterTunelChange.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Mover Tunel", href: "/produccioncongelados/changetunelmain" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/FrzTypeExitCategory",
    name: "FRZ_TYPE_EXIT_CATEGORY",
    component: () =>
        import ("../views/FrozenProduction/FrzTypeExitCategory/FrzTypeExitCategory.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Acondicionado", disabled: true }, 
            { text: "Tipo de salida de categoria", href: "/produccioncongelados/FrzTypeExitCategory" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/FrzCtnLinesProcess",
    name: "FRZ_CTN_LINES_PROCESS",
    component: () =>
        import ("../views/FrozenProduction/FrzCtnLinesProcess/FrzCtnLinesProcess.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" }, 
            { text: "Produccion Congelado", disabled: true },
            { text: "Acondicionado", disabled: true }, 
            { text: "Lineas en Producción", href: "/produccioncongelados/FrzCtnLinesProcess" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/controlprocessline",
    name: "FRZ_CTN_CONTROL_PROCESS_LINE",
    component: () =>
        import ("../views/FrozenProduction/FrzCtnLinesProcess/FrzCtnControlProcessLine.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" }, 
            { text: "Produccion Congelado", disabled: true },
            { text: "Acondicionado", disabled: true }, 
            { text: "Control en linea de proceso", href: "/produccioncongelados/controlprocessline" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/staticcartassembly",
    name: "Frz_Static_Cart_Assembly",
    component: () =>
        import ("../views/FrozenProduction/FrzAssemblyStatic/FrzCtnAssemblyStatic.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Armado de carrito estatico", href: "/produccioncongelados/staticcartassembly" },
        ],
    }
});



children.push({
    path: "/produccioncongelados/PermissionsPersonByGroup",
    name: "FRZ_CTN_PERMISSIONS_PERSON",
    component: () =>
        import ("../views/FrozenProduction/PermissionsPersonByGroup/PermissionsPersonByGroup.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Acondicionado", disabled: true },
            { text: "Permisos de trabajadores", href: "/produccioncongelados/PermissionsPersonByGroup" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/processcontainers",
    name: "FRZ_CTN_PROCESS_CONTAINERS",
    component: () =>
        import ("../views/FrozenProduction/FrzCtnProcessContainers/FrzCtnProcessContainers.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Acondicionado", disabled: true },
            { text: "Contenedores de proceso", href: "/produccioncongelados/processcontainers" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/containersinprocess",
    name: "FRZ_CTN_CONTAINERS_IN_PROCESS",
    component: () =>
        import ("../views/FrozenProduction/FrzCtnProcessContainers/FrzCtnContainersInProcess.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Acondicionado", disabled: true },
            { text: "Contenedores dentro  de proceso", href: "/produccioncongelados/containersinprocess" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/muestroproceso",
    name: "FRZPROCESS_SAMPLING",
    component: () =>
        import ("../views/FrozenProduction/ProcessSampling/ProcessSampling.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Acondicionado", disabled: true },
            { text: "Muestreo Proceso", href: "/produccioncongelados/muestroproceso" },
        ],
    }
});



children.push({
    path: "/produccioncongelados/packinglines",
    name: "FRZ_PACKING_LINES",
    component: () =>
        import ("../views/FrozenProduction/FrzPacking/FrzPackingLines/FrzPackingLines.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Empaque", disabled: true },
            { text: "Lineas de empaque", href: "/produccioncongelados/packinglines" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/packinggroups",
    name: "FRZ_PACKING_GROUP",
    component: () =>
        import ("../views/FrozenProduction/FrzPacking/FrzPackingLines/FrzPackingGroup/FrzPackingGroup.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Empaque", disabled: true },
            { text: "Grupo de personas de empaque", href: "/produccioncongelados/packinggroups" },
        ],
    }
});



children.push({
    path: "/produccioncongelados/FrzLinesPackingOut",
    name: "FRZ_LINES_PACKING_OUT",
    component: () =>
        import ("../views/FrozenProduction/FrzPacking/FrzLinesPackingOut/FrzLinesPackingOut.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Empaque", disabled: true },
            { text: "Salidas de lineas de empaque", href: "/produccioncongelados/FrzLinesPackingOut" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/FrzLinesPackingViews",
    name: "FRZ_LINES_PACKING_VIEWS",
    component: () =>
        import ("../views/FrozenProduction/FrzPacking/FrzLinesPackingViews/FrzLinesPackingViews.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Empaque", disabled: true },
            { text: "Vista de Lineas de Empaque", href: "/produccioncongelados/FrzLinesPackingViews" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/asignarbingalinea",
    name: "ASSIGN_BING_TO_LINE",
    component: () =>
        import ("../views/FrozenProduction/FrzPacking/FrzAssingBagToLine/FrzAssingBingToProcess.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Asignar Bings a Proceso", href: "/produccioncongelados/asignarbingalinea" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/packingarmedpallet",
    name: "FRZ_PACKING_ARMED_PALLET",
    component: () =>
        import ("../views/FrozenProduction/FrzPacking/FrzPackingArmedPallet/FrzPackingArmedPallet.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Armado de palet", href: "/produccioncongelados/packingarmedpallet" },
        ],
    }
});



children.push({
    path: "/produccioncongelados/condicionesrol",
    name: "FRZ_CONDITIONING_ROLE",
    component: () =>
        import ("../views/FrozenProduction/ConditioningRole/ConditioningRole.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Condiciones Rol", href: "/produccioncongelados/condicionesrol" },
        ],
    }
});



children.push({
    path: "/produccioncongelados/packingtunnel",
    name: "FRZ_PACKING_TUNNEL",
    component: () =>
        import ("../views/FrozenProduction/FrzPacking/FrzPackingTunnel/FrzChamberDispatchTunnels.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Tunel de empaque", href: "/produccioncongelados/packingtunnel" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/packingintunnel",
    name: "FRZ_PACKING_IN_TUNNEL",
    component: () =>
        import ("../views/FrozenProduction/FrzPacking/FrzPackingTunnel/FrzPackingInTunnel.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Tuneles de empaque", href: "/produccioncongelados/packingintunnel" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/linestationwork",
    name: "FRZ_LINE_STATION_WORK",
    component: () =>
        import ("../views/FrozenProduction/FrzCreationTable/FrzLineStationWork.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Acondicionado", disabled: true }, 
            { text: "Estaciones de Trabajo", href: "/produccioncongelados/linestationwork" },
        ],
    }
});
 
children.push({
    path: "/produccioncongelados/FrzCameraIncomePallet",
    name: "FRZ_CAMERA_INCOME_PALLET",
    component: () =>
        import ("../views/FrozenProduction/FrzCamera/FrzCameraIncomePallet/FrzCameraIncomePallet.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cámara", disabled: true },
            { text: "Ingreso de Pallet a Cámara ", href: "/produccioncongelados/FrzCameraIncomePallet" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/FrzCtnPalletSampling",
    name: "FRZ_CTN_DISCARD_PALLET",
    component: () =>
        import ("../views/FrozenProduction/ProcessSampling/FrzCtnPalletSampling.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Acondicionado", disabled: true },
            { text: "Seleccion", href: "/produccioncongelados/FrzCtnPalletSampling" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/packingworkstation",
    name: "FRZ_PACKING_WORK_STATION",
    component: () =>
        import ("../views/FrozenProduction/FrzPacking/FrzPackingWorkStation/FrzPackingWorkStation.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Empaque", disabled: true },
            { text: "Estaciones de Trabajo", href: "/produccioncongelados/packingworkstation" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/packingworkstationprocess",
    name: "FRZ_PACKING_WORKSTATION_PROCESS",
    component: () =>
        import ("../views/FrozenProduction/FrzPacking/FrzPackingWorkStationProcess.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Empaque", disabled: true },
            { text: "Estaciones de Trabajo", href: "/produccioncongelados/packingworkstationprocess" },
        ],
    }
});

children.push({

    path: "/produccioncongelados/packinlinesinprocess",
    name: "FRZ_PACKING_LINES_IN_PROCESS",
    component: () =>
        import ("../views/FrozenProduction/FrzPacking/FrzPackingLinesInProcess/FrzPackingLinesInProcess.vue"),

        meta: {
            authenticated: true,
            breadcrumb: [
                { text: "Inicio", href: "/#/" },
                { text: "Produccion Congelado", disabled: true },
                { text: "Empaque", disabled: true },
                { text: "Lineas en proceso", href: "/produccioncongelados/packinlinesinprocess" },
            ],
        }
    });

children.push({

    path: "/produccioncongelados/FrzCtnResponsibleStop",
    name: "FRZ_CTN_RESPONSIBLE_STOP",
    component: () =>
        import ("../views/FrozenProduction/FrzCtnResponsibleStop/FrzCtnResponsibleStop.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true }, 
            { text: "Acondicionado", disabled: true },
            { text: "Responsable de paradas", href: "/produccioncongelados/FrzCtnResponsibleStop" },

        ],
    }
});

children.push({

    path: "/produccioncongelados/packingsealersbag",
    name: "FRZ_PACKING_SEALERS_BAG",
    component: () =>
        import ("../views/FrozenProduction/FrzPacking/FrzPackingSealersBag.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cámara", disabled: true },
            { text: "Ingreso a Camara ", href: "/produccioncongelados/packingsealersbag" },
        ],
    }
});

children.push({

    path: "/produccioncongelados/FrzIncomePalletChamber",
    name: "FRZ_INCOME_PALLET_CHAMBER",
    component: () =>
        import ("../views/FrozenProduction/FrzCamera/FrzIncomePalletChamber/FrzIncomePalletChamber.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cámara", disabled: true },
            { text: "Ingreso  a Camara", href: "/produccioncongelados/FrzIncomePalletChamber" },
        ],
    }
});


children.push({

    path: "/produccioncongelados/FrzIncomePalletObserved",
    name: "FRZ_INCOME_PALLET_OBSERVED",
    component: () =>
        import ("../views/FrozenProduction/FrzCamera/FrzIncomePalletObserved/FrzIncomePalletObserved.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cámara", disabled: true },
            { text: "Ingreso de pallets observados Camara ", href: "/produccioncongelados/FrzIncomePalletObserved" },
        ],
    }
});

children.push({

    path: "/produccioncongelados/FrzPackingAssembleBins",
    name: "FRZ_PACKING_ASSEMBLE_BINS",
    component: () =>
        import ("../views/FrozenProduction/FrzPacking/FrzPackingAssembleBins/FrzPackingCreateBins.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Empaque", disabled: true },
            { text: "Creación de Bines ", href: "/produccioncongelados/FrzPackingAssembleBins" },

        ],
    }
});

children.push({

    path: "/produccioncongelados/chamberprepacking",
    name: "FRZ_CHAMBER_PREPACKING",
    component: () =>
        import ("../views/FrozenProduction/FrzCamera/FrzChamberPrepacking/FrzChamberPrePacking.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },    
            { text: "Produccion Congelado", disabled: true },       
            { text: "Cámara", disabled: true },
            { text: "PREPACKING ", href: "/produccioncongelados/chamberprepacking" },
        ],
    }
});

children.push({

    path: "/produccioncongelados/chamberpicking",
    name: "FRZ_CHAMBER_PICKING",
    component: () =>
        import ("../views/FrozenProduction/FrzCamera/FrzChamberPicking/FrzChamberPicking.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cámara", disabled: true },
            { text: "PICKING ", href: "/produccioncongelados/chamberpicking" },
        ],
    }
});

children.push({

    path: "/produccioncongelados/FrzIncomePalletRepacking",
    name: "FRZ_REPACKING_PALLET_CHAMBER",
    component: () =>
        import ("../views/FrozenProduction/FrzCamera/FrzIncomePalletRepacking/FrzIncomePalletRepacking.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cámara", disabled: true },
            { text: "Reempaque de Pallets ", href: "/produccioncongelados/FrzIncomePalletRepacking" },
        ],
    }
});

children.push({

    path: "/produccioncongelados/FrzChamberPalletLosses",
    name: "FRZ_LOSSES_PALLET_CHAMBER",
    component: () =>
        import ("../views/FrozenProduction/FrzCamera/FrzChamberPalletLosses/FrzChamberPalletLosses.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cámara", disabled: true }, 
            { text: "Mermas de Pallets", href: "/produccioncongelados/FrzChamberPalletLosses" },
        ],
    }
});

children.push({

    path: "/produccioncongelados/FrzChamberDetailLossesPallet",
    name: "FRZ_CHAMBER_DETAIL_LOSSES_PALLET",
    component: () =>
        import ("../views/FrozenProduction/FrzCamera/FrzChamberDetailLossesPallet/FrzChamberDetailLossesPallet.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cámara", disabled: true }, 
            { text: "Detalle de merma por Pallet", href: "/produccioncongelados/FrzChamberDetailLossesPallet" },
        ],
    }
});

children.push({

    path: "/produccioncongelados/FrzChamberDetailLosses",
    name: "FRZ_CHAMBER_DETAIL_LOSSES_PALLET_GENERAL",
    component: () =>
        import ("../views/FrozenProduction/FrzCamera/FrzChamberDetailLosses/FrzChamberDetailLosses.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cámara", disabled: true }, 
            { text: "Detalle de mermas", href: "/produccioncongelados/FrzChamberDetailLosses" },
        ],
    }
});

children.push({

    path: "/produccioncongelados/FrzChamberReEntry",
    name: "FRZ_CHAMBER_RE_ENTRY",
    component: () =>
        import ("../views/FrozenProduction/FrzCamera/FrzChamberReEntry/FrzChamberReEntry.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cámara", disabled: true }, 
            { text: "Reingresos", href: "/produccioncongelados/FrzChamberReEntry" },
        ],
    }
});

children.push({

    path: "/produccioncongelados/packingchamber",
    name: "FRZ_CHAMBER_DISPATCH_TUNNELS_CHAMBER",
    component: () =>
        import ("../views/FrozenProduction/FrzPacking/FrzPackingTunnel/FrzChamberDispatchTunnelsChamber.vue"),    
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Tuneles de empaque", href: "/produccioncongelados/packingchamber" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/chamberpalletchanber",
    name: "FRZ_CHAMBER_PALLET_CHAMBER",
    component: () =>
        import ("../views/FrozenProduction/FrzCamera/FRZChamberPalletChamber.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Tuneles de empaque", href: "/produccioncongelados/chamberpalletchanber" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/chamberpalletintunnel",
    name: "FRZ_CHAMBER_PALLET_IN_TUNNEL",
    component: () =>
        import ("../views/FrozenProduction/FrzPacking/FrzPackingTunnel/FrzChamberPalletInTunnel.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Tuneles de empaque", href: "/" },
        ],
    }
});

children.push({

    path: "/produccioncongelados/FrzChamberPalletDispatch",
    name: "FRZ_CHAMBER_PALLET_DISPATCH",
    component: () =>
        import ("../views/FrozenProduction/FrzCamera/FrzChamberPalletDispatch/FrzChamberPalletDispatch.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cámara", disabled: true }, 
            { text: "Despacho", href: "/produccioncongelados/FrzChamberPalletDispatch" },
        ],
    }
});

children.push({

    path: "/produccioncongelados/FrzChamberViewGraphics",
    name: "FRZ_CHAMBER_VIEW_GRAPHICS",
    component: () =>
        import ("../views/FrozenProduction/FrzCamera/FrzChamberViewGraphics/FrzChamberViewGraphics.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cámara", disabled: true }, 
            { text: "Vista Cámaras", href: "/produccioncongelados/FrzChamberViewGraphics" },
        ],
    }
});

children.push({

    path: "/produccioncongelados/FrzChamberPrePackingReport",
    name: "FRZ_PRE_PACKING_REPORT",
    component: () =>
        import ("../views/FrozenProduction/FrzCamera/FrzChamberPrePackingReport/FrzChamberPrePackingReport.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cámara", disabled: true }, 
            { text: "Reporte Pre-Packing", href: "/produccioncongelados/FrzChamberPrePackingReport" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/groupingofpallets",
    name: "FRZ_CHAMBER_GROUPING_PALLETS",
    component: () =>
        import ("../views/FrozenProduction/FrzPacking/FrzPackingTunnel/FrzChamberGroupingPallets.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cámara", disabled: true },
            { text: "Agrupacion de Pallets Camara", href: "/produccioncongelados/groupingofpallets" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/FrzDistributionBoxesPallets",
    name: "FRZ_DISTRIBUTION_BOXES_PALLETS",
    component: () =>
        import ("../views/FrozenProduction/FrzCamera/FrzDistributionBoxesPallets/FrzDistributionBoxesPallets.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cámara", disabled: true }, 
            { text: "Distribucion de cajas en Pallets", href: "/produccioncongelados/FrzDistributionBoxesPallets" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/FrzChamberCustomerBrand",
    name: "FRZ_CHAMBER_CUSTOMER_BRAND",
    component: () =>
        import ("../views/FrozenProduction/FrzCamera/FrzChamberCustomerBrand/FrzChamberCustomerBrand.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cámara", disabled: true }, 
            { text: "Marca de Clientes", href: "/produccioncongelados/FrzChamberCustomerBrand" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/FrzChamberDispatchPicking",
    name: "FRZ_CHAMBER_DISPATCH_PICKING",
    component: () =>
        import ("../views/FrozenProduction/FrzCamera/FrzChamberDispatchPicking/FrzChamberDispatchPicking.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cámara", disabled: true }, 
            { text: "Despacho por Picking", href: "/produccioncongelados/FrzChamberDispatchPicking" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/FrzChamberSearch",
    name: "FRZ_CHAMBER_SEACRH",
    component: () =>
        import ("../views/FrozenProduction/FrzCamera/FrzChamberSearchPallet.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cámara", disabled: true }, 
            { text: "Despacho por Picking", href: "/produccioncongelados/FrzChamberSearch" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/prepackingdispatch",
    name: "FRZ_CHAMBER_PREPACKING_DISPATCH",
    component: () =>
        import ("../views/FrozenProduction/FrzCamera/FrzChamberPicking/FrzChamberPrePackingGuideDispatch.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cámara", disabled: true }, 
            { text: "Guia de Despacho", href: "/produccioncongelados/prepackingdispatch" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/prepackingdispatchguia",
    name: "FRZ_CHAMBER_PREPACKING_GUIA",
    component: () =>
        import ("../views/FrozenProduction/FrzCamera/FrzChamberPicking/FrzChamberPrePackingGuideGenerate.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cámara", disabled: true }, 
            { text: "Guia de Despacho", href: "/produccioncongelados/prepackingdispatchguia" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/typestatefinal",
    name: "FINAL_STATE_FILTER",
    component: () =>
        import ("../views/FrozenProduction/FrzPacking/FrzPackingLines/FrzTypeStateFinal.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Tipo Estado Final", disabled: true },
            { text: "Empaque", disabled: true },
            { text: "Estado Final", href: "/produccioncongelados/typestatefinal" },
        ],
    }
});

children.push({

    path: "/produccioncongelados/chamberpacking",
    name: "FRZ_CHAMBER_PACKING",
    component: () =>
        import ("../views/FrozenProduction/FrzCamera/FrzChamberPicking/FrzChamberPacking.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cámara", disabled: true },
            { text: "PACKING ", href: "/produccioncongelados/chamberpacking" },
        ],
    }
});


children.push({

    path: "/produccioncongelados/packingcut",
    name: "FRZ_PACKING_TYPE_CUT",
    component: () =>
        import ("../views/FrozenProduction/FrzPacking/FrzTypeCutPackingView.vue"),

    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Cámara", disabled: true },
            { text: "Ingreso a Camara ", href: "/produccioncongelados/packingcut" },
        ],
    }
});

children.push({
    path: "/produccioncongelados/configobservations",
    name: "Frz_Observations_Packing_Camera",
    component: () =>
        import ("../views/FrozenProduction/ObservationState/FrzPackingObservation.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Estado Cultivo", href: "/produccioncongelados/configobservations" },
        ],
    }
});


children.push({
    path: "/produccioncongelados/selectiongreen",
    name: "FRZ_CTN_SELECTION_GREEN",
    component: () =>
        import ("../views/FrozenProduction/ProcessSampling/FrzCtnSelectionGreen.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Produccion Congelado", disabled: true },
            { text: "Acondicionado", disabled: true },
            { text: "Seleccion Verde", href: "/produccioncongelados/selectiongreen" },
        ],
    }
});


children.push({

    path: "/produccioncongelados/packingcontainers",
    name: "Frz_Containers_Packing",
    component: () =>
        import ("../views/FrozenProduction/FrzPacking/FrzPackingLinesInProcess/FrzPackingContainers.vue"),

        meta: {
            authenticated: true,
            breadcrumb: [
                { text: "Inicio", href: "/#/" },
                { text: "Produccion Congelado", disabled: true },
                { text: "Empaque", disabled: true },
                { text: "Lineas en proceso", href: "/produccioncongelados/packingcontainers" },
            ],
        }
    });

export { children }