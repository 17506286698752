<template>
  <div style="padding-top:-2px;margin-top:-2px;">
    <v-label
      ><b>{{ label }}</b></v-label
    >
    <section>
      <v-file-input
        @click:clear="clear()"
        :multiple="multiple"
        small-chips
        :value="value"
        @input="input($event)"
        prepend-icon
        @change="change($event)"
        show-size
        color="primary"
        prepend-inner-icon="mdi-paperclip"
        dense
        hide-details
        outlined
      >
      </v-file-input>
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: Array,
      default: [],
    },
    config: {
      type: Object,
      default: null,
    },
    label: { type: String, default: "" },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: null,
      files: null,
    };
  },
  watch: {
    files() {
      this.$emit("changeData", this.files);
      console.log(this.files);
    },
  },
  methods: {
    change(files) {
      this.$emit("input", files);
      console.log("regis");
    },
    input(files) {
      this.$emit("input", files);
    },
    send() {
      this.$fun.alert("Seguro de subir Archivo", "question").then((val) => {
        if (val.value) {
          if (this.config != null)
            if (this.value.length > 0) {
              const formData = new FormData();
              formData.append("file", this.files);
              this.$http
                .post(
                  this.config.url,
                  formData
                  // ,  { headers: {'Content-Type': 'multipart/form-data' } }
                )
                .then(() => {
                  this.$fun.alert("Archivo cargado correctamente", "success");
                });
            } else this.$fun.alert("Seleccione Archivo a Cargar", "info");
        }
      });
    },
    clear(item) {
      this.$emit("clear", this.value);
    },
  },
};
</script>
