<template
  ><div style="padding-top:-2px;margin-top:-2px;">
      <v-label ><b>{{label}}</b></v-label>
    <s-select-generic  class="text"
      itemtext="SlpName"
      itemvalue="SlpID" :disabled=disabled
      id="SlpID"
      :value="value" :return-object=returnObject
      @input="input($event)" 
      :config="config" 
      :text="text" :clearable=clearable :noDefault=noDefault
    >
    </s-select-generic>
  </div>
</template>

<script>
import _sGenericService from "@/services/Logistics/PurchaseService.js";
export default {
  components: {},
  props: {
     SupID: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
     clearable: {
      type: Boolean,
      default: false,
    },
    value: null,
    text: { type: String, default: "" },
    label: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
  
    noDefault: {
      type: Boolean,
      default: false,
    },
  
  },
  watch: {
 
    value(value) {
      this.item = value;
    },
  },
  methods: {
     
    input(val) {
      this.$emit("input", val);
    },
  
    },
    data: () => ({
        item: 0,
        val:'',
        items: [],
        config: {},
    }),
  
    created() {
        this.config = {
            url:_sGenericService.getPurchasingManagerURL(),
            title: this.label,
            params: {SupID:this.SupID,requestID:this.$fun.getUserID() }
        }
       

    }

  }

</script>
